import SiteLogo from '../global/logo.jpg'
import NBcss from '../css/DashNavbar.module.css'
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const Logout = () => {
    cookies.remove('Uid');
    cookies.remove('Login_status');
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/Login`);
}

const DashNavbar = () => {

    const navbar = (
        <div className={NBcss.Navbar}>
            <div className={NBcss.NavBarMain}>
                <Link to="/"><div className={NBcss.Logo}>
                    <img src={SiteLogo}></img>
                </div></Link>
                <div className={NBcss.NavbarMenu}>
                    <ul>
                        <li><Link to="/" className={NBcss.LogoutBtn} onClick={Logout}>登出</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
    return navbar
}


export default DashNavbar