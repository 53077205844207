import { Helmet, HelmetProvider } from "react-helmet-async"
import { Outlet } from "react-router-dom"
import NavBar from "../../Components/C0211/Main/Navbar"
import css from '../../css/C0211/mainLayout.module.css'

import ACLogo from '../../global/C0211/SHYLogo.png'
import ChangeColor from '../../Components/ChangeColor'
import ControlBar from '../../Components/C0211/Main/ControlBar'

import { useEffect, useContext, useState } from "react"
import { SystemContext } from "../../Class/SystemPara"
import { PreLoadingContext } from "../../Class/C0211PreLoadingData"
import { FetchSystemData, C0211PreLoadingData } from "../../Interface/Const"
import DeafultLoadingBar from "../../Components/LoadingBar/DefaultLoadingBar"
import { use } from "i18next"
import { UserContext } from "../../Class/UserPara"
import { Cookies } from "react-cookie"
import axios from "axios"
const cookies = new Cookies();


const C0211DashLayout = () => {
    const userContext = useContext(UserContext);
    const preLoadDataContext = useContext(PreLoadingContext);

    const [isloading, setLoading] = useState<boolean>(false);
    const [istoken, setToken] = useState<string | null>();
    const [isresize, setResize] = useState<boolean>(true);

    useEffect(() => {
        setToken(cookies.get('token'));
    }, []);

    useEffect(() => {
        const loadSystemData = async () => {
            try {
                await LoadUserPermission();
            } catch (error) {
                console.error("Error during loading user permissions:", error);
            }
        };

        if (istoken !== null) {
            loadSystemData();
        }
    }, [istoken]);

    useEffect(() => {
        if (userContext.FuncPermission?.length > 0) {
            const preloadData = async () => {
                try {
                    await C0211PreLoadingData(userContext, preLoadDataContext);
                } catch (error) {
                    console.error("Error during preloading data:", error);
                } finally {
                    setLoading(false);
                }
            };
            setLoading(true);
            preloadData();
        }
    }, [userContext?.FuncPermission]);

    const LoadUserPermission = async () => {
        let token = cookies.get('token');
        try {
            console.log("Running LoadUserPermission");
            const resPermission = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserPermission`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (resPermission.status === 200) {
                userContext.setFunctionPermission(resPermission.data.UserPermission);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const content = (
        <body>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>紹香園 - 控制台</title>
                    </Helmet>

                    <div className={!isresize ? css.DashLayout : css.humburgerDashLayout}>
                        {isloading && <DeafultLoadingBar />}
                        <div className={css.logo}>
                            {!isresize && (
                                <img src={ACLogo} alt="紹香園 Logo" />
                            )}
                        </div>

                        <div className={css.navbar}>
                            <NavBar />

                            <div className={css.EachTypeItem} onClick={() => setResize(!isresize)}>
                                <i className={`fi fi-br-bars-staggered ${css.iconStyles}`} /><p>{!isresize && ("Resize")}</p>
                            </div>
                        </div>

                        <div className={css.userinfo}>
                            <ChangeColor />
                            <ControlBar />
                        </div>

                        <div className={css.Outlet}>
                            <Outlet />
                        </div>
                    </div>

                </div>
            </HelmetProvider>
        </body>
    )

    return content
}

export default C0211DashLayout