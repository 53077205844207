import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImgFile0 from '../global/slider_0.png';
import ImgFile1 from '../global/slider_1.png';
import ImgFile2 from '../global/slider_2.png';
import ImgFile3 from '../global/slider_3.png';
import ImgFile4 from '../global/slider_4.png';
import IScss from '../css/ImageSlider.module.css'

const ImageSlider: React.FC = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={IScss.MainSection}>
      <div className={IScss.ImageSliderBody}>
        <Slider {...settings}>
          <div>
            <img src={ImgFile0} alt="Slider 0" />
          </div>
          <div>
            <img src={ImgFile1} alt="Slider 1" />
          </div>
          <div>
            <img src={ImgFile2} alt="Slider 2" />
          </div>
          <div>
            <img src={ImgFile3} alt="Slider 3" />
          </div>
          <div>
            <img src={ImgFile4} alt="Slider 4" />
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default ImageSlider;