import ContactUs from "../../Components/C0533/ContactUs"

const ContactUsPage = () => {
    const content =(
        <>
        <ContactUs />
        </>
    )
    return content
}
export default ContactUsPage