import { useEffect, useState } from 'react'
import css from '../../css/C0533/Aces.module.css'

import { IC0553WebsiteImage } from '../../Interface/interface'
import axios from 'axios'

const Aces = () => {

    const [ data, setData ] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        getDataJson();
    }, [])

    const getDataJson = async() => {
        try {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "Aces",
                item_section_code: "Aces_"
            });

            if (res.status === 200) {
                setData(res.data)
                console.log(data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const getMatchImg = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)
    
        if (indexID < 0 || indexID >= data.length) {
            return null;
        }
        
        const urlpath = data[indexID].item_pic_url;
        const urldesc = data[indexID].item_desc;
        return <img src={urlpath} alt={urldesc} />;
    };

    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                <p>精英跳繩運動員培訓</p>
            </div>

            <div className={css.MainImageDiv}>
                {getMatchImg(data, "Aces_01")}
            </div>

            <div className={css.DescPhoto}>
                <div className={css.DescriptonDiv}>
                    <div className={css.DescTitle}>
                        <p>Mini Aces／Aces</p>
                    </div>
                    <div className={css.DescriptonMessage}>
                        <p>本社「Aces」精英班成立目的是為本地跳繩界培育更多跳繩健將，並代表香港參與國際性賽事。而精英組別學員每年均會參與全港跳繩精英賽，與一眾本地跳繩精英同埸較量，力爭入選香港跳繩代表隊的資格。</p>
                    </div>
                </div>
                <div className={css.PhotoDiv}>
                {getMatchImg(data, "Aces_02")}
                </div>
            </div>

            <div className={css.DescPhoto2}>
                <div className={css.PhotoDiv2}>
                {getMatchImg(data, "Aces_03")}
                </div>
                <div className={css.DescriptonDiv2}>
                    <div className={css.DescriptonMessage2}>
                        <p>「Aces精英班」訓練時數及訓練難度較高，對比起一般花式跳繩興趣班略有不同。本課程主要由教練團甄選具備潛質及較高技術水平的學生加入。訓練內容主要以備戰全港跳繩精英賽為主，務求讓成員在充足訓練下，以最佳狀態出戰。</p>
                    </div>
                </div>
            </div>

        </section>
    )
    return content
}

export default Aces