import { IC0217GenerateSummaryReportProps } from '../../../../Interface/interface';
import css from '../../../../css/C0217/GenerateReport.module.css'


const C0217KeetaSummaryReport: React.FC<IC0217GenerateSummaryReportProps> = ({
    rawSummaryData
}) => {

    const convertOutputNumber = (number: number | undefined) => {
        if (number === undefined || number === null) return "-"
        const convernumbet = number.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        if (convernumbet === "0.00") return "-"
        return convernumbet
    }

    const calculateTotalNet = (FPnumber: number | undefined, SPnumber: number | undefined) => {

        if (FPnumber === null || FPnumber === undefined) {
            FPnumber = 0
        }

        if (SPnumber === null || SPnumber === undefined) {
            SPnumber = 0
        }

        const sumnet = FPnumber + SPnumber
        if (sumnet === 0) {
            return "-"
        }

        return convertOutputNumber(sumnet)

    }

    const calculatePercentage = (number: number | undefined, total: number | undefined) => {
        if (number === null || number === undefined) {
            number = 0
        }

        if (total === null || total === undefined) {
            total = 0
        }

        if (total === 0) {
            return "-"
        }

        const percentage = (number / total) * 100
        return percentage.toFixed(1) + "%"

    }

    const calculateNetPercentage = (FPnumber: number | undefined, SPnumber: number | undefined, FPtotal: number | undefined, SPtotal: number | undefined) => {
        if (FPnumber === null || FPnumber === undefined) {
            FPnumber = 0
        }

        if (SPnumber === null || SPnumber === undefined) {
            SPnumber = 0
        }

        if (FPtotal === null || FPtotal === undefined) {
            FPtotal = 0
        }

        if (SPtotal === null || SPtotal === undefined) {
            SPtotal = 0
        }

        const total = FPtotal + SPtotal

        if (total === 0) {
            return "-"
        }

        const percentage = ((FPnumber + SPnumber) / total) * 100
        return percentage.toFixed(1) + "%"

    }

    const calculatePercentageAfterDiscount = (FPTotal: number | undefined, SPTotal: number | undefined, FPVD: number | undefined, SPVD: number | undefined, FPVV: number | undefined, SPVV: number | undefined, FPPF: number | undefined, SPPF: number | undefined, FPTC: number | undefined, SPTC: number | undefined,) => {
        if (FPTotal === null || FPTotal === undefined) {
            FPTotal = 0
        }

        if (SPTotal === null || SPTotal === undefined) {
            SPTotal = 0
        }

        if (FPVD === null || FPVD === undefined) {
            FPVD = 0
        }

        if (SPVD === null || SPVD === undefined) {
            SPVD = 0
        }

        if (FPVV === null || FPVV === undefined) {
            FPVV = 0
        }

        if (SPVV === null || SPVV === undefined) {
            SPVV = 0
        }

        if (FPPF === null || FPPF === undefined) {
            FPPF = 0
        }

        if (SPPF === null || SPPF === undefined) {
            SPPF = 0
        }

        if (FPTC === null || FPTC === undefined) {
            FPTC = 0
        }

        if (SPTC === null || SPTC === undefined) {
            SPTC = 0
        }

        const total = FPTotal + SPTotal

        if (total === 0) {
            return "0%"
        }

        const TotalVD = FPVD + SPVD
        const TotalVV = FPVV + SPVV
        const TotalPF = FPPF + SPPF
        const TotalTC = FPTC + SPTC

        const percentage = TotalTC / (total - TotalVD - TotalVV - TotalPF) * 100
        return percentage.toFixed(1) + "%"
    }

    const content = (
        <>
            {(rawSummaryData?.K_Sum_7_1 && rawSummaryData.K_Sum_7_1.length >= 1) || (rawSummaryData?.K_Sum_7_2 && rawSummaryData.K_Sum_7_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ccffff" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount </td>
                                <td>{rawSummaryData?.K_Sum_7_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_7_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_7_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_7_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_7_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_7_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_7_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_7_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_7_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_7_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_7_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_7_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_7_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_7_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_7_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_7_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_7_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_7_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_7_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_7_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_7_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ccffff" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Modea</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount </td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_1_1 && rawSummaryData.K_Sum_1_1.length >= 1) || (rawSummaryData?.K_Sum_1_2 && rawSummaryData.K_Sum_1_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ccffcc" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> without any discount</td>
                                <td>{rawSummaryData?.K_Sum_1_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_1_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_1_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_1_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_1_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_1_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_1_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_1_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_1_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_1_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_1_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_1_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_1_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_1_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_1_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_1_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_1_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_1_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_1_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_1_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_1_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ccffcc" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> without any discount</td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_2_lt_1 && rawSummaryData.K_Sum_2_lt_1.length >= 1) || (rawSummaryData?.K_Sum_2_lt_2 && rawSummaryData.K_Sum_2_lt_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffffeb" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor discounts only <br /> (less than 10%)</td>
                                <td>{rawSummaryData?.K_Sum_2_lt_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_2_lt_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_lt_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_lt_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_lt_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_lt_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_lt_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_lt_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_2_lt_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_lt_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_lt_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffffeb" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor discounts only <br /> (less than 10%)</td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_2_1 && rawSummaryData.K_Sum_2_1.length >= 1) || (rawSummaryData?.K_Sum_2_2 && rawSummaryData.K_Sum_2_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffffcc" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor discounts only <br /> (=10%)</td>
                                <td>{rawSummaryData?.K_Sum_2_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_2_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_2_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_2_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_2_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_2_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_2_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_2_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_2_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_2_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_2_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_2_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffffcc" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor discounts only <br /> (=10%)</td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_3_1 && rawSummaryData.K_Sum_3_1.length >= 1) || (rawSummaryData?.K_Sum_3_2 && rawSummaryData.K_Sum_3_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffff00" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor discounts only <br /> (more than 10%)</td>
                                <td>{rawSummaryData?.K_Sum_3_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_3_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_3_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_3_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_3_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_3_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_3_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_3_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_3_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_3_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_3_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_3_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_3_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_3_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_3_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_3_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_3_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_3_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_3_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_3_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_3_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffff00" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor discounts only <br /> (more than 10%)</td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_4_1 && rawSummaryData.K_Sum_4_1.length >= 1) || (rawSummaryData?.K_Sum_4_2 && rawSummaryData.K_Sum_4_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#eaeaea" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor voucher only</td>
                                <td>{rawSummaryData?.K_Sum_4_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_4_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_4_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_4_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_4_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_4_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_4_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_4_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_4_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_4_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_4_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_4_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_4_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_4_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_4_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_4_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_4_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_4_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_4_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_4_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_4_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#eaeaea" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with vendor voucher only</td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_5_1 && rawSummaryData.K_Sum_5_1.length >= 1) || (rawSummaryData?.K_Sum_5_2 && rawSummaryData.K_Sum_5_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffccff" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with Pandabox Fee only</td>
                                <td>{rawSummaryData?.K_Sum_5_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_5_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_5_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_5_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_5_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_5_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_5_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_5_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_5_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_5_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_5_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_5_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_5_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_5_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_5_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_5_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_5_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_5_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_5_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_5_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_5_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffccff" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount <br /> with Pandabox Fee only</td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {(rawSummaryData?.K_Sum_6_1 && rawSummaryData.K_Sum_6_1.length >= 1) || (rawSummaryData?.K_Sum_6_2 && rawSummaryData.K_Sum_6_2.length >= 1) ? (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffcc99" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount with<br /> vendor voucher and <br /> Pandabox Fee </td>
                                <td>{rawSummaryData?.K_Sum_6_1?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_1?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_1?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_1?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>{rawSummaryData?.K_Sum_6_2?.[0]?.delivery_mode ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_2?.[0]?.vendor_discounts_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_2?.[0]?.vendor_voucher_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_2?.[0]?.foodpanda_price_sum) ?? "-"}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_2?.[0]?.total_commission_sum) ?? ""}</td>
                                <td>{convertOutputNumber(rawSummaryData?.K_Sum_6_2?.[0]?.foodpanda_balance_sum) ?? ""}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>{rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum === 0 ? "0%" : "100%"}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculatePercentage(rawSummaryData?.K_Sum_6_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_2?.[0]?.vendor_discounts_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_2?.[0]?.vendor_voucher_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_6_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_2?.[0]?.pandabox_fee_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_6_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_6_2?.[0]?.total_commission_sum)}</td>
                                <td>{calculateTotalNet(rawSummaryData?.K_Sum_6_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_6_2?.[0]?.foodpanda_balance_sum)}</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>{((rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum ?? 0) + (rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum ?? 0)) === 0 ? "0%" : "100%"}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_6_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_6_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_6_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_6_2?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                                <td>{calculateNetPercentage(rawSummaryData?.K_Sum_6_1?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_6_2?.[0]?.foodpanda_balance_sum, rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum)}</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>{calculatePercentageAfterDiscount(rawSummaryData?.K_Sum_6_1?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_2?.[0]?.products_gfv_sum, rawSummaryData?.K_Sum_6_1?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_2?.[0]?.vendor_discounts_sum, rawSummaryData?.K_Sum_6_1?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_2?.[0]?.vendor_voucher_sum, rawSummaryData?.K_Sum_6_1?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_2?.[0]?.pandabox_fee_sum, rawSummaryData?.K_Sum_6_1?.[0]?.total_commission_sum, rawSummaryData?.K_Sum_6_2?.[0]?.total_commission_sum)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className={css.EachTableDiv}>
                    <table style={{ backgroundColor: "#ffcc99" }}>
                        <thead>
                            <tr className={css.TableTitle}>
                                <th></th>
                                <th className={css.TDeliveryMode}>Delivery Mode</th>
                                <th className={css.TProductsGFV}><p className={css.char}>(A)</p> <br /> Products Total Value / GFV</th>
                                <th className={css.TVendorDiscounts}><p className={css.char}>(B)</p> <br /> Vendor Discounts</th>
                                <th className={css.TVendorVoucher}><p className={css.char}>(C)</p> <br /> Vendor Voucher</th>
                                <th className={css.TPandaboxFee}><p className={css.char}>(D)</p> <br /> Pandabox Fee</th>
                                <th className={css.TTotalCommission}><p className={css.char}>(G)</p> <br /> Total commission</th>
                                <th className={css.TBalance}><p className={css.char}>(H)= A-B-C-D-G</p> <br /> Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={css.ShowDataTr}>
                                <td className={css.TableTitle} rowSpan={6}>Total amount with<br /> vendor voucher and <br /> Pandabox Fee </td>
                                <td>FOODPANDA</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td>SELFPICKED</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td>Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr className={`${css.ShowDataTr} ${css.SubTotalDataTr}`}>
                                <td></td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                                <td>0%</td>
                            </tr>
                            <tr className={css.ShowDataTr}>
                                <td colSpan={6}>commission percentage after discounts :</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}



        </>
    )
    return content
}

export default C0217KeetaSummaryReport