import { useEffect, useState } from 'react'
import css from '../../css/C0533/AWADS.module.css'

import image01 from '../../global/C533/AWARS10.jpg'
import { IC0553WebsiteImage } from '../../Interface/interface'
import axios from 'axios'

const AWADS = () => {

    const [data, setData] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        goGetDataFromDB();
    }, [])

    const goGetDataFromDB = async () => {
        try {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "AWADL",
                item_section_code: "AWADL_"
            });

            if (res.status === 200) {
                setData(res.data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const getMatchImg = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        const urlpath = data[indexID].item_pic_url;
        const urldesc = data[indexID].item_desc;
        return <img src={urlpath} alt={urldesc} />;
    };

    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                <p>本地賽事獎項</p>
            </div>


            <div className={css.soloAWADS}>
                <div className={css.EachAWADSDiv}>
                    <div className={css.PhotoDiv}>
                        {getMatchImg(data, "AWADL_01")}
                    </div>
                    <div className={css.TextDiv}>
                        <p className={css.AWADSTitle}>全港跳繩精英賽2023－團體賽</p>
                        <p className={css.AWADSTitle}>(入選香港跳繩代表隊 合共30人)</p>
                        <p className={css.AWADSDesc}>本社在是次共獲得 37金，16銀，15銅 。合共68面獎牌。</p>
                        <br />
                        <br />
                        <p className={css.AWADSTitle}>全港跳繩精英賽2021－團體賽及個人賽 </p>
                        <p className={css.AWADSDesc}>本社在是次共獲得 17金，27銀，9銅 。合共53面獎牌。</p>
                    </div>
                </div>

                <div className={css.EachAWADSDiv}>
                    <div className={css.PhotoDiv}>
                        {getMatchImg(data, "AWADL_02")}
                    </div>
                    <div className={css.TextDiv}>
                        <p className={css.AWADSTitle}>全港跳繩分齡賽2021－團體賽及個人賽 </p>
                        <p className={css.AWADSDesc}>本社在是次共獲得 9金，7銀，11銅 。合共27面獎牌。</p>
                        <br />
                        <br />
                        <p className={css.AWADSTitle}>全港跳繩分齡賽2020－團體賽及個人賽 </p>
                        <p className={css.AWADSDesc}>本社在是次共獲得 31金，21銀，19銅 。合共71面獎牌。</p>
                    </div>
                </div>

                <div className={css.EachAWADSDiv}>
                    <div className={css.PhotoDiv}>
                        {getMatchImg(data, "AWADL_03")}
                    </div>
                    <div className={css.TextDiv}>
                        <p className={css.AWADSTitle}>全港跳繩分齡賽2018－團體賽及個人賽</p>
                        <p className={css.AWADSDesc}>各個項目合共取得 48面獎牌 ，更取得多過總成績獎項。</p>
                    </div>

                </div>

            </div>

            <div className={css.EachAWADSTeamDiv}>
                <div className={css.TeamPhotoDiv}>
                    {getMatchImg(data, "AWADL_04")}
                </div>
                <div className={css.TextDiv}>
                    <p className={css.AWADSTitle}>全港跳繩分齡賽2016－個人賽</p>
                    <p className={css.AWADSDesc}>9-11歲女子組個人總成績冠軍－黃樂瑤</p>
                    <p className={css.AWADSDesc}>30s速度跳競賽項目－冠軍</p>
                    <p className={css.AWADSDesc}>個人花式－亞軍</p>
                    <br />
                    <p className={css.AWADSDesc}>9-11歲女子組個人花式冠軍－黃貝瑤</p>
                    <br />
                    <p className={css.AWADSDesc}>12-14歲女子組個人總成績冠軍－謝穎思</p>
                    <p className={css.AWADSDesc}>3分鐘速度耐力跳－亞軍</p>
                    <p className={css.AWADSDesc}>個人花式項目－季軍</p>
                    <br />
                    <p className={css.AWADSDesc}>12-14歲女子組個人總成績殿軍－郭家慧</p>
                    <p className={css.AWADSDesc}>30s速度跳競賽項目－季軍</p>
                    <br />
                    <p className={css.AWADSDesc}>12-14歲男子組個人總成績亞軍－黎柏軒</p>
                    <p className={css.AWADSDesc}>個人花式項目－冠軍</p>
                    <br />
                    <p className={css.AWADSDesc}>12-14歲男子組－蘇正浩</p>
                    <p className={css.AWADSDesc}>30s速度跳競賽項目－冠軍</p>
                    <p className={css.AWADSDesc}>3分鐘速度耐力跳－冠軍</p>
                </div>

                <div className={css.TeamPhotoDiv}>
                    {getMatchImg(data, "AWADL_05")}
                </div>
                <div className={css.TextDiv}>
                    <p className={css.AWADSTitle}>全港跳繩分齡賽2016－團體賽</p>
                    <p className={css.AWADSDesc}>12-14歲女子組團體賽－總成績冠軍</p>
                    <p className={css.AWADSDesc}>12-14歲男子組團體賽－總成績冠軍</p>
                    <p className={css.AWADSDesc}>12-14歲男女混合組團體賽－總成績亞軍</p>
                    <p className={css.AWADSDesc}>15-17歲女子組團體賽－總成績亞軍</p>
                    <p className={css.AWADSDesc}>15-17歲男女混合組團體賽－總成績亞軍</p>
                </div>
            </div>

        </section>
    )
    return content
}
export default AWADS
