import { useState } from 'react'
import css from '../../../css/C0211/OrderToDn.module.css'


const OrderToDn = () => {

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.OrderToDn}>
                </div>
            </section>
        </>
    )

    return content
}

export default OrderToDn