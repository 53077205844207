import { Parallax } from 'react-parallax'
import css from '../../css/C0533/HomPageChannel.module.css'
import Hamburger from 'hamburger-react'

//import Photo
import Banner from '../../global/C533/Banner.png'
import blackBGImg from '../../global/C533/blackBGImg.jpg'

const HomePageChannel = () => {
    const content = (
        <section className={css.MainSection}>
            <div className={css.ChannelDiv}>
                <p className={css.ChannelTitle}>跳繩 Channel</p>
            </div>

            <div className={css.ChannelImgDiv}>
                <iframe
                    id="pl2"
                    className={css.ChannelVideo}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    title="【HKRSU跳繩Channel】EP11.花式跳繩裝備篇－鞋 | 跳繩教學 | 減肥 | 運動"
                    width="850"
                    height="478"
                    src="https://www.youtube-nocookie.com/embed/LJNBhSJErmU?playsinline=1&rel=0&enablejsapi=1&origin=http://www.hkrsu.com&widgetid=2"
                />
            </div>

            {/* <Parallax blur={0} bgImage={Banner} bgImageAlt="Banner" strength={600} className={css.Img} /> */}
            <div className={css.BannerDiv}>
                {/* <div className={css.BannerBGImg}><img  src={blackBGImg} alt="BGColor" /></div> */}
                <div className={css.BannerImg}><img  src={Banner} alt="Banner" /></div>
                {/* <div className={css.BannerBGImg}><img  src={blackBGImg} alt="BGColor" /></div> */}
            </div>
        </section>
    )
    return content
}

export default HomePageChannel