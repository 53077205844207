import Performances from '../../Components/C0533/Performances'

const PerformanceteamPage = () => {
    const content = (
        <>
        <Performances />
        </>
    )
    return content
}
export default PerformanceteamPage