import css from '../../css/Common/AlertMessageBox.module.css'
import { IMessageBoxProps } from '../../Interface/interface';

const AlertMessageBox: React.FC<IMessageBoxProps> = ({ MessageBoxOnOff, messageType, messageTitle, messageContent, returnBooleanResult }) => {

    const handleBooleanResult = (result: boolean) => {
        returnBooleanResult && returnBooleanResult(result)
        MessageBoxOnOff(false)
    }

    const content = (
        <>
            <section className={css.darkpannel}>
                <div className={css.MainDiv}>
                    <div className={css.MessageBox}>
                        <div className={css.MessageBoxHeader}>
                            <h3>{messageTitle}</h3>

                        </div>
                        <div className={css.MessageBoxContent}>
                            <p>{messageContent}</p>
                        </div>
                        <div className={css.MessageBoxFooter}>
                            <button className={css.RemovemBtn} onClick={() => handleBooleanResult(false)}>取消</button>
                            <button className={css.ConfirmBtn} onClick={() => handleBooleanResult(true)}>確認</button>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
    return content
}

export default AlertMessageBox;