import { useContext, useEffect, useState } from 'react';
import css from '../../../css/C0211/ProductList.module.css';
import { c0211ProductProps, IC0211Product, IC0211ProductSKU, IC0211ProductSKURef, IC0211ProductType } from '../../../Interface/interface';
import DeafultLoadingBar from '../../../Components/LoadingBar/DefaultLoadingBar';
import AlertMessage from '../../LoadingBar/AlertMessage';
import { CreateOutline, AddCircleOutline } from 'react-ionicons';
import axios from 'axios';
import { Cookies } from 'react-cookie';

import AddProductTypecmp from './AddProductType'
import { use } from 'i18next';

import { SystemContext } from '../../../Class/SystemPara';
import { UserContext } from '../../../Class/UserPara';
import { PreLoadingContext } from '../../../Class/C0211PreLoadingData';
import { C0211PreLoadingData } from '../../../Interface/Const';
import { set } from 'rsuite/esm/internals/utils/date';

const cookies = new Cookies();

const C0211ProductList: React.FC<c0211ProductProps> = ({ setProductTypeID, setProductID, setProductSKUID, setProductSKUCode, isProductID, setEditMode }) => {

    const userContext = useContext(UserContext);
    const preLoadDataContext = useContext(PreLoadingContext);

    const [data, setData] = useState<IC0211Product[]>(preLoadDataContext.preLoadData.isProductList);
    const [typedata, setTypeData] = useState<IC0211ProductType[]>(preLoadDataContext.preLoadData.isProductTypeList);
    const [skudata, setSKUData] = useState<IC0211ProductSKU[]>(preLoadDataContext.preLoadData.isProductSKUList);
    const [skurefdata, setSKURefData] = useState<IC0211ProductSKURef[]>(preLoadDataContext.preLoadData.isProductSKURefList);
    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]); // Track selected product types
    const [filteredData, setFilteredData] = useState<IC0211Product[]>(preLoadDataContext.preLoadData.isProductList); // Filtered product list
    const [controlPanelMode, setControlPanelMode] = useState<number | null>(null);
    const [controlListMode, setControlListMode] = useState<number>(2);
    const [isAddMode, setAddMode] = useState<number | null>(null);
    const [isTypeID, setTypeID] = useState<number>(-1);
    const [isProductCode, setProductCode] = useState<string>('');
    const [isProductName, setProductName] = useState<string>('');
    const [isProductDesc, setProductDesc] = useState<string>('');
    const [isProductAutoInputDesc, setProductAutoInputDesc] = useState<boolean>(true);
    const [isProductNameStatus, setProductNameStatus] = useState<string>('');

    //Type
    const [isNewProductType, setNewProductType] = useState<string>('');
    const [isNewProductName, setNewProductName] = useState<string>('');
    const [isNewProductDesc, setNewProductDesc] = useState<string>('');
    const [isNewProductAutoInputDesc, setNewProductAutoInputDesc] = useState<boolean>(true);
    const [isProductTypeStatus, setProductTypeStatus] = useState<string>('');

    //SKU
    const [isNewProductSKUCode, setNewProductSKUCode] = useState<string>('');
    const [isNewProductSKUName, setNewProductSKUName] = useState<string>('');
    const [isProductCodeSKUStatus, setProductCodeSKUStatus] = useState<string>('');

    //Error Message
    const [errorProductField, setErrorProductField] = useState({
        Type: false,
        Code: false,
        Name: false,
        Desc: false
    });

    const [errorProductTypeField, setErrorProductTypeField] = useState({
        Code: false,
        Name: false,
        Desc: false
    });

    const [errorProductSKUField, setErrorProductSKUField] = useState({
        Code: false,
    });


    useEffect(() => {
        if (preLoadDataContext.preLoadData.isProductList.length === 0 ||
            preLoadDataContext.preLoadData.isProductTypeList.length === 0 ||
            preLoadDataContext.preLoadData.isProductSKUList.length === 0 ||
            preLoadDataContext.preLoadData.isProductSKURefList.length === 0) {
            setLoading(true);
            GetLoadingDefaultData();
        }
    }, []);

    useEffect(() => {
        filterProductsByType(); // Re-filter products when selected types change
    }, [selectedTypes, data]);

    useEffect(() => {
        if (isProductDesc.length === 0) {
            setProductAutoInputDesc(true);
        }
    }, [isProductDesc]);

    useEffect(() => {
        if (isNewProductDesc.length === 0) {
            setNewProductAutoInputDesc(true);
        }
    }, [isNewProductDesc]);

    useEffect(() => {
        if (isProductCode.length === 5) {
            getFindMatchProduct();
        } else {
            setProductNameStatus('');
        }
    }, [isProductCode]);

    useEffect(() => {
        if (isNewProductType.length === 4) {
            getFindMatchProductType();
        } else {
            setProductTypeStatus('');
        }
    }, [isNewProductType])

    useEffect(() => {
        if (isNewProductSKUCode.length === 6) {
            getFindMatchProductSKU();
        } else {
            setProductCodeSKUStatus('');
        }
    }, [isNewProductSKUCode]);


    useEffect(() => {
        if (isShowAlert) {
            // Automatically hide the alert after 3 seconds
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage('');
            }, 8000);
        }
    }, [isShowAlert])

    //Submit New Product Function
    const submitNewProduct = async () => {
        if (!userContext.FuncPermission.some(permission => permission.function === 2601)) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage("You don't have permission to add a new product!");
            return;
        }

        const errors = {
            Type: isTypeID === -1,
            Code: isProductCode === "",
            Name: isProductName === "",
            Desc: isProductDesc === ""
        };

        setErrorProductField(errors);

        if (isTypeID === -1) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please select a product type!');
            return;
        }

        if (isProductCode.length !== 5) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please follow the product code format!');
            return;
        } else {
            const getmatchProduct = data.filter((Product) => Product.Product_Code === isProductCode);
            if (getmatchProduct.length > 0) {
                setShowAlert(true);
                setAlertType('Error');
                setAlertMessage('Product code already exists!');
                return;
            }
        }

        if (isProductName.length === 0) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please enter a product name!');
            return;
        }

        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertUpdateProductMain`, {
                product_code: isProductCode,
                product_type_id: isTypeID,
                product_name: isProductName,
                product_desc: isProductDesc
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            });

            if (res.status === 200) {
                setShowAlert(true);
                setAlertType('Success');
                setAlertMessage('Product successfully added!');
                setProductCode('');
                setProductName('');
                setProductDesc('');
                setTypeID(-1);
                GetLoadingDefaultData();
            }
        } catch (error) {
            console.log(error);
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Failed to add product!');
        } finally {
            await C0211PreLoadingData(userContext, preLoadDataContext);
            setLoading(false);
        }
    };

    const submitNewProductType = async () => {
        if (!userContext.FuncPermission.some(permission => permission.function === 2600)) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage("You don't have permission to add a new product type!");
            return;
        }

        const errors = {
            Code: isNewProductType === "",
            Name: isNewProductName === "",
            Desc: isNewProductDesc === ""
        };

        setErrorProductTypeField(errors);

        if (isNewProductType.length !== 4) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please follow the product type code format!');
            return;
        } else {
            const getmatchProduct = typedata.filter((ProductType) => ProductType.product_type_code === isNewProductType);
            if (getmatchProduct.length > 0) {
                setShowAlert(true);
                setAlertType('Error');
                setAlertMessage('Product type code already exists!');
                return;
            }
        }

        if (isNewProductName.length === 0) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please enter a product type name!');
            return;
        }

        if (isNewProductDesc.length === 0) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please enter a product type description!');
            return;
        }

        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
                product_type_code: isNewProductType,
                product_type_name: isNewProductName,
                product_type_desc: isNewProductDesc
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            });

            if (res.status === 200) {
                setShowAlert(true);
                setAlertType('Success');
                setAlertMessage('Product type successfully added!');
                setNewProductType('');
                setNewProductName('');
                setNewProductDesc('');
                GetLoadingDefaultData();
            }

        } catch (error) {
            console.log(error);
        } finally {
            await C0211PreLoadingData(userContext, preLoadDataContext);
            setLoading(false);
        }

    }

    const submitNewProductSKU = async () => {

        if (!userContext.FuncPermission.some(permission => permission.function === 2602)) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage("You don't have permission to add a new product SKU!");
            return;
        }

        const errors = {
            Code: isNewProductSKUCode === "",
        };

        setErrorProductSKUField(errors);

        if (isNewProductSKUCode.length !== 6) {
            setShowAlert(true);
            setAlertType('Error');
            setAlertMessage('Please follow the product SKU code format!');
            return;
        } else {
            const getmatchProduct = skudata.filter((ProductSKU) => ProductSKU.Product_Code_SKU === isNewProductSKUCode);
            if (getmatchProduct.length > 0) {
                setShowAlert(true);
                setAlertType('Error');
                setAlertMessage('Product SKU code already exists!');
                return;
            }
        }

        try {
            console.log(isNewProductSKUCode);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductSKU_Web`, {
                product_sku_code: isNewProductSKUCode
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            });

            if (res.status === 200) {
                setShowAlert(true);
                setAlertType('Success');
                setAlertMessage('Product SKU successfully added!');
                setNewProductSKUCode('');
                GetLoadingDefaultData();
            }

        } catch (error) {
            console.log(error);
        } finally {
            await C0211PreLoadingData(userContext, preLoadDataContext);
            setLoading(false);
        }
    }

    const getFindMatchProductSKU = () => {
        if (isNewProductSKUCode.length === null || isNewProductSKUCode.length <= 0) {
            setProductCodeSKUStatus('');
            return;
        }
        const getmatchProduct = skudata.filter((ProductSKU) => ProductSKU.Product_Code_SKU === isNewProductSKUCode);
        if (getmatchProduct.length > 0) {
            setProductCodeSKUStatus('❌');
        } else {
            setProductCodeSKUStatus('✅');
        }
    }

    const getFindMatchProduct = () => {
        if (isProductCode.length === null || isProductCode.length <= 0) {
            setProductNameStatus('');
            return;
        }
        const getmatchProduct = data.filter((Product) => Product.Product_Code === isProductCode);
        if (getmatchProduct.length > 0) {
            setProductNameStatus('❌');
        } else {
            setProductNameStatus('✅');
        }
    }

    const getFindMatchProductType = () => {
        if (isNewProductType.length === null || isNewProductType.length <= 0) {
            setProductTypeStatus('');
            return;
        }
        const getmatchProduct = typedata.filter((ProductType) => ProductType.product_type_code === isNewProductType);
        if (getmatchProduct.length > 0) {
            setProductTypeStatus('❌');
        } else {
            setProductTypeStatus('✅');
        }
    }

    const GetLoadingDefaultData = async () => {
        const token = cookies.get('token');
        try {
            const AllProductListRes = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductList`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const AllProductTypeRes = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const AllProductSKURes = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/goUpdateProductList`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const AllProductSKURefRes = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductSKURef`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (AllProductListRes.status === 200) {
                setData(AllProductListRes.data.Product);
                setFilteredData(AllProductListRes.data.Product);
            }

            if (AllProductTypeRes.status === 200) {
                setTypeData(AllProductTypeRes.data['Product Type']);
            }

            if (AllProductSKURes.status === 200) {
                setSKUData(AllProductSKURes.data.Product);
            }

            if (AllProductSKURefRes.status === 200) {
                setSKURefData(AllProductSKURefRes.data.ProductSKURef);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleTypeSelection = (typeCode: string) => {
        if (selectedTypes.includes(typeCode)) {
            // If the type is already selected, remove it from the selected list
            setSelectedTypes(prevSelected => prevSelected.filter(code => code !== typeCode));
        } else {
            // Otherwise, add the selected type to the list
            setSelectedTypes(prevSelected => [...prevSelected, typeCode]);
        }
    };

    const filterProductsByType = () => {
        if (selectedTypes.length === 0) {
            // If no type is selected, show all products
            setFilteredData(data);
        } else {
            // Filter products based on selected product types
            const filtered = data.filter(product => selectedTypes.includes(product.Type_Code));
            setFilteredData(filtered);
        }
    };


    // Search Product Function
    function SearchFunc() {
        const input = document.getElementById("SearchTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("TableList") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdProductCode = tr[i].getElementsByTagName("td")[1];
            const tdProductName = tr[i].getElementsByTagName("td")[2];
            if (tdProductCode || tdProductName) {
                const txtStaffIDValue = tdProductCode.textContent || tdProductCode.innerText;
                const txtUsernameValue = tdProductName.textContent || tdProductName.innerText;
                if (txtStaffIDValue.toUpperCase().indexOf(filter) > -1 || txtUsernameValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    // Search Product Function
    function SearchTypeFunc() {
        const input = document.getElementById("SearchTypeTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("TableTypeList") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdTypeCode = tr[i].getElementsByTagName("td")[0];
            const tdTypeName = tr[i].getElementsByTagName("td")[1];
            if (tdTypeCode || tdTypeName) {
                const txtStaffIDValue = tdTypeCode.textContent || tdTypeCode.innerText;
                const txtUsernameValue = tdTypeName.textContent || tdTypeName.innerText;
                if (txtStaffIDValue.toUpperCase().indexOf(filter) > -1 || txtUsernameValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    // Search Product Function
    function SearchSKUFunc() {
        const input = document.getElementById("SearchSKUTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("TableSKUList") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdSKUCode = tr[i].getElementsByTagName("td")[0];
            const tdSKUName = tr[i].getElementsByTagName("td")[1];
            const tdSKUDesc = tr[i].getElementsByTagName("td")[2];
            if (tdSKUCode || tdSKUName || tdSKUDesc) {
                const txtStaffIDValue = tdSKUCode.textContent || tdSKUCode.innerText;
                const txtUsernameValue = tdSKUName.textContent || tdSKUName.innerText;
                const txtDescValue = tdSKUDesc.textContent || tdSKUDesc.innerText;
                if (txtStaffIDValue.toUpperCase().indexOf(filter) > -1 || txtUsernameValue.toUpperCase().indexOf(filter) > -1 || txtDescValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    // Search Product Function
    function SearchSKURefFunc() {
        const input = document.getElementById("SearchSKURefTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("TableSKURefList") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdSKUCode = tr[i].getElementsByTagName("td")[0];
            const tdSKUName = tr[i].getElementsByTagName("td")[1];
            if (tdSKUCode || tdSKUName) {
                const txtStaffIDValue = tdSKUCode.textContent || tdSKUCode.innerText;
                const txtUsernameValue = tdSKUName.textContent || tdSKUName.innerText;
                if (txtStaffIDValue.toUpperCase().indexOf(filter) > -1 || txtUsernameValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const handleControlPanelMode = (mode: number) => {
        if (controlPanelMode === mode) {
            setControlPanelMode(null);
        } else {
            setControlPanelMode(mode);
        }
    }
    const handleAddPanelMode = (mode: number) => {
        if (isAddMode === mode) {
            setAddMode(null);
        } else {
            setAddMode(mode);
        }
    }

    const EditProduct = (ProductID: number) => {
        setProductID && setProductID(ProductID);
        setEditMode && setEditMode(2);

    }


    const EditProductType = (ProductType: number) => {
        setProductTypeID && setProductTypeID(ProductType);
        setEditMode && setEditMode(1);

    }

    const routerPageTitle = () => {
        switch (controlListMode) {
            case 1:
                return "Product Type List";
            case 2:
                return "Product List";
            case 3:
                return "Product SKU List";
            case 4:
                return "Product SKU Reference List";
        }
        return "ERROR";
    }

    const checkProductSKUDetail = (ProductID: number, ProductSKUID: number, ProductSKUCode: string) => {
        setProductID && setProductID(ProductID);
        setProductSKUID && setProductSKUID(ProductSKUID);
        setProductSKUCode && setProductSKUCode(ProductSKUCode);
        setEditMode && setEditMode(3);
    }

    const RefreshData = async () => {
        setLoading(true);
        await GetLoadingDefaultData();
        setShowAlert(true);
        setAlertType('Success');
        setAlertMessage('Data refreshed!');
    }


    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar />}

            {/* {isShowAlert && <AlertMessage isAlertMessage={isAlertMessage} />} */}

            <section className={css.MainSection}>
                <div className={css.TopDiv}>
                    <div>
                        <p className={css.Title}>{routerPageTitle()}</p>
                    </div>
                    <div className={css.RefreshBtnContainer}>
                        <i className={`fi fi-br-refresh ${css.RefreshIcon}`} onClick={() => RefreshData()}/>
                    </div>
                </div>

                <div className={css.btnControlPanel}>

                    {controlListMode === 1 && (
                        <div className={css.SelectTypeBtnContainer}>
                            <button className={css.SelectTypeBtn} onClick={() => handleControlPanelMode(4)}>
                                <i className={`fi fi-rr-analyse-alt ${css.SelectTypeIcon}`} />
                                Filter
                            </button>
                        </div>
                    )}

                    {controlListMode === 2 && (
                        <div className={css.SelectTypeBtnContainer}>
                            <button className={css.SelectTypeBtn} onClick={() => handleControlPanelMode(1)}>
                                <i className={`fi fi-rr-analyse-alt ${css.SelectTypeIcon}`} />
                                Filter
                            </button>
                        </div>
                    )}

                    {controlListMode === 3 && (
                        <div className={css.SelectTypeBtnContainer}>
                            <button className={css.SelectTypeBtn} onClick={() => handleControlPanelMode(5)}>
                                <i className={`fi fi-rr-analyse-alt ${css.SelectTypeIcon}`} />
                                Filter
                            </button>
                        </div>
                    )}

                    {controlListMode === 4 && (
                        <div className={css.SelectTypeBtnContainer}>
                            <button className={css.SelectTypeBtn} onClick={() => handleControlPanelMode(6)}>
                                <i className={`fi fi-rr-analyse-alt ${css.SelectTypeIcon}`} />
                                Filter
                            </button>
                        </div>
                    )}

                    {/* <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => handleControlPanelMode(2)}>
                            <i className={`fi fi-rs-apps-add ${css.SelectTypeIcon}`} />
                            Insert New Product
                        </button>
                    </div> */}

                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => handleControlPanelMode(3)}>
                            <i className={`fi fi-br-list ${css.SelectTypeIcon}`} />
                            Switch List View
                        </button>
                    </div>

                </div>

                {controlPanelMode === 1 && (
                    <div className={css.ControlBar}>
                        <input
                            type="text"
                            id="SearchTxt"
                            onKeyUp={SearchFunc}
                            className={css.SeachUser}
                            placeholder="Search for product code / name"
                        />

                        <fieldset className={css.TypeFieldset}>
                            <legend>Choose your product's type:</legend>
                            {typedata.map((ProductType) => (
                                <div key={ProductType.product_type_id} className={css.ProductTypeFieldset}>
                                    <input
                                        type="checkbox"
                                        id={ProductType.product_type_code}
                                        name={ProductType.product_type_code}
                                        checked={selectedTypes.includes(ProductType.product_type_code)} // Check if selected
                                        onChange={() => handleTypeSelection(ProductType.product_type_code)} // Handle selection change
                                    />
                                    <label htmlFor={ProductType.product_type_code} className={css.Typelable}>
                                        {ProductType.product_type_name}
                                    </label>
                                </div>
                            ))}
                        </fieldset>
                    </div>
                )}

                {controlPanelMode === 2 && (
                    <div className={css.BtnControlBar}>

                        <div className={css.ProdBtnContainer}>
                            <button className={css.AddContainerBtn} onClick={() => handleAddPanelMode(1)}>
                                <i className={`fi fi-rr-note ${css.SelectTypeIcon}`} />
                                Add New Type
                            </button>
                        </div>

                        <div className={css.ProdBtnContainer}>
                            <button className={css.AddContainerBtn} onClick={() => handleAddPanelMode(2)}>
                                <i className={`fi fi-rr-boxes ${css.SelectTypeIcon}`} />
                                Add New Product
                            </button>
                        </div>

                        <div className={css.ProdBtnContainer}>
                            <button className={css.AddContainerBtn} onClick={() => handleAddPanelMode(3)}>
                                <i className={`fi fi-rs-chart-tree ${css.SelectTypeIcon}`} />
                                Add New SKU
                            </button>
                        </div>

                    </div>
                )}

                {controlPanelMode === 3 && (
                    <div className={css.BtnControlBar}>

                        <div className={css.ProdBtnContainer}>
                            <button className={`${css.AddContainerBtn} ${controlListMode === 1 ? css.activeBtn : ''}`} onClick={() => setControlListMode(1)}>
                                <i className={`fi fi-rr-note ${css.SelectTypeIcon}`} />
                                Product Type List
                            </button>
                        </div>

                        <div className={css.ProdBtnContainer}>
                            <button className={`${css.AddContainerBtn} ${controlListMode === 2 ? css.activeBtn : ''}`} onClick={() => setControlListMode(2)}>
                                <i className={`fi fi-rr-boxes ${css.SelectTypeIcon}`} />
                                Product List
                            </button>
                        </div>

                        <div className={css.ProdBtnContainer}>
                            <button className={`${css.AddContainerBtn} ${controlListMode === 3 ? css.activeBtn : ''}`} onClick={() => setControlListMode(3)}>
                                <i className={`fi fi-rs-chart-tree ${css.SelectTypeIcon}`} />
                                Product SKU List
                            </button>
                        </div>

                        <div className={css.ProdBtnContainer}>
                            <button className={`${css.AddContainerBtn} ${controlListMode === 4 ? css.activeBtn : ''}`} onClick={() => setControlListMode(4)}>
                                <i className={`fi fi-rr-document ${css.SelectTypeIcon}`} />
                                Product SKU Reference List
                            </button>
                        </div>

                    </div>
                )}

                {controlPanelMode === 4 && (
                    <div className={css.ControlBar}>
                        <input
                            type="text"
                            id="SearchTypeTxt"
                            onKeyUp={SearchTypeFunc}
                            className={css.SeachUser}
                            placeholder="Search for product code / name"
                        />

                    </div>
                )}

                {controlPanelMode === 5 && (
                    <div className={css.ControlBar}>
                        <input
                            type="text"
                            id="SearchSKUTxt"
                            onKeyUp={SearchSKUFunc}
                            className={css.SeachUser}
                            placeholder="Search for SKU code / name / description"
                        />

                    </div>
                )}

                {controlPanelMode === 6 && (
                    <div className={css.ControlBar}>
                        <input
                            type="text"
                            id="SearchSKURefTxt"
                            onKeyUp={SearchSKURefFunc}
                            className={css.SeachUser}
                            placeholder="Search for SKU code / Reference"
                        />

                    </div>
                )}

                {isAddMode === null && controlListMode === 1 && (
                    <div className={css.ListUser}>
                        <table className={css.UserTable} id="TableTypeList">
                            <thead>
                                <tr>
                                    <th>Type Code</th>
                                    <th>Type Name</th>
                                    <th>Type Description</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isNewProductType}
                                                onChange={(e) => setNewProductType(e.target.value)}
                                                className={errorProductTypeField.Code ? css.errorInputDiv : ''}
                                            />
                                            {isProductTypeStatus}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isNewProductName}
                                                onChange={(e) => {
                                                    setNewProductName(e.target.value)
                                                    if (isNewProductAutoInputDesc) {
                                                        setNewProductDesc("介紹 " + e.target.value + " 的詳細資料");
                                                    }
                                                }}
                                                className={errorProductTypeField.Name ? css.errorInputDiv : ''}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isNewProductDesc}
                                                onChange={(e) => {
                                                    setNewProductDesc(e.target.value);
                                                    setNewProductAutoInputDesc(false);
                                                }}
                                                className={errorProductTypeField.Desc ? css.errorInputDiv : ''}
                                            // disabled={true}
                                            />
                                        </div>
                                    </td>
                                    <td>Y</td>
                                    <td className={css.EditUserBtn}>
                                        <AddCircleOutline
                                            color={'#032654'}
                                            height="30px"
                                            width="30px"
                                            onClick={() => submitNewProductType()}
                                        />
                                    </td>
                                </tr>

                                {typedata.map(Type => (
                                    <tr key={Type.product_type_id}>
                                        <td>{Type.product_type_code}</td>
                                        <td>{Type.product_type_name}</td>
                                        <td>{Type.product_type_name}</td>
                                        <td>Y</td>
                                        <td className={css.EditUserBtn}>
                                            <CreateOutline
                                                color={'#032654'}
                                                height="30px"
                                                width="30px"
                                                onClick={() => EditProductType(Type.product_type_id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {isAddMode === null && controlListMode === 2 && (
                    <div className={css.ListUser}>
                        <table className={css.UserTable} id="TableList">
                            <thead>
                                <tr>
                                    <th>Product Type</th>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>Product Description</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <select
                                                value={isTypeID}
                                                onChange={(e) => setTypeID(Number(e.target.value))}
                                                className={errorProductField.Type ? css.errorInputDiv : ''}
                                            >
                                                <option value="-1"></option>
                                                {typedata.map((ProductType) => (
                                                    <option value={ProductType.product_type_id}>{ProductType.product_type_name}</option>
                                                ))}


                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isProductCode}
                                                onChange={(e) => setProductCode(e.target.value)}
                                                className={errorProductField.Code ? css.errorInputDiv : ''}
                                            />
                                            {isProductNameStatus}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isProductName}
                                                onChange={(e) => {
                                                    setProductName(e.target.value)
                                                    if (isProductAutoInputDesc) {
                                                        setProductDesc("介紹 " + e.target.value + " 的詳細資料");
                                                    }
                                                }}
                                                className={errorProductField.Name ? css.errorInputDiv : ''}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isProductDesc}
                                                onChange={(e) => {
                                                    setProductDesc(e.target.value);
                                                    setProductAutoInputDesc(false);
                                                }}
                                                className={errorProductField.Desc ? css.errorInputDiv : ''}
                                            // disabled={true}
                                            />
                                        </div>
                                    </td>
                                    <td>Y</td>
                                    <td className={css.EditUserBtn}>
                                        <AddCircleOutline
                                            color={'#032654'}
                                            height="30px"
                                            width="30px"
                                            onClick={() => submitNewProduct()}
                                        />
                                    </td>
                                </tr>

                                {filteredData.map(Product => (
                                    <tr key={Product.Product_ID}>
                                        <td>{Product.Type_Name}</td>
                                        <td>{Product.Product_Code}</td>
                                        <td>{Product.Product_Name}</td>
                                        <td>{Product.Product_Description}</td>
                                        <td>{Product.Status}</td>
                                        <td className={css.EditUserBtn}>
                                            <CreateOutline
                                                color={'#032654'}
                                                height="30px"
                                                width="30px"
                                                onClick={() => EditProduct(Product.Product_ID)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {isAddMode === null && controlListMode === 3 && (
                    <div className={css.ListUser}>
                        <table className={css.UserTable} id="TableSKUList">
                            <thead>
                                <tr>
                                    <th>SKU Code</th>
                                    <th>SKU Name</th>
                                    <th>SKU Description</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isNewProductSKUCode}
                                                onChange={(e) => setNewProductSKUCode(e.target.value)}
                                            />
                                            {isProductCodeSKUStatus}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value={isNewProductSKUName}
                                                onChange={(e) => setNewProductSKUName(e.target.value)}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className={css.TableInputDiv}>
                                            <input
                                                type="text"
                                                value=""
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                    <td>Y</td>
                                    <td className={css.EditUserBtn}>
                                        <AddCircleOutline
                                            color={'#032654'}
                                            height="30px"
                                            width="30px"
                                            onClick={() => submitNewProductSKU()}
                                        />
                                    </td>
                                </tr>

                                {skudata.map(SKU => (
                                    <tr key={`${SKU.Product_Main_ID} | ${SKU.Product_SKU_ID}`}>
                                        <td>{SKU.Product_Code_SKU}</td>
                                        <td>{SKU.Product_Name}</td>
                                        <td>{SKU.Product_Desc}</td>
                                        <td>{SKU.Product_Status}</td>
                                        <td className={css.EditUserBtn}>
                                            <CreateOutline
                                                color={'#032654'}
                                                height="30px"
                                                width="30px"
                                                onClick={() => checkProductSKUDetail(SKU.Product_Main_ID, SKU.Product_SKU_ID, SKU.Product_Code_SKU)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {isAddMode === null && controlListMode === 4 && (
                    <div className={css.ListUser}>
                        <table className={css.UserTable} id="TableSKURefList">
                            <thead>
                                <tr>
                                    <th style={{ maxWidth: '20%' }}>SKU Code</th>
                                    <th style={{ maxWidth: '20%' }}>SKU Ref</th>
                                    <th style={{ maxWidth: '20%' }}>Status</th>
                                    <th style={{ maxWidth: '20%' }}>Update On</th>
                                    <th style={{ maxWidth: '20%' }}>Update By</th>
                                </tr>
                            </thead>
                            <tbody>

                                {skurefdata.map(SKU => (
                                    <tr key={`${SKU.product_ref_id} | ${SKU.ref_value}`}>
                                        <td style={{ maxWidth: '20%' }}>{SKU.productCode}</td>
                                        <td style={{ maxWidth: '20%' }}>{SKU.ref_value}</td>
                                        <td style={{ maxWidth: '20%' }}>{SKU.status}</td>
                                        <td style={{ maxWidth: '20%' }}>{SKU.update_on}</td>
                                        <td style={{ maxWidth: '20%' }}>{SKU.sku_update_by}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {isAddMode === 1 && (
                    <AddProductTypecmp setAddMode={setAddMode} />
                )}

            </section>
        </>
    );

    return content;
};

export default C0211ProductList;
