import { useEffect, useState } from 'react'
import css from '../../css/C0533/AWADRSInternational.module.css'

import { IC0553WebsiteArr, IC0553WebsiteImage } from '../../Interface/interface'
import axios from 'axios'

const AWADRSInternational = () => {

    const [data, setData] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        goGetDataFromDB()
    }, [])

    const goGetDataFromDB = async () => {
        try {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "AWADRS",
                item_section_code: "AWADRS_"
            });

            if (res.status === 200) {
                setData(res.data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const getMatchImg = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)
    
        if (indexID < 0 || indexID >= data.length) {
            return null;
        }
        
        const urlpath = data[indexID].item_pic_url;
        const urldesc = data[indexID].item_desc;
        return <img src={urlpath} alt={urldesc} />;
    };

    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                <p>國際賽事獎項</p>
            </div>
            <div className={css.MainImage}>
                {getMatchImg(data, "AWADRS_02")}
            </div>
            <div className={css.ThreeInOne}>
                {/* <div className={css.OneSubImage}><img src={image01} alt="Main Image" /></div> */}
                <div className={css.OneSubImage}>{getMatchImg(data, "AWADRS_04")}</div>
                <div className={css.OneMainImage}>{getMatchImg(data, "AWADRS_01")}</div>
                <div className={css.OneSubImage}>{getMatchImg(data, "AWADRS_03")}</div>
            </div>

            <div className={css.SubTitle}>
                <p>世界跳繩錦標賽2024 (美國) (代表中國香港跳繩總會) 香港跳繩代表隊</p>
            </div>

            <div className={css.SubMessage}>
                <p>世界跳繩錦標賽2023, 世界青年錦標賽 和 國際公開賽是</p>
                <p>是次比賽本社共獲得 7金，13銀，9銅，合共取得29面獎牌。</p>
            </div>

            <div className={css.EachAwadrs}>
                <div className={css.AwadrsImgDiv}>
                    {getMatchImg(data, "AWADRS_05")}
                </div>
                <div className={css.AwadrsTextDiv}>
                    <p className={css.AwadrsTitle}>世界跳繩虛擬錦標賽2021 (代表中國香港跳繩總會)</p>
                    <p className={css.AwadrsTitle2}>香港跳繩代表隊</p>
                    <p className={css.AwadrsName}>鄭樂澄</p>
                    <p className={css.AwadrsList}>・12-15歲女子世界青少年跳繩錦標賽 個人總成績 季軍</p>
                    <p className={css.AwadrsList}>・個人花式 冠軍</p>
                    <p className={css.AwadrsName}>施穎</p>
                    <p className={css.AwadrsList}>・12-15歲女子世界青少年跳繩邀請賽 個人花式 冠軍</p>
                    <p className={css.AwadrsList}>是次比賽本社共獲得 3金，6銀，6銅，合共取得15面獎牌。</p>
                </div>
            </div>

            <div className={css.EachAwadrs}>
                <div className={css.AwadrsImgDiv}>
                    {getMatchImg(data, "AWADRS_06")}
                </div>
                <div className={css.AwadrsTextDiv}>
                    <p className={css.AwadrsTitle}>香港－亞洲跳繩錦標賽2019 (代表中國香港跳繩總會)</p>
                    <p className={css.AwadrsTitle2}>香港跳繩代表隊</p>
                    <p className={css.AwadrsList}>・12-14歲女子團體總成績 亞軍</p>
                </div>
            </div>

            <div className={css.EachAwadrs}>
                <div className={css.AwadrsImgDiv}>
                    {getMatchImg(data, "AWADRS_07")}
                </div>
                <div className={css.AwadrsTextDiv}>
                    <p className={css.AwadrsTitle}>上海－世界跳繩錦標賽2018 (代表中國香港跳繩總會)</p>
                    <p className={css.AwadrsTitle2}>香港跳繩代表隊</p>
                    <p className={css.AwadrsList}>・表演盃 總亞軍</p>
                    <p className={css.AwadrsList}>・12-14歲 男子個人總成績－亞軍 及 季軍</p>
                    <p className={css.AwadrsList}>・15歲或以上 男子團體賽 總成績－亞軍</p>
                    <p className={css.AwadrsList}>・12-14歲以下 女子團體賽 二人同步項目－亞軍</p>
                </div>
            </div>

            <div className={css.EachAwadrs}>
                <div className={css.AwadrsImgDiv}>
                    {getMatchImg(data, "AWADRS_08")}
                </div>
                <div className={css.AwadrsTextDiv}>
                    <p className={css.AwadrsTitle}>韓國－亞洲跳繩錦標賽2017 (代表中國香港跳繩總會)</p>
                    <p className={css.AwadrsTitle2}>香港跳繩代表隊</p>
                    <p className={css.AwadrsList}>・團體總成績 亞軍</p>
                    <p className={css.AwadrsList}>・4x30秒速度跳接力賽項目－亞軍</p>
                    <p className={css.AwadrsList}>・4人單人繩花式項目－亞軍</p>
                    <p className={css.AwadrsList}>・4人交互繩花式項目－季軍</p>
                </div>
            </div>

            <div className={css.TwoInOneDiv}>
                <div className={css.TwoInOne}>
                    <div className={css.TwoInOneImg}>
                    {getMatchImg(data, "AWADRS_09")}
                    </div>
                    <div className={css.TwoInOneImg}>
                    {getMatchImg(data, "AWADRS_10")}
                    </div>
                </div>
                <div className={css.SubAwadrs2}>
                    <p className={css.SubTitle2}>瑞典－世界跳繩錦標賽2016 (代表中國香港跳繩總會)</p>
                    <p className={css.SubTitle3}>香港跳繩代表隊</p>
                    <p className={css.AwadrsList}>・20參賽隊伍中，奪得總成績第六名</p>
                    <p className={css.AwadrsList}>・4x45秒交互繩速度跳競賽項目－亞軍</p>
                    <p className={css.AwadrsList}>・至1997年起首隊香港女子團體在世界賽賽事中奪得速度項目獎牌</p>
                </div>
            </div>

            <div className={css.EachAwadrs}>
                <div className={css.AwadrsImgDiv}>
                {getMatchImg(data, "AWADRS_11")}
                </div>
                <div className={css.AwadrsTextDiv}>
                    <p className={css.AwadrsTitle}>2016年全國青少年「未來之星」陽光體育大會</p>
                    <p className={css.AwadrsTitle2}>香港跳繩代表隊</p>
                    <p className={css.AwadrsList}>・本社精英運動員隊伍 Aces Joker 代表中國香港跳繩代表隊 參與該次比賽</p>
                    <p className={css.AwadrsList}>・並奪得全國冠軍『 一等獎 』</p>
                    <p className={css.AwadrsList}>・李健浤 更奪得『 未來之星獎 』</p>
                </div>
            </div>

            <div className={css.EachAwadrs}>
                <div className={css.AwadrsImgDiv}>
                    {getMatchImg(data, "AWADRS_12")}
                </div>
                <div className={css.AwadrsTextDiv}>
                    <p className={css.AwadrsTitle}>第八屆亞洲跳繩錦標賽(2015) (代表中國香港跳繩總會)</p>
                    <p className={css.AwadrsTitle2}>香港跳繩代表隊</p>
                    <p className={css.AwadrsList}>・團體四人單人繩比賽 - 季軍</p>
                    <p className={css.AwadrsList}>・團體三人交互繩比賽 - 冠軍</p>
                    <p className={css.AwadrsList}>・14歲或以下公開組總成績 - 第四名</p>
                </div>
            </div>



        </section>
    )
    return content
}

export default AWADRSInternational