import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import lng
import trENG from './locales/en/translation.json'
import trZH from './locales/zh/translation.json'

const resources = {
    en : {
        translation : trENG
    },
    zh : {
        translation :trZH
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "en",
    lng: "zh"
})