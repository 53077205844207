import Bcss from '../css/Banner.module.css'
import { Parallax } from 'react-parallax';
import bannerimg from '../global/home_slogan.jpg'
//import 'bootstrap/dist/css/bootstrap.css'


const Banner = () => {
    return (
        <section className={Bcss.MainSection}>
            <div className={Bcss.banner}>
                <Parallax blur={3} bgImage={bannerimg} bgImageAlt="the cat" strength={600} className={Bcss.Img}>
                    <p className={Bcss.SloganTitle}>科技，像一列不停站火車</p>
                    <p className={Bcss.SubSloganTitle}>仍在等待?</p>
                    <button type="button" className={Bcss.JoinUs1}>了解更多</button>
                    <button type="button" className={Bcss.JoinUs2}>加入我們?</button>
                </Parallax>
            </div>
        </section>
    );
};

export default Banner;
