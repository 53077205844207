import BaseUserInfo from '../../Components/Common/SettingUserBaseInfo'
import ChangeColor from '../../Components/Common/ChangeColor'
import css from '../../css/Common/DashSetting.module.css'

const DashSettingPage = () => {
    const content = (
        <>
            <section className={css.MainSection}>
                <BaseUserInfo />
                <ChangeColor />
            </section>
        </>
    )
    return content
}

export default DashSettingPage