import Maindashboard from "../.././../Components/C0211/Main/mainDashboard"
import FunctionCmp from "../../../Components/C0217/Main/FunctionList"

const c0217FunctionListPage = () => {
    const content = (
        <>
            <FunctionCmp />
        </>
    )

    return content
}

export default c0217FunctionListPage;