//Page
import PhoneSystemProduct from "../Components/PhoneSystemProduct"
import ImageSlider from "../Components/ImageSlider"

const PhoneSystem = () => {
    const content = (
        <div>
            <PhoneSystemProduct />
            <ImageSlider />
        </div>
    )
    return content
}

export default PhoneSystem