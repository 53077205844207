import { CreateOutline } from 'react-ionicons'


import css from '../css/UserList.module.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { IUserTable } from '../Interface/interface'

const UserList = () => {

    const [data, setData] = useState<IUserTable[]>([])

    async function GetFunctionList() {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/c0502/user/GetUserListByAcid`, {
                ac_id: 'c0211'
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
                .then(function (res) {
                    console.log(res.data);
                    setData(res.data.users)
                })
        } catch (error) {
            console.log(error)
        }
    }

    function SearchUserFc() {
        const input = document.getElementById("SearchUserTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("UserListTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                const txtValue = td.textContent || td.innerText;
                tr[i].style.display = txtValue.toUpperCase().indexOf(filter) > -1 ? "" : "none";
            }
        }
    }

    useEffect(() => {
        GetFunctionList();
    }, [])

    const content = (
        <>
            <section className={css.TopSection}>
                <div className={css.ControlBar}>
                    <p className={css.Title}>Employee List</p>

                    <input type="text" id="SearchUserTxt" onKeyUp={SearchUserFc} className={css.SeachUser} placeholder="Search for staff id.." ></input>
                </div>

                <div className={css.ListUser}>
                    <table className={css.UserTable} id="UserListTable">
                        <tr>
                            <th>Staff ID</th>
                            <th>Username</th>
                            <th>Department</th>
                            <th>Job Title</th>
                            <th>Premission Level</th>
                            <th>Status</th>
                            <th>Remarks</th>
                            <th>Edit</th>
                        </tr>
                        {data.map((user => (
                            <tr>
                                <td>{user.login_id}</td>
                                <td>{user.user_name}</td>
                                <td>{user.department}</td>
                                <td>{user.job_title}</td>
                                <td>{user.employment_type}</td>
                                <td>Y</td>
                                <td>{user.nationality}</td>
                                <td className={css.EditUserBtn}><CreateOutline
                                    color={'#032654'}
                                    height="30px"
                                    width="30px"
                                /></td>
                            </tr>
                        )))}
                    </table>
                </div>
            </section>
        </>
    )
    return content
}

export default UserList