import css from '../css/ACFunctionList.module.css'
import { useEffect, useState, useContext } from 'react'
import { IGroupedFunctions } from '../Interface/interface'
import { SystemContext } from '../Class/SystemPara'
import { FetchSystemData } from '../Interface/Const'
import { Link } from 'react-router-dom'



const ACFunctionList = () => {


    const systemContext = useContext(SystemContext);

    const [filterdata, SetFilterData] = useState<IGroupedFunctions[]>([])

    async function getmatchData() {
        // Use a local variable to store the new state
        let newFilterData = [...filterdata];

        systemContext.systemData.FunctionArr.forEach((item) => {
            const groupKey = item.function_section.split('-')[0];
            const isPresent = newFilterData.some((groupItem) => groupItem.function_section.split('-')[0] === groupKey);

            // If the groupKey isn't present, add the item
            if (!isPresent) {
                newFilterData.push(item);
            }
        });

        // Update the state only once after processing all items
        SetFilterData(newFilterData);
    }

    useEffect(() => {
        FetchSystemData(systemContext)
    }, []);

    useEffect(() => {
        getmatchData();
    }, [systemContext.systemData.FunctionArr]); // This effect runs every time 'data' changes

    const formatFNCName = (item: IGroupedFunctions) => {
        // Split the function_section string by the hyphen and take the first part
        const getFunctionName = item.function_section.split('-')[0];
        return getFunctionName;
    }

    const content = (
        <>
            <section className={css.ACFunctionListSection}>
                <div className={css.TitleDiv}>
                    <p>功能列表</p>
                </div>

                <hr />

                <div className={css.FuctionList}>

                    {filterdata.map((FNlist, index) => (
                        <div className={css.eachItem} key={index}>
                            <div className={css.eachItemTop}>
                                <div className={css.imgDiv}>
                                    <img alt="Function Photo" src={FNlist.function_img} />
                                </div>

                                <div className={css.informationDiv}>

                                    <div className={css.DataTitleDiv}>
                                        <p>{formatFNCName(FNlist)}</p>
                                        <p>{index}</p>
                                    </div>

                                    <div className={css.DataInfoDiv}>
                                        <div className={css.subinfobox}>
                                            <p className={css.SubTitleP}>狀態</p>
                                            <p className={css.SubDescP}>維護中</p>
                                        </div>

                                        <div className={css.subinfobox}>
                                            <p className={css.SubTitleP}>功能數量</p>
                                            <p className={css.SubDescP}>2</p>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className={css.ClickMeDiv}>
                                <Link to={{
                                    pathname: "/tsdash/ProductList",
                                    search: `FName=${formatFNCName(FNlist)}`
                                    }}>
                                    <div className={css.ClickBtnDiv}>
                                        <p>Click Me</p>
                                    </div>
                                </Link>
                            </div>

                        </div>


                    ))}
                </div>


            </section>
        </>
    )
    return content
}

export default ACFunctionList