import Tc from '../Components/Tc'

const TCPage = () => {
    const content = (
        <>
        <Tc />
        </>
    )
    return content
}
export default TCPage