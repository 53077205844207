import { useContext, useEffect, useState } from 'react'
import css from '../../../css/C0211/FunctionPage.module.css'
import { Ic0211Function } from '../../../Interface/interface'
import { Link } from 'react-router-dom'
import { UserContext } from '../../../Class/UserPara'

const C0211FunctionList: React.FC = () => {

    const userContext = useContext(UserContext);
    const [isSelectionType, setSelectionType] = useState<number>(0)
    const [functionList, setFunctionList] = useState<Ic0211Function[]>([
        {
            function_typeID: 2901,
            function_id: 2001,
            function_name: "員工列表",
            function_icon: "fi fi-rs-member-list",
            function_icon_BGcolor: "#7a5298",
            desc: "可以查看/編輯所有用户的列表",
            permission_id: 2001,
            function_path: "/C0211/UserList",
            function_status: "開發中",
            isShow: true,
        },
        {
            function_typeID: 2902,
            function_id: 2002,
            function_name: "產品列表",
            function_icon: "fi fi-rr-boxes",
            function_icon_BGcolor: "#168369",
            desc: "可以查看/編輯所有產品的資訊包括: (產品類別, 產品SKU)",
            permission_id: 2002,
            function_path: "/C0211/Product",
            function_status: "開發中",
            isShow: true,
        },
        {
            function_typeID: 2901,
            function_id: 2003,
            function_name: "商店列表",
            function_icon: "fi fi-rr-boxes",
            function_icon_BGcolor: "#163983",
            desc: "可以查看/編輯所有商店的詳細資訊",
            permission_id: 2003,
            function_path: "/C0211/Shop",
            function_status: "開發中",
            isShow: false,
        },
        {
            function_typeID: 2901,
            function_id: 2004,
            function_name: "打卡記錄",
            function_icon: "fi fi-rr-boxes",
            function_icon_BGcolor: "#7a5298",
            desc: "可以查看/編輯所有員工的打卡記錄",
            permission_id: 2004,
            function_path: "/C0211/Shop",
            function_status: "開發中",
            isShow: false,
        },
        {
            function_typeID: 2901,
            function_id: 2005,
            function_name: "工卡記錄",
            function_icon: "fi fi-rr-boxes",
            function_icon_BGcolor: "#7a5298",
            desc: "可以查看/編輯所有員工的已申報的工卡記錄",
            permission_id: 2005,
            function_path: "/C0211/Shop",
            function_status: "開發中",
            isShow: false,
        },
        {
            function_typeID: 2901,
            function_id: 2006,
            function_name: "打卡及工卡記錄",
            function_icon: "fi fi-rr-boxes",
            function_icon_BGcolor: "#7a5298",
            desc: "可以查看/編輯所有員工的已申報的工卡記錄",
            permission_id: 2006,
            function_path: "/C0211/Shop",
            function_status: "開發中",
            isShow: false,
        },
        {
            function_typeID: 2902,
            function_id: 2007,
            function_name: "Order To DN",
            function_icon: "fi fi-rr-refresh",
            function_icon_BGcolor: "#168369",
            desc: "可以把Order轉換成DN",
            permission_id: 2007,
            function_path: "/C0211/OrderToDN",
            function_status: "開發中",
            isShow: true,
        },
        {
            function_typeID: 2902,
            function_id: 2008,
            function_name: "Delivery Note 列表",
            function_icon: "fi fi-bs-inbox-out",
            function_icon_BGcolor: "#168369",
            desc: "可以建立/查看/編輯所有DN的列表",
            permission_id: 2007,
            function_path: "/C0211/DeliveryNote",
            function_status: "開發中",
            isShow: true,
        },
        {
            function_typeID: 2902,
            function_id: 2009,
            function_name: "PO Note 列表",
            function_icon: "fi fi-bs-inbox-in",
            function_icon_BGcolor: "#168369",
            desc: "可以建立/查看/編輯所有PO的列表",
            permission_id: 2007,
            function_path: "/C0211/PONote",
            function_status: "開發中",
            isShow: true,
        }
    ])

    const returnEachFunction = (permissionID: number) => {
        const FunctionCmp = (
            <div className={css.FunctionList}>
                {functionList.filter(item => item.function_typeID === permissionID && item.isShow === true && userContext.FuncPermission.some(permission => permission.function === item.permission_id)).map((item, index) => (
                    <div key={index} className={css.FunctionItem}>
                        <div className={css.FunctionIcon} style={{ backgroundColor: item.function_icon_BGcolor }}>
                            <i className={item.function_icon} />
                        </div>
                        <div className={css.FunctionName}>
                            {item.function_name}
                        </div>
                        <div className={css.FunctionDesc}>
                            {item.desc}
                        </div>
                        <div className={css.Functiondividingline} />
                        <div className={css.FunctionControlDiv}>
                            <div className={css.LinkDiv}>
                                <Link to={item.function_path}><button className={css.FunctionBtn}>Go</button></Link>
                            </div>
                            <div className={css.FunctionStatusDiv}>
                                <p className={css.functionStatus}>功能狀態:</p>
                                {item.function_status}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
        return FunctionCmp
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.TopDiv}>
                    <p className={css.PageTitle}>Function List</p>
                </div>

                <div className={css.dividingline} />

                <div className={css.SelectionDiv}>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(0)}>
                        <i className="fi fi-rs-user-add" />
                        ALL
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(1)}>
                        <i className="fi fi-rs-user-add" />
                        Human Resources
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(2)}>
                        <i className="fi fi-rs-user-add" />
                        Warehouse
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(3)}>
                        <i className="fi fi-rs-user-add" />
                        Sales
                    </button>
                    {/* <button className={css.TypeBtn} onClick={() => setSelectionType(4)}>
                        <i className="fi fi-rs-user-add" />
                        Develop Page
                    </button> */}
                </div>


                {isSelectionType === 0 && (
                    <div className={css.FunctionList}>
                        {functionList.filter(item => item.isShow === true && userContext.FuncPermission.some(permission => permission.function === item.permission_id)).map((item, index) => (
                            <div key={index} className={css.FunctionItem}>
                                <div className={css.FunctionIcon} style={{ backgroundColor: item.function_icon_BGcolor }}>
                                    <i className={item.function_icon} />
                                </div>
                                <div className={css.FunctionName}>
                                    {item.function_name}
                                </div>
                                <div className={css.FunctionDesc}>
                                    {item.desc}
                                </div>
                                <div className={css.Functiondividingline} />
                                <div className={css.FunctionControlDiv}>
                                    <div className={css.LinkDiv}>
                                        <Link to={item.function_path}><button className={css.FunctionBtn}>Go</button></Link>
                                    </div>
                                    <div className={css.FunctionStatusDiv}>
                                        <p className={css.functionStatus}>功能狀態:</p>
                                        {item.function_status}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                )}

                {isSelectionType === 1 && userContext.FuncPermission.some(permission => permission.function === 2901) && (
                    returnEachFunction(2901)
                )}

                {isSelectionType === 2 && userContext.FuncPermission.some(permission => permission.function === 2902) && (
                    returnEachFunction(2902)
                )}

                {isSelectionType === 3 && userContext.FuncPermission.some(permission => permission.function === 2903) && (
                    returnEachFunction(2903)
                )}


            </section>
        </>
    )
    return content
}

export default C0211FunctionList