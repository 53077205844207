//Page
import PCsystemProduct from "../Components/PCSystemProduct"
import ImageSlider from "../Components/ImageSlider"

const PCSystemPage = () => {
    const content = (
        <div>
            <PCsystemProduct />
            <ImageSlider />
        </div>
    )
    return content
}

export default PCSystemPage