import { useContext } from 'react';
import css from '../../css/Common/DashSetting.module.css'
import { UserContext } from '../../Class/UserPara';

const SettingUserBaseInfo = () => {

    const { userData, setUserData } = useContext(UserContext);

    const content = (
        <>
            <div className={css.UserBaseInfomationDiv}>
                <div className={css.MainTitile}>
                    <p>General User Information</p>
                </div>

                <div className={css.MainInputDiv}>
                    <div className={css.EachDisplayDiv}>

                        <div className={css.EachInputDiv}>
                            <label>Username</label>
                            <input type="text" value={userData.user_name} disabled={true} />
                        </div>

                        <div className={css.EachInputDiv}>
                            <label>Email</label>
                            <input type="text" value={userData.login_email} disabled={true} />
                        </div>

                        <div className={css.EachInputDiv}>
                            <label>Account Status</label>
                            <input type="text" value={userData.user_status} disabled={true} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
    return content
}

export default SettingUserBaseInfo