import { useEffect, useState } from "react";
import { Cookies } from 'react-cookie';
import css from '../../css/Common/DashSetting.module.css'
import { Theme } from "./Common/ColorPackage";

const cookies = new Cookies();

const ChangeColor = () => {
    // Define theme options using the Theme type"
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const themes: { [key: string]: Theme } = {
        default: {
            "primary-color": "#ffc146",
            "secondary-color": "#ffaa2a",
            "background-color": "#ffffff",
            "mina-button-background-color": "#751917",
            "sub-button-background-color": "#ef4c26",
            "sub-button-background-color-hover": "#014375",
            "button-text-color": "#ffffff",
            "page-title-text-color": "#000000",
            "sub-title-background-color": "#ffaa2a",
            "table-title-text-color": "#5e3737",
            "edit-button-color": "#032654",
            "input-div-background-color": "#f5f5f5",

            "dash-outlet-background-color":  "#ffc146",
            "dash-logo-background-color": "#ffffff",
            "dash-userinfo-background-color":  "#ffffff",
            "dash-navbar-background-color": "#ffffff",
        
            "dash-page-background-color": "#ffffff",
            "dash-page-title-text-color": "#741715",
            "dash-each-sub-div-background-color": "#f5f5f5",
            "dash-each-sub-div-border-color": "#b9b9b9",
            "dash-each-sub-div-title-background-color": "#ffaa2a",
            "dash-each-sub-div-title-color": "#000000",
            "dash-dividingline-color": "#b9b9b9",
        
            "dash-primary-button-background-color": "#751917",
            "dash-primary-button-text-color": "#ffffff",
            "dash-primary-button-background-color-hover": "#8c1f1f",
            "dash-primary-button-text-color-hover": "#ffffff",
        
            "dash-secondary-button-background-color": "#ef4c26",
            "dash-secondary-button-text-color": "#ffffff",
            "dash-secondary-button-background-color-hover": "#ff6a3d",
            "dash-secondary-button-text-color-hover": "#ffffff",
        },
        dark: {
            "primary-color": "#333333",
            "secondary-color": "#555555",
            "background-color": "#222222",
            "mina-button-background-color": "#444444",
            "sub-button-background-color": "#666666",
            "sub-button-background-color-hover": "#014375",
            "button-text-color": "#ffffff",
            "page-title-text-color": "#ffffff",
            "sub-title-background-color": "#555555",
            "table-title-text-color": "#dddddd",
            "edit-button-color": "#aaaaaa",
            "input-div-background-color": "#f5f5f5",
            "dash-dividingline-color": "#b9b9b9",

            "dash-outlet-background-color":  "#ffc146",
            "dash-logo-background-color": "#ffffff",
            "dash-userinfo-background-color":  "#ffffff",
            "dash-navbar-background-color": "#ffffff",
        
            "dash-page-background-color": "#ffffff",
            "dash-page-title-text-color": "#ffffff",
            "dash-each-sub-div-background-color": "#f5f5f5",
            "dash-each-sub-div-border-color": "#b9b9b9",
            "dash-each-sub-div-title-background-color": "#ffaa2a",
            "dash-each-sub-div-title-color": "#000000",
        
            "dash-primary-button-background-color": "#751917",
            "dash-primary-button-text-color": "#ffffff",
            "dash-primary-button-background-color-hover": "#8c1f1f",
            "dash-primary-button-text-color-hover": "#ffffff",
        
            "dash-secondary-button-background-color": "#ef4c26",
            "dash-secondary-button-text-color": "#ffffff",
            "dash-secondary-button-background-color-hover": "#ff6a3d",
            "dash-secondary-button-text-color-hover": "#ffffff",
        },
        AzureStyle: {
            "primary-color": "#0178d4",
            "secondary-color": "#ffffff",
            "background-color": "#ffffff",
            "mina-button-background-color": "#444444",
            "sub-button-background-color": "#016fc5",
            "sub-button-background-color-hover": "#014375",
            "button-text-color": "#ffffff",
            "page-title-text-color": "#ffffff",
            "sub-title-background-color": "#016fc5",
            "table-title-text-color": "#dddddd",
            "edit-button-color": "#aaaaaa",
            "input-div-background-color": "#fafafa",

            "dash-outlet-background-color":  "#0178d4",
            "dash-logo-background-color": "#ffffff",
            "dash-userinfo-background-color":  "#ffffff",
            "dash-navbar-background-color": "#ffffff",
            "dash-dividingline-color": "#b9b9b9",
        
            "dash-page-background-color": "#ffffff",
            "dash-page-title-text-color": "#000000",
            "dash-each-sub-div-background-color": "#fafafa",
            "dash-each-sub-div-border-color": "#9a9b9c",
            "dash-each-sub-div-title-background-color": "#016fc5",
            "dash-each-sub-div-title-color": "#ffffff",
        
            "dash-primary-button-background-color": "#016fc5",
            "dash-primary-button-text-color": "#ffffff",
            "dash-primary-button-background-color-hover": "#014375",
            "dash-primary-button-text-color-hover": "#ffffff",
        
            "dash-secondary-button-background-color": "#016fc5",
            "dash-secondary-button-text-color": "#ffffff",
            "dash-secondary-button-background-color-hover": "#014375",
            "dash-secondary-button-text-color-hover": "#ffffff",
        },
        // Add more themes if needed
    };


    const [currentTheme, setCurrentTheme] = useState<Theme>(themes.default);

    const applyTheme = (theme: Theme) => {
        Object.entries(theme).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, value);
        });
    };

    // Function to change theme and save it to cookies
    const changeTheme = (themeKey: string) => {
        const selectedTheme = themes[themeKey];
        setCurrentTheme(selectedTheme);
        applyTheme(selectedTheme);
        cookies.set("selectedTheme", themeKey, { path: "/", maxAge: 31536000 }); // Set cookie for 1 year
    };

    // On initial load, retrieve theme from cookie and apply it
    useEffect(() => {
        const savedThemeKey = cookies.get("selectedTheme");
        if (savedThemeKey && themes[savedThemeKey]) {
            setCurrentTheme(themes[savedThemeKey]);
            applyTheme(themes[savedThemeKey]);
        } else {
            applyTheme(themes.default); // Apply default theme if no cookie found
        }
    }, []);

    const content = (
        <>

        </>
    )

    return content
}

export default ChangeColor