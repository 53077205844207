import RaceRank from "../Components/RaceRank"

const RaceRankPage = () => {
    const content = (
        <div>
            <RaceRank />
        </div>
    )
    return content
}

export default RaceRankPage