import { Helmet, HelmetProvider } from "react-helmet-async" 
//Pages
import { Outlet } from "react-router-dom"
import DashNavBar from "../Components/DashNavbar"
import FootagePage from "../Components/Footage"
import { Cookies } from "react-cookie"
import { useContext, useEffect } from "react"
import { SystemContext } from "../Class/SystemPara"
import { FetchSystemData } from "../Interface/Const"

const cookies = new Cookies()

const DashLayout = () => {

    const systemContext = useContext(SystemContext);

    useEffect(() => {
        FetchSystemData(systemContext)
        CheckLoginStatus()
    }, []);

    const CheckLoginStatus = () => {
        if (systemContext.systemData.isLogin !== true) {
            window.location.replace('https://www.wisetechinterglobe.com.hk/');
          }
    }


    const content = (
        <body>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Wisetechinterglobe Dash</title>
                    </Helmet>
                    <DashNavBar />
                    <Outlet />
                    <FootagePage />                    
                </div>
            </HelmetProvider>
        </body>
    
    )
    return content
}
export default DashLayout