import { useEffect, useState } from "react";
import ProcessBar from '../../../Components/Common/ProgressBar'
import MessageBox from '../../../Components/Common/AlertMessageBox'
import css from "../../../css/C0217/RequestRepairPage.module.css"

import BaseInfoForm from '../../../Components/C0217/Shop/SendRepair/BaseInfoForm'
import SelectEquipment from "../../../Components/C0217/Shop/SendRepair/SelectEquipment";
import SelectEquipmentDetail from "../../../Components/C0217/Shop/SendRepair/SetEquipmentDetail";

import { IC0217SendRepairRequest } from "../../../Interface/interface";
import AlertMessage from "../../../Components/LoadingBar/AlertMessage";
import DeafultLoadingBar from "../../../Components/LoadingBar/DefaultLoadingBar";
import { useNavigate, Link } from "react-router-dom";




const RequestRepairPage = () => {

    const navigate = useNavigate();

    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [isloading, setLoading] = useState<boolean>(false);
    const [isProgressBarPercent, setProgrssBarPercent] = useState<number>(0);

    const [isFormStatus, setFormStatus] = useState<number>(0);

    const [isShowMessageBox, setShowMessageBox] = useState<boolean>(false);
    const [isMessageBoxType, setMessageBoxType] = useState<string>('Boolean');
    const [isMessageBoxTitiel, setMessageBoxTitiel] = useState<string>('系統提示');
    const [isMessageBoxMessage, setMessageBoxMessage] = useState<string>('Are you sure you want to delete this product?');
    const [isMessageBoxResult, setMessageBoxResult] = useState<boolean | null>(null);

    // const [isFormData, setFormData] = useState<IC0217SendRepairRequest>({
    //     Date: '2024-12-04',
    //     ShopID: 'CF019',
    //     RepairType: '',
    //     EquipmentID: '',
    //     EquipmentName: '',
    //     EquipmentIssue: '',
    //     EquipmentOtherIssue: '',
    //     Remark: ''
    // });

    const [isFormData, setFormData] = useState<IC0217SendRepairRequest>({
        Date: '2024-12-04',
        ShopID: 'CF019',
        RepairType: '爐具(電)',
        EquipmentID: '2021001521',
        EquipmentName: '矮仔爐 (電)',
        EquipmentIssue: '',
        EquipmentOtherIssue: '',
        Remark: ''
    });

    useEffect(() => {
        if (isMessageBoxResult === null) return;
        if (isMessageBoxResult) {
            setShowMessageBox(false);
            setMessageBoxResult(null);
            navigate("/C0217/RequestRepair");
        }

    }, [isMessageBoxResult])

    useEffect(() => {
        if (isFormData.Date.length > 0) {
            setProgrssBarPercent((prev) => prev + 16.7)
        }

        if (isFormData.ShopID.length > 0) {
            setProgrssBarPercent((prev) => prev + 16.7)
        }

        if (isFormData.RepairType.length > 0) {
            setProgrssBarPercent((prev) => prev + 16.7)
        }

        if (isFormData.EquipmentID.length > 0) {
            setProgrssBarPercent((prev) => prev + 16.7)
        }

        if (isFormData.EquipmentName.length > 0) {
            setProgrssBarPercent((prev) => prev + 16.7)
        }

        if (isFormData.EquipmentIssue.length > 0) {
            setProgrssBarPercent((prev) => prev + 16.7)
        }
    }, [isFormData])

    useEffect(() => {
        if (!isShowAlert) return;
        const timer = setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);

        return () => clearTimeout(timer);
    }, [isShowAlert]);


    const handleChangeStatus = (status: number) => {
        setFormStatus(status)
    }

    const handleChangeData = (target: string, value: string) => {
        setFormData((prev) => {
            return {
                ...prev,
                [target]: value
            }
        })
    }

    const handleDataValueByName = (name: string, value: string) => {
        console.log('name', name)
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleDateChange = (targetName: string, value: Date | null) => {
        if (value !== null) {
            const enteredYear = value.getFullYear();
            if (enteredYear < 1800 || enteredYear > 2100) {
                return;
            }

            const day = String(value.getDate()).padStart(2, '0'); // Pad the day to always be two digits
            const month = String(value.getMonth() + 1).padStart(2, '0'); // Pad the month (January is 0)
            const year = String(value.getFullYear());

            const formattedDate = `${year}-${month}-${day}`;

            setFormData(prevData => ({
                ...prevData,
                [targetName]: formattedDate // Converting the date to a string format like 'YYYY-MM-DD'
            }));
        }
    };

    const ConvtDisplayDate = (date: string) => {
        const dateArr = date.split('-');
        return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
    }

    const handleNavigateWithConfirmation = () => {
        setMessageBoxType('Boolean');
        setMessageBoxTitiel('系統提示');
        setMessageBoxMessage('您確定要返回主頁嗎？退出將導致您選擇的選項被放棄。');
        setShowMessageBox(true);
    }


    const content = (
        <>
            {isShowMessageBox && <MessageBox MessageBoxOnOff={setShowMessageBox} messageType={isMessageBoxType} messageTitle={isMessageBoxTitiel} messageContent={isMessageBoxMessage} returnBooleanResult={setMessageBoxResult} />}
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar isACID='C0217' />}



            <section className={css.PageSection}>

                {isFormStatus > 0 && (
                    <div className={css.PageHeader}>
                        <div className={css.AreadyFilterDiv}>
                            {
                                isFormData.Date.length > 0 && (
                                    <div className={css.EachFileData}>
                                        <h3>申請日期:</h3>
                                        <p>{ConvtDisplayDate(isFormData.Date)}</p>
                                    </div>
                                )
                            }

                            {isFormData.ShopID.length > 0 && (
                                <div className={css.EachFileData}>
                                    <h3>店鋪:</h3>
                                    <p>{isFormData.ShopID}</p>
                                </div>
                            )}

                            {isFormData.RepairType.length > 0 && (
                                <div className={css.EachFileData}>
                                    <h3>維修類別:</h3>
                                    <p>{isFormData.RepairType}</p>
                                </div>
                            )}

                            {isFormData.EquipmentID.length > 0 && (
                                <div className={css.EachFileData}>
                                    <h3>設備編號:</h3>
                                    <p>{isFormData.EquipmentID}</p>
                                </div>
                            )}

                            {isFormData.EquipmentName.length > 0 && (
                                <div className={css.EachFileData}>
                                    <h3>設備名稱:</h3>
                                    <p>{isFormData.EquipmentName}</p>
                                </div>
                            )}


                        </div>

                        <div className={css.ReturnToFunctionBtnDiv}>
                            <div className={css.returnFunctionBtn} onClick={handleNavigateWithConfirmation}>
                                <i className={`fi fi-br-angle-double-small-left  ${css.HeaderReturnBtn}`} />
                                <p>退出</p>
                            </div>
                        </div>
                    </div>
                )}





                <div className={css.ProcessBarDiv}>
                    <p>進度條</p>
                    <ProcessBar Percent={isProgressBarPercent} />
                </div>
                <div>
                    {isFormStatus === 0 && (
                        <BaseInfoForm
                            isFormData={isFormData}
                            handleChangeStatus={handleChangeStatus}
                            handleChangeData={handleChangeData}
                            handleDataValueByName={handleDataValueByName}
                            handleDateChange={handleDateChange} />
                    )}

                    {isFormStatus === 1 && (
                        <SelectEquipment
                            isFormData={isFormData}
                            handleChangeStatus={handleChangeStatus}
                            handleChangeData={handleChangeData}
                            handleDataValueByName={handleDataValueByName}
                            handleDateChange={handleDateChange} />
                    )}

                    {isFormStatus === 2 && (
                        <SelectEquipmentDetail
                            isFormData={isFormData}
                            handleChangeStatus={handleChangeStatus}
                            handleChangeData={handleChangeData}
                            handleDataValueByName={handleDataValueByName}
                            setShowAlert={setShowAlert}
                            setAlertType={setAlertType}
                            setAlertMessage={setAlertMessage}
                            setLoading={setLoading} />
                    )}

                </div>

            </section>
        </>
    )

    return content
}

export default RequestRepairPage;