//page
//import Login from "../Components/Login";
import MultiLogin from "../Components/MultiLogin";

function LoginPage() {
  return (
    <div>
      {/* <Login /> */}
      <MultiLogin />
    </div>
  );
}

export default LoginPage;
