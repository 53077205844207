import EditShop from "../Components/EditShop"

const EditShopPage = () => {
    const content = (
        <>
        <EditShop />
        </>
    )
    return content   
}
export default EditShopPage