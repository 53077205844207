import Course from '../../../Components/C0533/Course'

const CoursePage = () => {
    const content = (
        <>
        <Course />
        </>
    )
    return content
}
export default CoursePage
