import { createContext } from "react";
import { IC0211WebsitePermission, IFunction } from "../Interface/interface";

export interface SystemData {
    isLogin: boolean;
    FunctionArr: IFunction[];
}

export interface SystemContextType{
    systemData: SystemData;
    setLoginStatus: (isLogin: boolean) => void;
    setFunctionArr: (FunctionArr: IFunction[]) => void;
}

export const SystemContext = createContext<SystemContextType>({
    systemData: {
        isLogin: false,
        FunctionArr: [],
    },
    setFunctionArr: () => {},
    setLoginStatus: () => {},
})