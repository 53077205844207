import { createContext } from "react";
import { IC0211Product, IC0211ProductSKU, IC0211ProductSKURef, IC0211ProductType, IC0211UserList } from "../Interface/interface";

export interface IC0211PreLoadingData {
    isUserList: IC0211UserList[];
    isProductTypeList: IC0211ProductType[];
    isProductList: IC0211Product[];
    isProductSKUList: IC0211ProductSKU[];
    isProductSKURefList: IC0211ProductSKURef[];
}

export interface PreLoadingContextType{
    preLoadData: IC0211PreLoadingData;
    setLoadUserData: (isUserList: IC0211UserList[]) => void;
    setLoadProductTypeList: (isProductTypeList: IC0211ProductType[]) => void;
    setLoadProductList: (isProductList: IC0211Product[]) => void;
    setLoadProductSKUList: (isProductSKUList: IC0211ProductSKU[]) => void;
    setLoadProductSKURefList: (isProductSKURefList: IC0211ProductSKURef[]) => void;
}

export const PreLoadingContext = createContext<PreLoadingContextType>({
    preLoadData: {
        isUserList: [],
        isProductTypeList: [],
        isProductList: [],
        isProductSKUList: [],
        isProductSKURefList: []
    },
    setLoadUserData: () => {},
    setLoadProductTypeList: () => {},
    setLoadProductList: () => {},
    setLoadProductSKUList: () => {},
    setLoadProductSKURefList: () => {}
})