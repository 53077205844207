import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { IC0217DashBoardBarChart } from '../../../Interface/interface';

import css from '../../../css/C0217/Dashboard.module.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const C0217ShopBarChart: React.FC<IC0217DashBoardBarChart> = ({
    displayDataLabel,
    displayCData,
    displayLData
}) => {

    const data = {
        // labels: displayDataLabel,
        labels: displayDataLabel,
        datasets: [
            {
                label: '本月數據',
                data: displayCData,
                backgroundColor: 'rgba(122, 103, 185, 0.8)', // Bar color
                borderColor: 'rgba(122, 103, 185, 1)', // Border color
                borderWidth: 1,
            },
            {
                label: '上月數據',
                data: displayLData,
                backgroundColor: 'rgba(252, 141, 13, 0.8)', // Bar color
                borderColor: 'rgba(252, 141, 13, 1)', // Border color
                borderWidth: 1,
            },
        ],
    };

    let delayed: boolean;

    const options = {
        responsive: true,
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context: { type: string; mode: string; dataIndex: number; datasetIndex: number; }) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
            },
        },
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
            title: {
                display: false,
                text: 'Order Raw Data', // Chart title
            },
        },
        scales: {
            y: {
                beginAtZero: true, // Start y-axis at 0
            },
        },
        indexAxis: 'x' as const,
    };

    return (
        <>
            <div className={css.barChart} style={{ width: '600px', height: '400px' }}>
                <Bar data={data} options={options} width={600} height={400} />
            </div>
        </>
    );
};

export default C0217ShopBarChart;
