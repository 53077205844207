import { useEffect } from "react"
import { IC0217RawDataProps } from "../../../../Interface/interface"
import css from '../../../../css/C0217/GenerateReport.module.css'

const C0217OrderRawData: React.FC<IC0217RawDataProps> = ({
    rawdataA,
    rawdataB
}) => {

    const CalculateVendorDiscountsRate = (order_discounts: number, products_gfv: number) => {
        const discounts = order_discounts;
        const gfv = products_gfv;
        
        // 檢查解析結果是否為有效數字
        if (isNaN(discounts) || isNaN(gfv) || gfv === 0) {
            return "-"; // 回傳 "-" 代表無效結果或無法計算
        }
    
        return (discounts / gfv).toFixed(2); // 以百分比形式保留兩位小數
    };

    const content = (
        <>
            <div className={css.RawDataDiv}>

                <div className={css.RawDataADiv}>
                    <div className={css.TitleDiv}>
                        <p>RawData A</p>
                    </div>

                    <div className={css.RawDataTableDivA}>
                        <table className={css.RawDataTable}>
                            <thead>
                                <tr>
                                    <th>CODE</th>
                                    <th>SHOP</th>
                                    <th>Source.Name</th>
                                    <th>Invoice Number</th>
                                    <th>Order Coode</th>
                                    <th>Delivery Date</th>
                                    <th>Delivery Mode</th>
                                    <th>Payment Method</th>
                                    <th>Reversal</th>
                                    <th>Products Total Value / GFV</th>
                                    <th>Container Charges</th>
                                    <th>Vendor Delivery Fee Discounts</th>
                                    <th>Vendor Discounts</th>
                                    <th>Vendor Voucher</th>
                                    <th>Pandabox Fee</th>
                                    <th>Delivery Fee</th>
                                    <th>Restaurant revenue</th>
                                    <th>COD amount</th>
                                    <th>Commision base</th>
                                    <th>Pandabox Commission Base</th>
                                    <th>Customer Targeting Fees Percentage</th>
                                    <th>Adjustment</th>
                                    <th>Fixed commission</th>
                                    <th>Total commission</th>
                                    <th>Online Payment Charge</th>
                                    <th>Balance</th>
                                    <th>Commission Rate</th>
                                    <th>Online Payment Commission Rate</th>
                                    <th>DATE</th>
                                    <th>Vendor Discounts rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rawdataA.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.shop_id__shop_fodpanda_code1}</td>
                                        <td>{data.shop_id__shop_code}</td>
                                        <td>{data.data_from_file}</td>
                                        <td>{data.invoice_number}</td>
                                        <td>{data.order_code}</td>
                                        <td>{data.order_datetime_start}</td>
                                        <td>{data.delivery_mode}</td>
                                        <td>{data.payment_method}</td>
                                        <td>{data.reversal}</td>
                                        <td>{data.products_gfv}</td>
                                        <td>{data.container_charges}</td>
                                        <td>{data.delivery_fee_discounts}</td>
                                        <td>{data.order_discounts}</td>
                                        <td>{data.vendor_voucher}</td>
                                        <td>{data.pandabox_fee}</td>
                                        <td>{data.platform_delivery_fee}</td>
                                        <td>{data.restaurant_revenue}</td>
                                        <td>{data.already_received_amount}</td>
                                        <td>{data.commission_base}</td>
                                        <td>{data.pandabox_commission_base}</td>
                                        <td>{data.customer_targeting_fees_percentage}</td>
                                        <td>{data.foodpanda_price}</td>
                                        <td>{data.fixed_commission}</td>
                                        <td>{data.total_commission}</td>
                                        <td>{data.online_payment_charge}</td>
                                        <td>{data.order_balance}</td>
                                        <td>{data.commission_percentage}</td>
                                        <td>{data.online_payment_charge}</td>
                                        <td>{data.order_datetime_start}</td>
                                        <td>{CalculateVendorDiscountsRate(data.order_discounts, data.products_gfv)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={css.RawDataBDiv}>
                    <div className={css.TitleDiv}>
                        <p>RawData B</p>
                    </div>

                    <div className={css.RawDataTableDivB}>
                        <table className={css.RawDataTable}>
                            <thead>
                                <tr>
                                    <th>Source.Name</th>
                                    <th>帳單號碼</th>
                                    <th>帳單日期</th>
                                    <th>類別</th>
                                    <th>商品說明</th>
                                    <th>商戶號碼</th>
                                    <th>數量</th>
                                    <th>價格</th>
                                    <th>淨總值</th>
                                    <th>Currency Key</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rawdataB.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.data_from_file}</td>
                                        <td>{data.invoice_number}</td>
                                        <td>{data.order_datetime_start}</td>
                                        <td>{data.category}</td>
                                        <td>{data.description}</td>
                                        <td>{data.shop_id__shop_foodpanda_code1}</td>
                                        <td>{data.quantity}</td>
                                        <td>{data.unit_price}</td>
                                        <td>{data.order_balance}</td>
                                        <td>{data.currency_key}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
    return content
}

export default C0217OrderRawData