
import React, { useState } from "react";
import Webcam from "react-webcam";
import css from '../../../../css/C0217/RequestRepairPage.module.css'
import { IC0217SetRepairDetailProps } from '../../../../Interface/interface'

import tempImage from '../../../../global/C0217/Short_stove.png'
const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
};



const RepairType = [
    {
        "value": "不能開動",
        "label": "不能開動"
    },
    {
        "value": "不夠熱力",
        "label": "不夠熱力"
    },
    {
        "value": "有電到,無跳掣",
        "label": "有電到,無跳掣"
    },
    {
        "value": "有電到,唔識轉",
        "label": "有電到,唔識轉"
    },
    {
        "value": "人手輸入詳情",
        "label": "人手輸入詳情"
    }
]

const SelectEquipmentDetail: React.FC<IC0217SetRepairDetailProps> = ({ isFormData, handleChangeStatus, handleChangeData, handleDataValueByName, setShowAlert, setAlertType, setAlertMessage, setLoading}) => {

    const [isOpenWebcam, setOpenWebcam] = useState<boolean>(false);
    const webcamRef = React.useRef<Webcam>(null);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);

    const capture = React.useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc); // Save the captured image to state
        }
    }, [webcamRef]);

    const removePhoto = () => {
        setCapturedImage(null);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setCapturedImage(URL.createObjectURL(selectedFile));
        }
    };

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.SelectRepairOptionDetailDiv}>
                    <div className={css.BasicFormTitle}>
                        <p>維修細類</p>
                    </div>

                    <div className={css.BasicFormContent}>

                        <fieldset className={css.EquipmentOptionDiv}>
                            {RepairType.map((option) => {
                                return (
                                    <div key={option.value} className={css.eachEquipmentOption}>
                                        <input type="radio" id={option.value} name="drone" value={option.value} onChange={(e) => handleDataValueByName("EquipmentIssue", e.target.value)} />
                                        <label>{option.label}</label>
                                    </div>
                                )
                            })
                            }
                        </fieldset>

                        {isFormData.EquipmentIssue === "人手輸入詳情" && (
                            <div className={css.OtherOptionDiv}>
                                <label>設備問題</label>
                                <input type="text" placeholder='請列明設備現時問題' value={isFormData.EquipmentOtherIssue} onChange={(e) => handleDataValueByName("EquipmentOtherIssue", e.target.value)} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={css.UploadDetailDiv}>
                    <div className={css.RepairTextInputBox}>
                        <p>*請前線同事影相作記錄</p>
                        <p>**如有特殊情況, 請落備註</p>

                        <h3>請求備註</h3>
                        <textarea value={isFormData.Remark} onChange={(e) => handleChangeData("Remark", e.target.value)} rows={10}></textarea>
                    </div>

                    <div className={css.UploadFileBox}>
                        <div className={css.DispalyImageDiv}>


                            {capturedImage ? (
                                <img src={capturedImage} alt="Captured equipment" className={css.CapturedImage} />
                            ) : (
                                isOpenWebcam &&
                                <Webcam
                                    audio={false}
                                    height={720}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={1280}
                                    videoConstraints={videoConstraints}
                                />
                            )}

                            <div className={css.UpdateImageControlPanel}>
                                {/* {isOpenWebcam ? (
                                    <div className={css.UpdateImageBtn} onClick={() => setOpenWebcam(false)}>
                                        <label>關閉相機</label>
                                    </div>
                                ) : (
                                    <div className={css.UpdateImageBtn} onClick={() => setOpenWebcam(true)}>
                                        <label>開啟相機</label>
                                    </div>
                                )} */}
                                <div className={css.UpdateImageBtn}>
                                    <label htmlFor="UploadImage">上傳檔案/拍照</label>
                                    <input type="file" id="UploadImage" accept="image/*" onChange={(e) => handleFileChange(e)} />
                                </div>
                                {/* <div className={css.UpdateImageBtn} onClick={() => removePhoto()}>
                                    <label>清除照片</label>
                                </div>

                                <div className={css.UpdateImageBtn} onClick={capture}>
                                    <label>拍照</label>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={css.ButtonControlDiv}>

                    <div className={css.PreviousStepDiv} onClick={() => handleChangeStatus(1)}>
                        <i className={`fi fi-br-angle-double-small-left ${css.ReturnIcon}`} />
                        <p>上一步</p>
                    </div>


                    <div className={css.NextStepDiv} onClick={() => handleChangeStatus(1)}>
                        <i className={`fi fi-br-check ${css.NextStepIcon}`} />
                        <p>&nbsp;&nbsp;遞&nbsp;&nbsp;交</p>
                    </div>

                </div>


            </section>
        </>
    )

    return content
}

export default SelectEquipmentDetail