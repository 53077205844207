import ACFunctionList from '../Components/ACFunctionList'

const ACFunctionListPage = () => {
    const content = (
        <>
        <ACFunctionList />
        </>
    )
    return content
}
export default ACFunctionListPage