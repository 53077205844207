import GPSRecord from "../Components/GPSRecord"

const GPSRecordPage = () => {
    const content = (
        <>
        <GPSRecord />
        </>
    )
    return content
}

export default GPSRecordPage
