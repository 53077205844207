import css from '../../css/C0533/AboutHKrus.module.css'
import banner from '../../global/C533/Banner_About.jpg'

import Img01 from '../../global/C533/facility.jpg'
import Img02 from '../../global/C533/AboutLogo.png'

const AboutHKrus = () => {

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        event.currentTarget.classList.add(css.visible);
    };;

    const content = (
        <section className={css.MainSection}>
            <div className={css.BannerImg}>
                <img src={banner} alt="banner Image" />
            </div>
            <div className={css.AboutDiv}>
                <div className={css.AboutTextDiv}>
                    <p className={css.Title}>本社介紹</p>
                    <p className={css.AboutContent}>香港跳繩精英訓練社成立於2011年，並以花式跳繩推廣及精英運動員培訓為宗</p>
                    <p className={css.AboutContent}>旨。同時，本社定期開辦訓練班、推廣活動、證書課程等務求為本地跳繩界培育</p>
                    <p className={css.AboutContent}>出更多精英運動員代表本港參與國際性跳繩賽事。</p>
                    <br />
                    <br />
                    <p className={css.SubTitle}>本社成立至今已教育超過10000位以上學生 及 已培訓超過150位教練。</p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <p className={css.AboutContent2}>場館內的運動地板，具有玻璃纖維加強了穩定性，而底層為封閉式海綿底層，提供極佳的抗衝擊性及反彈性。場內設施包括巨形跳舞鏡、音響組合、體操墊、充氣體操墊、飲水機等等。</p>
                </div>
                <div className={css.AboutImgDiv}>
                    <img src={Img01} alt="banner Image" className={css.fade_in_img} onLoad={handleImageLoad}/>
                </div>
            </div>

            <div className={css.AboutDiv}>

                <div className={css.AboutImgDiv2}>
                    <img src={Img02} alt="banner Image" className={css.fade_in_img} onLoad={handleImageLoad}/>
                </div>

                <div className={css.AboutTextDiv}>
                    <p className={css.ServeTitle}>本社提供以下服務:</p>
                    <ul className={css.Listpoint}>
                        <li>工作坊</li>
                        <li>表演</li>
                        <li>跳繩課程</li>
                        <li>證書課程</li>
                        <li>技術改良課程</li>
                        <li>器材</li>
                    </ul>
                </div>

            </div>
        </section>
    )
    return content
}

export default AboutHKrus