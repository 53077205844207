import Competition from "../../../Components/C0533/Competition"

const UnionCupPage = () => {
    const content = (
        <>
        <Competition />
        </>
    )
    return content
}
export default UnionCupPage