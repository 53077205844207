import css from '../../css/C0533/Performances.module.css'

import image01 from '../../global/C533/Team_Performances.jpg'
import image02 from '../../global/C533/Team2_Performances.jpg'

import image03 from '../../global/C533/TeamImg.jpg'
import image04 from '../../global/C533/SmallBanner.jpg'


const Performances = () => {
    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                <p className={css.Title}>本社能為學校及各機構提供花式跳繩推廣表演，及專業花式跳繩工作坊等等。</p>
                <p className={css.SubTitle}>歡迎各機構聯絡合作。</p>
            </div>
            <div className={css.ImgBox2}>
                <img src={image01} alt='Image1' />
                <img src={image02} alt='Image2' />
            </div>

            <div className={css.SubTitleDiv}>
                <p className={css.SubTitle}>世界級表演隊伍</p>
            </div>

            <div className={css.ImgBoxAndVideos}>
                <img src={image03} alt='Image3' />
                <iframe
                    id="pl3"
                    className={css.videos}
                    frameBorder="0"
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    title="DDCHK6 - Double Dutch Department - Open - Champion"
                    width="500"
                    height="370"
                    src="https://www.youtube-nocookie.com/embed/2S_k5SO2u_Y?playsinline=1&rel=0&enablejsapi=1&origin=http://www.hkrsu.com&widgetid=1"
                ></iframe>
            </div>

            <div className={css.DescDiv}>
                <p className={css.DescMessage}>本社的跳繩表演隊之一：Double Dutch Department 曾奪得國際交互繩世界巡迴賽 －韓國站冠軍及香港站冠軍！ 當中隊員亦曾奪得「世界跳繩錦標賽」表演盃 －冠軍等殊榮！！</p>
            </div>

            <div className={css.SmallBanner}>
                <img src={image04} alt='Image4' />
            </div>

            <div className={css.SubTitleDiv}>
                <p className={css.SubTitle}>本社Aces 表演團體由旗下運動員組成。當中成員包括香港代表隊成員及世界級運動員。</p>
                <p className={css.SubTitle}>歡迎聯絡傾談各類型表演活動。</p>
            </div>

        </section>
    )
    return content
}

export default Performances