import Banner from '../Components/Banner';
import ImageSlider from '../Components/ImageSlider';
import PointList from '../Components/PointList';
import Purpose from '../Components/Purpose';
import Description from '../Components/Description';
import Contectus from '../Components/Contact';



function HomePage() {
  return (
    <div className="App">
      <Banner />
      <ImageSlider />
      <PointList />
      <Purpose />
      <Description />
      <Contectus />
    </div>
  );
}

export default HomePage;
