import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { IC0553WebsiteImage } from "../../Interface/interface";

import EachProduct from './EachProduct'

// CSS imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import css from '../../css/C0533/Store.module.css';

const Store = () => {
    const [data, setData] = useState<IC0553WebsiteImage[]>([]);

    useEffect(() => {
        goGetDataJson();
    }, []);

    const PrintProduct = (data: IC0553WebsiteImage[], productCodePrefix: string) => {
        const filteredData = data.filter(item => item.item_section_code.startsWith(productCodePrefix));
        return <EachProduct data={filteredData} />;
    }

    const goGetDataJson = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "Store",
                item_section_code: "Rope_",
            });
            if (res.status === 200) {
                setData(res.data);
                console.log(res.data)
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    return (
        <>
            {data.length > 0 ? (
                <section className={css.MainSection}>
                    <div className={css.TitleDiv}>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSf8e63XWd9cpaXIr6VWWc4OuQ-tr9j5LrTevZ5sMr0EwFgYRw/viewform">點擊：(訂繩表格)</a>
                    </div>
                    <hr />
                    <div className={css.ThreeInOne}>
                        {PrintProduct(data, "Rope_SoloRope")}
                        {PrintProduct(data, "Rope_SoloLongRope")}
                        {PrintProduct(data, "Rope_SpeedO")}
                        {PrintProduct(data, "Rope_DDcrossRope")}
                        {PrintProduct(data, "Rope_SpeedM")}
                        {PrintProduct(data, "Rope_SpeedMN")}
                        {PrintProduct(data, "Rope_LongRope")}
                        {PrintProduct(data, "Rope_Bag")}
                        {PrintProduct(data, "Rope_C0533WindBreaker")}
                        {PrintProduct(data, "Rope_Tshirt")}
                        {PrintProduct(data, "Rope_Pants_01")}
                    </div>
                </section>
            ): (
                <section className={css.MainSection}>
                <div className={css.TitleDiv}>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSf8e63XWd9cpaXIr6VWWc4OuQ-tr9j5LrTevZ5sMr0EwFgYRw/viewform">點擊：(訂繩表格)</a>
                </div>
                <hr />
                <div className={css.ThreeInOne}>

                </div>
            </section>
            )}
        </>

    );
};

export default Store;
