import { ChangeEvent, useEffect, useState } from 'react'
import { Calendar, Badge } from 'rsuite';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import css from '../css/NewRaceRank.module.css'
import RRcss from '../css/RaceRank.module.css'
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/Button/styles/index.css';


const NewRaceRank = () => {

    interface IRaceRank {
        race_id: string
        race_date: string
        race_no: number
        race_location: string
        race_day_night: string
        race_type: string
        race_name: string
        race_time: string
        race_is_all_day_road: string
        race_road_type: string
        race_length: number
        race_ground_type: string
        race_bounty: number
        race_rating: string
        race_class: string
        race_details: IRaceRankdetails[]
    }

    interface IRaceRankdetails {
        race_detail_id: number
        race_id: string
        horse_no: number
        horse_id: string
        last_six_ranking: string
        negative_pounds: number
        rider_id: string
        possible_overweight: string
        trainer_id: string
        race_position: number
        rating: number
        rating_changes: number
        priority_queue: string
        weight_ranking: number
        weight_ranking_changes: number
        best_time: string
        horse_age: number
        split_age_give_pounds: string
        seasonal_bounty: number
        accessories: string
        horse_owner: string
        sp_loss_pounds: number
        sp_split_age_give_pounds: string
        sp_accessories: string
        sp_strap_tongue: string
        sp_last_game_mouth_metal: string
        sp_this_game_mouth_metal: string
        sp_ratings: number
        sp_length: number
        sp_last_game_day_diff: number
        sp_trainer_changes: string
        sp_arrival_date: string
    }

    const { t, i18n } = useTranslation();
    const [date, SetDate] = useState<string | undefined>('');
    const [data, SetData] = useState<IRaceRank[]>([])
    // const [no , SetNo ] = useState<IRaceRankdetails []>([])
    const [no, SetNo] = useState<number>(-1);

    const handleDateInput = (e: ChangeEvent<HTMLInputElement>) => SetDate(e.target.value);

    useEffect(() => {
        GetFunctionList();
    }, [date])

    const getSelectNo = (value: number) => {
        SetNo(value - 1)
    };

    async function GetFunctionList() {
        SetNo(-1)
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/c0020/race/GetRaceData`, {
                race_date: date
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }

            })
                .then(function (res) {
                    console.log(res.data)
                    SetData(res.data)
                })
        } catch (error) {
            console.log(error)
        }
    }



    const content = (
        <>
            <section className={css.TopSection}>
                <input type="date" onChange={handleDateInput}></input>
            </section>

            <section className={css.MainSection}>
                <div className={css.SelectRaceNo}>
                    <table className={css.Basetable}>
                        {data.length >= 1 ? (
                            <tr>
                                <th>場次</th>
                                <th>編號</th>
                                <th>日期</th>
                                <th>時段(日/夜)</th>
                                <th>類型</th>
                                <th>名稱</th>
                                <th>時間</th>
                                <th>race_is_all_day_road</th>
                                <th>距離</th>
                                <th>地面類型</th>
                                <th>競賽賞金</th>
                                <th>比賽評級</th>
                                <th>比賽等級</th>
                                <th>詳情</th>
                            </tr>
                        ) : (null)}


                        {data.map((race) => (
                            <tr>
                                <th>{race.race_no}</th>
                                <th>{race.race_id}</th>
                                <th>{race.race_date}</th>
                                <th>{race.race_day_night}</th>
                                <th>{race.race_type}</th>
                                <th>{race.race_name}</th>
                                <th>{race.race_time}</th>
                                <th>{race.race_is_all_day_road}</th>
                                <th>{race.race_road_type}</th>
                                <th>{race.race_ground_type}</th>
                                <th>{race.race_bounty}</th>
                                <th>{race.race_rating}</th>
                                <th>{race.race_class}</th>
                                <th><button onClick={() => { getSelectNo(race.race_no) }} value={race.race_no} className={css.SelectNoBTN}>{race.race_no}</button></th>
                            </tr>
                        ))}
                    </table>
                    <div>
                    </div>
                </div>
            </section>
            <section className={css.detailSection} id="detailRace">
                {no >= 0 ? (<p className={RRcss.TitleMessage}>詳細資料</p>) : null}
                <table className={RRcss.RaceTable}>
                    {no >= 0 ? (
                        <tr className={RRcss.DetailTitle}>
                            <th className={RRcss.race_detail_id}>{t('race_detail_id')}</th>
                            <th className={RRcss.race_id}>{t('race_id')}</th>
                            <th className={RRcss.horse_no}>{t('horse_no')}</th>
                            <th className={RRcss.horse_id}>{t('horse_id')}</th>
                            <th className={RRcss.last_six_ranking}>{t('last_six_ranking')}</th>
                            <th className={RRcss.negative_pounds}>{t('negative_pounds')}</th>
                            <th className={RRcss.rider_id}>{t('rider_id')}</th>
                            <th className={RRcss.possible_overweight}>{t('possible_overweight')}</th>
                            <th className={RRcss.trainer_id}>{t('trainer_id')}</th>
                            <th className={RRcss.race_position}>{t('race_position')}</th>
                            <th className={RRcss.rating}>{t('rating')}</th>
                            <th className={RRcss.rating_changes}>{t('rating_changes')}</th>
                            <th className={RRcss.priority_queue}>{t('priority_queue')}</th>
                            <th className={RRcss.weight_ranking}>{t('weight_ranking')}</th>
                            <th className={RRcss.weight_ranking_changes}>{t('weight_ranking_changes')}</th>
                            <th className={RRcss.best_time}>{t('best_time')}</th>
                            <th className={RRcss.horse_age}>{t('horse_age')}</th>
                            <th className={RRcss.split_age_give_pounds}>{t('split_age_give_pounds')}</th>
                            <th className={RRcss.seasonal_bounty}>{t('seasonal_bounty')}</th>
                            <th className={RRcss.accessories}>{t('accessories')}</th>
                            <th className={RRcss.horse_owner}>{t('horse_owner')}</th>
                            <th className={RRcss.sp_loss_pounds}>{t('sp_loss_pounds')}</th>
                            <th className={RRcss.sp_split_age_give_pounds}>{t('sp_split_age_give_pounds')}</th>
                            <th className={RRcss.sp_accessories}>{t('sp_accessories')}</th>
                            <th className={RRcss.sp_strap_tongue}>{t('sp_strap_tongue')}</th>
                            <th className={RRcss.sp_last_game_mouth_metal}>{t('sp_last_game_mouth_metal')}</th>
                            <th className={RRcss.sp_this_game_mouth_metal}>{t('sp_this_game_mouth_metal')}</th>
                            <th className={RRcss.sp_ratings}>{t('sp_ratings')}</th>
                            <th className={RRcss.sp_length}>{t('sp_length')}</th>
                            <th className={RRcss.sp_last_game_day_diff}>{t('sp_last_game_day_diff')}</th>
                            <th className={RRcss.sp_trainer_changes}>{t('sp_trainer_changes')}</th>
                            <th className={RRcss.sp_arrival_date}>{t('sp_arrival_date')} </th>
                        </tr>
                    ) : null}
                    {no >= 0 && data[no].race_details.map((detail) => (
                        <tr className={RRcss.DetailTitle}>
                            <td className={RRcss.race_detail_id}>{no >= 0 && detail.race_detail_id}</td>
                            <td className={RRcss.race_id}>{no >= 0 && detail.race_id}</td>
                            <td className={RRcss.horse_no}>{no >= 0 && detail.horse_no}</td>
                            <td className={RRcss.horse_id}>{no >= 0 && detail.horse_id}</td>
                            <td className={RRcss.last_six_ranking}>{no >= 0 && detail.last_six_ranking}</td>
                            <th className={RRcss.negative_pounds}>{no >= 0 && detail.negative_pounds}</th>
                            <th className={RRcss.rider_id}>{no >= 0 && detail.rider_id}</th>
                            <th className={RRcss.possible_overweight}>{no >= 0 && detail.possible_overweight}</th>
                            <th className={RRcss.trainer_id}>{no >= 0 && detail.trainer_id}</th>
                            <th className={RRcss.race_position}>{no >= 0 && detail.race_position}</th>
                            <th className={RRcss.rating}>{no >= 0 && detail.rating}</th>
                            <th className={RRcss.rating_changes}>{no >= 0 && detail.rating_changes}</th>
                            <th className={RRcss.priority_queue}>{no >= 0 && detail.priority_queue}</th>
                            <th className={RRcss.weight_ranking}>{no >= 0 && detail.weight_ranking}</th>
                            <th className={RRcss.weight_ranking_changes}>{no >= 0 && detail.weight_ranking_changes}</th>
                            <th className={RRcss.best_time}>{no >= 0 && detail.best_time}</th>
                            <th className={RRcss.horse_age}>{no >= 0 && detail.horse_age}</th>
                            <th className={RRcss.split_age_give_pounds}>{no >= 0 && detail.split_age_give_pounds}</th>
                            <th className={RRcss.seasonal_bounty}>{no >= 0 && detail.seasonal_bounty}</th>
                            <th className={RRcss.accessories}>{no >= 0 && detail.accessories}</th>
                            <th className={RRcss.horse_owner}>{no >= 0 && detail.horse_owner}</th>
                            <th className={RRcss.sp_loss_pounds}>{no >= 0 && detail.sp_loss_pounds}</th>
                            <th className={RRcss.sp_split_age_give_pounds}>{no >= 0 && detail.sp_split_age_give_pounds}</th>
                            <th className={RRcss.sp_accessories}>{no >= 0 && detail.sp_accessories}</th>
                            <th className={RRcss.sp_strap_tongue}>{no >= 0 && detail.sp_strap_tongue}</th>
                            <th className={RRcss.sp_last_game_mouth_metal}>{no >= 0 && detail.sp_last_game_mouth_metal}</th>
                            <th className={RRcss.sp_this_game_mouth_metal}>{no >= 0 && detail.sp_this_game_mouth_metal}</th>
                            <th className={RRcss.sp_ratings}>{no >= 0 && detail.sp_ratings}</th>
                            <th className={RRcss.sp_length}>{no >= 0 && detail.sp_length}</th>
                            <th className={RRcss.sp_last_game_day_diff}>{no >= 0 && detail.sp_last_game_day_diff}</th>
                            <th className={RRcss.sp_trainer_changes}>{no >= 0 && detail.sp_trainer_changes}</th>
                            <th className={RRcss.sp_arrival_date}>{no >= 0 && detail.sp_arrival_date}</th>
                        </tr>
                    ))}
                </table>
            </section>
        </>
    )
    return content
}
export default NewRaceRank