import ExcelJS from 'exceljs';
import css from '../../../css/C0211/DeliveryNoteToExcel.module.css'

import ExcelSHYLogo from '../../../global/C0211/Logo.png'
import ExcelSlogan from '../../../global/C0211/slogan.png'
import ExcelQRCode from '../../../global/C0211/QRCode.png'

const images = [
    {
        name: 'SHYLogo',
        src: ExcelSHYLogo, // Imported image path
        position: { tl: { col: 1, row: 1 }, ext: { width: 180, height: 65 } }, // Top-left and size
    },
    {
        name: 'SHYSlogan',
        src: ExcelSlogan, // Imported image path
        position: { tl: { col: 14, row: 1 }, ext: { width: 120, height: 40 } },
    },
];

const SKUlist = [
    {
        index: 1,
        SKU: 'A1012Y',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 2,
        SKU: 'A1012E',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: "Test"
    },
    {
        index: 3,
        SKU: 'A1012K',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 4,
        SKU: 'A1015E',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 5,
        SKU: 'A1018B',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 6,
        SKU: 'B1013A',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 7,
        SKU: 'A1012Y',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 8,
        SKU: 'A1012Y',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 9,
        SKU: 'A1012Y',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    },
    {
        index: 10,
        SKU: 'A1012Y',
        Description: '原味腰果 (2kg/包)',
        Quantity: 1,
        Remark: null,
    }
]

const DeliveryNoteToExcel = () => {

    const exportDeliveryNoteToExcelZHTW = async() => {

        // Create a new ExcelJS Workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Delivery Note');

        worksheet.getColumn('A').width = 1.29; // Adjust width as needed
        worksheet.getColumn('B').width = 11.4; // Adjust width as needed
        worksheet.getColumn('C').width = 1.29; // Adjust width as needed
        worksheet.getColumn('D').width = 7.86; // Adjust width as needed
        worksheet.getColumn('E').width = 11.57; // Adjust width as needed
        worksheet.getColumn('F').width = 13.86; // Adjust width as needed
        worksheet.getColumn('G').width = 8.14; // Adjust width as needed
        worksheet.getColumn('H').width = 11.57; // Adjust width as needed
        worksheet.getColumn('I').width = 1.29; // Adjust width as needed
        worksheet.getColumn('J').width = 9.29; // Adjust width as needed
        worksheet.getColumn('K').width = 3.57; // Adjust width as needed
        worksheet.getColumn('L').width = 9.29; // Adjust width as needed
        worksheet.getColumn('M').width = 5.86; // Adjust width as needed
        worksheet.getColumn('N').width = 9.29; // Adjust width as needed
        worksheet.getColumn('O').width = 14.29; // Adjust width as needed

        worksheet.getRow(1).height = 10; // Adjust as needed
        worksheet.getRow(21).height = 30; // Adjust as needed

        // Add images to the workbook
        for (const img of images) {
            const response = await fetch(img.src);
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            await new Promise((resolve) => {
                reader.onloadend = () => resolve(null);
            });
            const base64Image = reader.result as string;

            const imageId = workbook.addImage({
                base64: base64Image,
                extension: 'jpeg', // Adjust based on your image format
            });

            worksheet.addImage(imageId, img.position);
        }

        // Add data to the worksheet
        const cellB3 = worksheet.getCell('B3');
        cellB3.value = 'The Walnut Shop Trading Limited';
        cellB3.font = { bold: true, size: 16 }; // Set text to bold
        cellB3.alignment = { vertical: 'middle', horizontal: 'center' }; // Center text

        const cellB4 = worksheet.getCell('B4');
        cellB4.value =
            '香港 九龍 油塘 高輝道17號 油塘工業城 A2座 6樓 16室\nFlat 616, Block A2, Yau Tong Industrial City\n17 Ko Fai Road, Yau Tong, Kowloon, Hong Kong\nTel: 27173421';
        cellB4.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; // Center text and enable wrapping
        cellB4.font = { size: 14 }; // Set font size

        const cellB12 = worksheet.getCell('B12');
        cellB12.value = 'Delivery Note';
        cellB12.font = { bold: true, underline: true, size: 14 };
        cellB12.alignment = { vertical: 'middle', horizontal: 'center' }; // Center text

        //帳單編號
        const cellB14 = worksheet.getCell('B14');
        cellB14.value = '帳單編號';
        cellB14.font = { bold: true, size: 14 };

        const cellC14 = worksheet.getCell('C14');
        cellC14.value = ':';
        cellC14.font = { size: 14 };

        const cellD14 = worksheet.getCell('D14');
        cellD14.value = 'INV_24110693';
        cellD14.font = { size: 14 };

        //付款條款
        const cellH14 = worksheet.getCell('H14');
        cellH14.value = '付款條款';
        cellH14.font = { bold: true, size: 14 };

        const cellI14 = worksheet.getCell('I14');
        cellI14.value = ':';
        cellI14.font = { size: 14 };

        const cellJ14 = worksheet.getCell('J14');
        cellJ14.value = '付款條款ABCD';
        cellJ14.font = { size: 14 };

        // 日期
        const cellB15 = worksheet.getCell('B15');
        cellB15.value = '日期';
        cellB15.font = { bold: true, size: 14 };

        const cellC15 = worksheet.getCell('C15');
        cellC15.value = ':';
        cellC15.font = { size: 14 };

        const cellD15 = worksheet.getCell('D15');
        cellD15.value = '2021-11-06';
        cellD15.font = { size: 14 };

        // 送貨方式
        const cellH15 = worksheet.getCell('H15');
        cellH15.value = '送貨方式';
        cellH15.font = { bold: true, size: 14 };

        const cellI15 = worksheet.getCell('I15');
        cellI15.value = ':';
        cellI15.font = { size: 14 };

        const cellJ15 = worksheet.getCell('J15');
        cellJ15.value = '送貨方式ABCD';
        cellJ15.font = { size: 14 };

        // 客戶
        const cellB16 = worksheet.getCell('B16');
        cellB16.value = '客戶';
        cellB16.font = { bold: true, size: 14 };

        const cellC16 = worksheet.getCell('C16');
        cellC16.value = ':';
        cellC16.font = { size: 14 };

        const cellD16 = worksheet.getCell('D16');
        cellD16.value = '客戶ABCD';
        cellD16.font = { size: 14 };

        // 收貨地址
        const cellH16 = worksheet.getCell('H16');
        cellH16.value = '收貨地址';
        cellH16.font = { bold: true, size: 14 };

        const cellI16 = worksheet.getCell('I16');
        cellI16.value = ':';
        cellI16.font = { size: 14 };

        const cellJ16 = worksheet.getCell('J16');
        cellJ16.value = '收貨地址ABCD';
        cellJ16.font = { size: 14 };

        // 出貨日期
        const cellH18 = worksheet.getCell('H17');
        cellH18.value = '出貨日期';
        cellH18.font = { bold: true, size: 14 };

        const cellI18 = worksheet.getCell('I17');
        cellI18.value = ':';
        cellI18.font = { size: 14 };

        const cellJ18 = worksheet.getCell('J17');
        cellJ18.value = '2021-11-06';
        cellJ18.font = { size: 14 };

        // 聯絡
        const cellB20 = worksheet.getCell('B19');
        cellB20.value = '聯絡';
        cellB20.font = { bold: true, size: 14 };

        const cellC20 = worksheet.getCell('C19');
        cellC20.value = ':';
        cellC20.font = { size: 14 };

        const cellD20 = worksheet.getCell('D19');
        cellD20.value = '聯絡ABCD';
        cellD20.font = { size: 14 };

        // SKU header 
        const cellB21 = worksheet.getCell('B21');
        cellB21.value = '貨號/ 貴客貨號';
        cellB21.font = { bold: true, size: 14, };
        cellB21.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
        }; // Add a border to the bottom

        const cellE21 = worksheet.getCell('E21');
        cellE21.value = '詳細資料/包裝';
        cellE21.font = { bold: true, size: 14 };
        cellE21.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
        }; // Add a border to the bottom

        const cellJ21 = worksheet.getCell('J21');
        cellJ21.value = '數量';
        cellJ21.font = { bold: true, size: 14 };
        cellJ21.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
        }; // Add a border to the bottom

        const cellO21 = worksheet.getCell('O21');
        cellO21.value = '備註';
        cellO21.font = { bold: true, size: 14 };
        cellO21.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
        }; // Add a border to the bottom

        // Add data to the worksheet
        SKUlist.forEach((item) => {
            const cellB = worksheet.getCell(`B${item.index + 21}`);
            cellB.value = item.index;
            cellB.font = { size: 14 };
            cellB.border = { bottom: { style: 'thin', color: { argb: 'FF000000' } } }; // Add a border to the bottom
            cellB.alignment = { vertical: 'middle', horizontal: 'left' }; // Center text

            const cellC = worksheet.getCell(`C${item.index + 21}`);
            cellC.value = "";
            cellC.font = { size: 14 };
            cellC.border = { bottom: { style: 'thin', color: { argb: 'FF000000' } } }; // Add a border to the bottom

            const cellD = worksheet.getCell(`D${item.index + 21}`);
            cellD.value = item.SKU;
            cellD.font = { size: 14 };
            cellD.border = { bottom: { style: 'thin', color: { argb: 'FF000000' } } }; // Add a border to the bottom

            const cellE = worksheet.getCell(`E${item.index + 21}`);
            cellE.value = item.Description;
            cellE.font = { size: 14 };
            cellE.border = { bottom: { style: 'thin', color: { argb: 'FF000000' } } }; // Add a border to the bottom

            const cellJ = worksheet.getCell(`J${item.index + 21}`);
            cellJ.value = item.Quantity + "PCS";
            cellJ.font = { size: 14 };
            cellJ.border = { bottom: { style: 'thin', color: { argb: 'FF000000' } } }; // Add a border to the bottom
            cellJ.alignment = { vertical: 'middle', horizontal: 'center' }; // Center text

            const cellO = worksheet.getCell(`O${item.index + 21}`);
            cellO.value = item.Remark;
            cellO.font = { size: 14 };
            cellO.border = { bottom: { style: 'thin', color: { argb: 'FF000000' } } }; // Add a border to the bottom

            worksheet.mergeCells(`E${item.index + 21}:I${item.index + 21}`);
            worksheet.mergeCells(`J${item.index + 21}:N${item.index + 21}`);
        });

        // Footer
        const cellFooterB = worksheet.getCell(`B${SKUlist.length + 30}`);
        cellFooterB.value = '確認人';
        cellFooterB.font = { size: 14 };

        const cellFooterI = worksheet.getCell(`I${SKUlist.length + 30}`);
        cellFooterI.value = '確認人';
        cellFooterI.font = { size: 14 };

        const cellSeller = worksheet.getCell(`B${SKUlist.length + 31}`);
        cellSeller.value = 'The Walnut Shop Trading Limited';
        cellSeller.font = { bold: true, size: 14 };

        const cellBuyer = worksheet.getCell(`I${SKUlist.length + 31}`);
        cellBuyer.value = '買家';
        cellBuyer.font = { bold: true, size: 14 };

        const cellSignatureB = worksheet.getCell(`B${SKUlist.length + 32}`);
        cellSignatureB.value = "";
        cellSignatureB.border = {
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
        }

        const cellSignatureI = worksheet.getCell(`I${SKUlist.length + 32}`);
        cellSignatureI.value = "";
        cellSignatureI.border = {
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
        }

        const cellSignatureLabelB = worksheet.getCell(`B${SKUlist.length + 36}`);
        cellSignatureLabelB.value = '簽署';
        cellSignatureLabelB.font = { size: 14, italic: true };

        const cellSignatureLabelI = worksheet.getCell(`I${SKUlist.length + 36}`);
        cellSignatureLabelI.value = '簽署';
        cellSignatureLabelI.font = { size: 14, italic: true };

         // Merge cells (if needed)
         worksheet.mergeCells('B2:O2');
         worksheet.mergeCells('B3:O3');
         worksheet.mergeCells('B4:O10');
         worksheet.mergeCells('B12:O12');
         worksheet.mergeCells('D14:E14');
         worksheet.mergeCells('J14:O14');
         worksheet.mergeCells('D15:E15');
         worksheet.mergeCells('J15:O15');
         worksheet.mergeCells('D16:F16');
         worksheet.mergeCells('J16:O16');
         worksheet.mergeCells('D17:G18');
         worksheet.mergeCells('J19:O19');
         worksheet.mergeCells('B21:D21');
         worksheet.mergeCells('E21:I21');
         worksheet.mergeCells('J21:N21');
         worksheet.mergeCells(`B${SKUlist.length + 31}: F${SKUlist.length + 31}`);
         worksheet.mergeCells(`I${SKUlist.length + 31}: O${SKUlist.length + 31}`);
         worksheet.mergeCells(`B${SKUlist.length + 32}: F${SKUlist.length + 35}`);
         worksheet.mergeCells(`I${SKUlist.length + 32}: O${SKUlist.length + 35}`);
         worksheet.mergeCells(`I${SKUlist.length + 36}: J${SKUlist.length + 36}`);
 
        // Add QR code at the end of the worksheet
        const SHYQRCode = ExcelQRCode;
        const response = await fetch(SHYQRCode);
        const imageBlob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        await new Promise((resolve) => {
            reader.onloadend = () => resolve(null);
        });
        const base64Image = reader.result as string;

        const imageId = workbook.addImage({
            base64: base64Image,
            extension: 'jpeg', // Use 'png' or 'jpeg' based on your image
        });

        worksheet.addImage(imageId, {
            tl: { col: 13, row: SKUlist.length + 38 }, // Top-left position
            ext: { width: 160, height: 80 }, // Width and height in pixels
        });

        // Generate the Excel file and trigger the download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Delivery_Note_With_Image.xlsx';
        link.click();
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <button className={css.Test} onClick={() => exportDeliveryNoteToExcelZHTW()}>Export DN Record To Excel File</button>
            </section>
        </>
    )

    return content
}

export default DeliveryNoteToExcel;