import AWADRSInternational from '../../../Components/C0533/AWADRSInternational'

const AWADRSInternationPage = () => {

    const content = (
        <>
            <AWADRSInternational />
        </>
    )
    return content
}

export default AWADRSInternationPage