import Pcss from '../css/Purpose.module.css'
import { useTranslation } from 'react-i18next';
//import 'bootstrap/dist/css/bootstrap.css'


const Purpose = () => {

    const { t, i18n } = useTranslation();

    const content = (
        <section className={Pcss.MainSection}>
            <div className={Pcss.PurposeDiv}>

                <div className={Pcss.PurposeGroup}>
                    <p className={Pcss.PurposeTitle}>我們的宗旨</p>
                    <p className={Pcss.SubPurposePoint}>{t('PurposeMainMessage')}</p>
                </div>

                <div className={Pcss.PurposeGroup}>
                    <p className={Pcss.PurposeTitle}>怎樣做?</p>
                    <ul className={Pcss.SubPurposePoint}>
                        <li>{t('PurposeSubMessage1')}</li>
                        <li>{t('PurposeSubMessage2')}</li>
                        <li>{t('PurposeSubMessage3')}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
    return content
};

export default Purpose;