import ImageSlider from '../Components/ImageSlider';
import Contact from '../Components/Contact';



function ContactPage() {
  return (
    <div className="App">
      <Contact />
      <ImageSlider />
    </div>
  );
}

export default ContactPage;