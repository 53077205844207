import { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import DownloadReportFilter from '../../../Components/C0217/Accounting/DownloadReportFilter'
import BrandAndShopFilter from '../../../Components/C0217/Accounting/BrandAndShopFilter'
import GenerateReport from '../../../Components/C0217/Accounting/GenerateReport'
import KeywordFilter from '../../../Components/C0217/Accounting/KeywordFilter'
import { IC0217DownloadReportFilter } from '../../../Interface/interface'

import cafe100 from '../../../global/C0217/cafe100.jpg'
import cha_thai from '../../../global/C0217/cha_thai.jpg'
import chicken_factory from '../../../global/C0217/chicken_factory.jpg'
import coco_fish from '../../../global/C0217/coco_fish.jpg'
import itamomo from '../../../global/C0217/itamama.jpg'
import itamama from '../../../global/C0217/itamama2.jpg'
import lime_fish from '../../../global/C0217/lime_fish.jpg'
import mama_bakery from '../../../global/C0217/mama_bakery.jpg'
import mc_dau from '../../../global/C0217/mc_dau.jpg'
import milk_cafe from '../../../global/C0217/milk_cafe.jpg'
import mizi from '../../../global/C0217/mizi.jpg'
import momo_classic from '../../../global/C0217/momo_classic.jpg'
import momoku from '../../../global/C0217/momoku.jpg'
import rud from '../../../global/C0217/rud.jpg'
import smile_bread from '../../../global/C0217/smile_bread.jpg'
import tao_yuen from '../../../global/C0217/tao_yuen.jpg'
import uuro from '../../../global/C0217/uuro.jpg'
import otherOption from '../../../global/C0217/Other.jpg'
import AlertMessage from '../../../Components/LoadingBar/AlertMessage'
import DeafultLoadingBar from '../../../Components/LoadingBar/DefaultLoadingBar'
import axios from 'axios';

const cookies = new Cookies();
const maxKeywords = 10;


const BrandList = [
    { BrandName: "Café 100%", BrandImage: cafe100 },
    { BrandName: "Cha Thai", BrandImage: cha_thai },
    { BrandName: "Chicken Factory", BrandImage: chicken_factory },
    { BrandName: "Coco Fish", BrandImage: coco_fish },
    { BrandName: "Itamomo", BrandImage: itamomo },
    { BrandName: "Itamama", BrandImage: itamama },
    { BrandName: "Lime Fish", BrandImage: lime_fish },
    { BrandName: "Mama Bakery", BrandImage: mama_bakery },
    { BrandName: "MC Dau", BrandImage: mc_dau },
    { BrandName: "Milk Café", BrandImage: milk_cafe },
    { BrandName: "Mizi", BrandImage: mizi },
    { BrandName: "Momo Classic", BrandImage: momo_classic },
    { BrandName: "Momoku", BrandImage: momoku },
    { BrandName: "RUD", BrandImage: rud },
    { BrandName: "Smile Bread", BrandImage: smile_bread },
    { BrandName: "Tao Yuen", BrandImage: tao_yuen },
    { BrandName: "UURO", BrandImage: uuro },
    { BrandName: "Other", BrandImage: otherOption },
];

const C0217DownloadReportPage = () => {

    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [isloading, setLoading] = useState<boolean>(false);

    const [isStage, setStage] = useState<number>(0);

    const [hasModifiedShops, setHasModifiedShops] = useState<boolean>(false);
    const [selectedShops, setSelectedShops] = useState<Set<number>>(new Set());
    const [isFilterData, setFilterData] = useState<IC0217DownloadReportFilter>({
        StartDate: '',
        EndDate: '',
        DateFilterBy: 1,
        PlatformType: ['foodpanda', 'deliveroo', 'keeta'],
        ReportType: 'Raw Data Summary',
        RawDataReportType: null,
        Brand: [],
        ExtensionKeyword: true,
        FileNameKeyword: '',
        OrderCodeKeyword: '',
        OrderCodeSWCheck: 0,
    })
    const [keywords, setKeywords] = useState<string[]>([]);

    useEffect(() => {
        if (!isShowAlert) return;
        const timer = setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);

        return () => clearTimeout(timer);
    }, [isShowAlert]);

    useEffect(() => {
        const today = new Date();

        const startDate = new Date(today);
        startDate.setMonth(startDate.getMonth() - 1);
        
        const endDate = new Date(today.getFullYear(), today.getMonth() , 0);

        const formattedStartDate = `${String(startDate.getDate()).padStart(2, '0')}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${startDate.getFullYear()}`;
        const formattedEndDate = `${String(startDate.getDate() - 1).padStart(2, '0')}-${String(endDate.getMonth() + 2).padStart(2, '0')}-${endDate.getFullYear()}`;
        
        setFilterData(prevData => ({
            ...prevData,
            StartDate: formattedStartDate,
            EndDate: formattedEndDate
        }));
    }, []);

    // const addKeywordInput = () => {

    //     if (keywords.length >= maxKeywords) return;
    //     setKeywords((prevKeywords) => [...prevKeywords, ""]);
    // };

    const addKeywordInput = () => {
        // Filter out any empty keywords from the array
        const nonEmptyKeywords = keywords.filter(keyword => keyword.trim() !== "");

        if (keywords.length >= maxKeywords) {
            setShowAlert && setShowAlert(true);
            setAlertType && setAlertType("Warning");
            setAlertMessage && setAlertMessage("Maximum number of keywords reached");
            setKeywords([...nonEmptyKeywords]);
            return;
        }

        // Update the keywords state to remove empty values, then add an empty string for a new input
        setKeywords([...nonEmptyKeywords, ""]);
    };

    const RemoveAllKeywords = () => {
        setKeywords([]);
    };

    const handleKeywordChange = (index: number, value: string) => {
        setKeywords((prevKeywords) => {
            const newKeywords = [...prevKeywords];
            newKeywords[index] = value;
            return newKeywords;
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilterData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: "StartDate" | "EndDate") => {
        const inputDate = e.target.value;
        const [year, month, day] = inputDate.split("-");
        const formattedDate = `${day}-${month}-${year}`; // Format as dd-mm-yyyy

        setFilterData((prevData) => ({
            ...prevData,
            [fieldName]: formattedDate,
        }));
    };

    // Function to handle brand selection
    const handleBrandSelection = (brandName: string) => {
        setFilterData((prevData) => {
            const currentBrands = prevData.Brand || [];
            const isBrandSelected = currentBrands.includes(brandName);

            // Toggle brand selection
            const updatedBrands = isBrandSelected
                ? currentBrands.filter((name) => name !== brandName)
                : [...currentBrands, brandName];

            return { ...prevData, Brand: updatedBrands };
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: 'PlatformType' | 'RawDataReportType' | 'ExtensionKeyword' | 'OrderCodeSWCheck') => {
        const { value, checked } = e.target;

        setFilterData((prevData) => {
            if (fieldName === 'ExtensionKeyword') {
                // Toggle ExtensionKeyword value directly
                return { ...prevData, ExtensionKeyword: checked };
            }

            if (fieldName === 'OrderCodeSWCheck') {
                return { ...prevData, OrderCodeSWCheck: checked ? 1 : 0 };
            }

            // Handle PlatformType and RawDataReportType as arrays
            const updatedValues = [...(prevData[fieldName] || [])];

            if (checked) {
                // Add item if it is checked
                if (!updatedValues.includes(value)) {
                    updatedValues.push(value);
                }
            } else {
                // Remove item if it is unchecked
                updatedValues.splice(updatedValues.indexOf(value), 1);
            }

            return {
                ...prevData,
                [fieldName]: updatedValues,
            };
        });
    };

    const handleShopListCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, shopId: number) => {
        const { checked } = e.target;
        setSelectedShops(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (checked) {
                newSelected.add(shopId);
            } else {
                newSelected.delete(shopId);
            }
            return newSelected;
        });

        setHasModifiedShops(true)
    };

    const handlePackageLoad = (packageKeywords: string[]) => {
        setKeywords([...packageKeywords]);
    };


    const content = (
        <>

            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar isACID='C0217'/>}

            {isStage === 0 &&
                <DownloadReportFilter
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                    setLoading={setLoading}
                    setStage={setStage}
                    isFilterData={isFilterData}
                    handleInputChange={handleInputChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handleDateChange={handleDateChange}
                />}

            {isStage === 1 &&
                <BrandAndShopFilter
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                    setLoading={setLoading}
                    setStage={setStage}
                    isFilterData={isFilterData}
                    handleInputChange={handleInputChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handleBrandSelection={handleBrandSelection}
                    selectedShops={selectedShops}
                    setSelectedShops={setSelectedShops}
                    handleShopListCheckboxChange={handleShopListCheckboxChange}
                    keywords={keywords}
                    addKeywordInput={addKeywordInput}
                    handleKeywordChange={handleKeywordChange}
                    handlePackageLoad={handlePackageLoad}
                    hasModifiedShops={hasModifiedShops}
                    setHasModifiedShops={setHasModifiedShops}
                />
            }

            {isStage === 2 &&
                <KeywordFilter
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                    setLoading={setLoading}
                    setStage={setStage}
                    isFilterData={isFilterData}
                    selectedShops={selectedShops}
                    keywords={keywords}
                    addKeywordInput={addKeywordInput}
                    RemoveAllKeywords={RemoveAllKeywords}
                    handleKeywordChange={handleKeywordChange}
                    handlePackageLoad={handlePackageLoad}
                />
            }

            {isStage === 90 &&
                <GenerateReport
                    setShowAlert={setShowAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                    setLoading={setLoading}
                    setStage={setStage}
                    isFilterData={isFilterData}
                    selectedShops={selectedShops}
                    keywords={keywords}
                />}
        </>
    )
    return content
}

export default C0217DownloadReportPage