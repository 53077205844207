import css from '../../css/C0533/HomePageImageSilde.module.css';

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${css.nextArrow}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${css.prevArrow}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

export { NextArrow, PrevArrow };
