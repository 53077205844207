import { Link } from 'react-router-dom';
import css from '../../../css/C0217/RepairEquipmentMenu.module.css'
import { IC0217Function, IC0217RepairFunction } from '../../../Interface/interface';
import { useState } from 'react';

import RequirRepairImage from '../../../global/C0217/RequirRepairImage.png'
import RepairRecordImage from '../../../global/C0217/RepairRecordImage.png'

const RepairEquipmentFunctionList = () => {

    const [functionList, setFunctionList] = useState<IC0217RepairFunction[]>([
        {
            function_typeID: 2901,
            function_id: 2001,
            function_name: "新增店鋪維修求",
            function_image: RequirRepairImage,
            function_button_text: "創立請求",
            desc: "選擇常見維修項目，例如：電力問題、燈具更換、水管漏水、設備故障等。",
            permission_id: 2001,
            function_path: "/C0217/RequestRepair/SendRequest",
            function_status: "開發中",
        }, {
            function_typeID: 2901,
            function_id: 2002,
            function_name: "查詢現有店鋪維修請求",
            function_image: RepairRecordImage,
            function_button_text: "查看記錄",
            desc: "選擇常見維修項目，例如：電力問題、燈具更換、水管漏水、設備故障等。",
            permission_id: 2002,
            function_path: "/C0217/RequestRepair",
            function_status: "開發中",
        },
    ])

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.TopDiv}>
                    <Link to="/C0217/FunctionList">
                        <div className={css.ReturnToMenuBtn}>
                            <i className={`fi fi-br-angle-double-small-left ${css.ReturnMenuIcon}`} />
                            返回目錄
                        </div>
                    </Link>
                </div>

                <div className={css.FunctioListDiv}>
                    <div className={css.FunctionListTitle}>
                        <p>店鋪維修請求</p>
                    </div>

                    <div className={css.MainFunctionDisplayDiv}>
                        {
                            functionList.map((item, index) => {
                                return (
                                    <div className={css.eachFunctionDiv} key={index}>
                                        <div className={css.FunctionTitle}>
                                            <p>{item.function_name}</p>
                                        </div>
                                        <div className={css.FunctionMainContent}>
                                            <div className={css.FunctionImage}>
                                                <img src={item.function_image} alt={item.function_name} />
                                            </div>
                                            <div className={css.FunctionDesc}>
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>
                                        <Link to={item.function_path}>
                                            <div className={css.FunctionButtonDiv}>
                                                <p className={css.FunctionButton}>{item.function_button_text}</p>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>



            </section>
        </>
    )

    return content
}

export default RepairEquipmentFunctionList;