import { Link } from 'react-router-dom';
import Fcss from '../css/Footage.module.css'


const Footage = () => {
    return (
        <section className={Fcss.MainSection}>
            <div className={Fcss.footagMain}>
                <div className={Fcss.DescriptionMessage}>
                    <div className={Fcss.footagContent}>
                        <p className={Fcss.footagTitle}>We're Hiring!</p>
                        <p className={Fcss.footagMessage}>Please send to</p>
                        <p className={Fcss.footagMessage}>info@wisetechinterglobe.com.hk</p>
                    </div>

                    <div className={Fcss.footagContent}>
                        <p className={Fcss.footagTitle}>聯繫我們</p>
                        <p className={Fcss.footagMessage}>我們期待收到你的來信！</p>
                        <p className={Fcss.footagMessage}>info@wisetechinterglobe.com.hk</p>
                    </div>

                </div>
                <hr />
                <div className={Fcss.Copyright}>
                    <div className={Fcss.InnerCopyright}>
                        <p><a href={`${process.env.REACT_APP_WEBSITE_URL}`}>Wisetech Interglobe Limited 滙文智庫有限公司 ©2024</a></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footage;
