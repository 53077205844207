import { CreateOutline } from 'react-ionicons'
import css from '../css/EditShop.module.css'
import tgcss from '../css/ToggleButton.module.css'

const EditShop = () => {
    const content = (
        <>
            <section className={css.TopSection}>
                <div className={css.Titile}>
                    <p>Editing Shop</p>
                </div>
            </section>

            <section className={css.MainSection}>
                <div className={css.BaseInfoDiv}>
                    <div className={css.EachFormDiv}>
                        <p>ID</p>
                        <input type="text" className={css.LoginIDText} disabled></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Shop Name</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Brief Name</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Shop Group</p>
                        <select className={css.LoginIDText}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Shop Region</p>
                        <select className={css.LoginIDText}>
                            <option value="九龍東">九龍東</option>
                            <option value="九龍西">九龍西</option>
                            <option value="新界東">新界東</option>
                            <option value="九龍西">九龍西</option>
                            <option value="離島">離島</option>
                            <option value="香港島B">香港島</option>
                        </select>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Shop Type</p>
                        <select className={css.LoginIDText}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>GPS LatitudeLab</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>GPS Longitude</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Status</p>
                        <div className={css.RadioDiv}>
                            <input type="radio" className={css.StatusRadio} name="drone"></input>
                            <label>Yes</label>
                            <input type="radio" className={css.StatusRadio} name="drone"></input>
                            <label>No</label>
                        </div>
                    </div>
                </div>
            </section>

            <section className={css.TopSection}>
                <div className={css.Titile}>
                    <p>Shop Time Table</p>
                    <table className={css.UserTable} id="UserListTable">
                        <tr>
                            <th>ID</th>
                            <th>Shop Code</th>
                            <th>Shop Name</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Update Reocrd</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>SHY-001</td>
                            <td>香港站</td>
                            <td>13:00:00-22:00:00</td>

                            <td><input defaultChecked type="checkbox" id="Status" className={tgcss.toggle} /><label htmlFor="Status"></label></td>

                            <td className={css.EditUserBtn}><CreateOutline
                                color={'#032654'}
                                height="30px"
                                width="30px"
                            /></td>
                        </tr>

                    </table>
                </div>
            </section>
        </>
    )
    return content
}
export default EditShop