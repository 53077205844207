import css from '../../css/Auth/ACIDSelect.module.css'

import ACIDSelectCmp from '../../Components/AuthPage/ACIDSelect'

const SelectACIDPage = () => {
    const content = (
        <>
            <ACIDSelectCmp />
        </>
    )

    return content;
}

export default SelectACIDPage;