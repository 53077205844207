import Store from "../../Components/C0533/Store"

const SalesPage = () => {
    const content = (
        <>
        <Store />
        </>
    )
    return content
}
export default SalesPage