import { useState } from 'react';
import css from '../../../../css/C0217/RequestRepairPage.module.css'
import { IC0217BaseInfoFormProps } from '../../../../Interface/interface';

import DatePicker, { DatePickerProps } from 'react-date-picker';
import { Link } from 'react-router-dom';

const ShopData = [
    {
        "ShopID": 1,
        "ShopCode": "CF019",
        "ShopBrand": "Chicken Factory",
        "ShopName": "迎海",
        "ShopAddress": "台北市信義區信義路五段7號",
        "ShopPhone": "0000 2423",
    }, {
        "ShopID": 2,
        "ShopCode": "CF020",
        "ShopBrand": "Chicken Factory",
        "ShopName": "南港",
        "ShopAddress": "台北市南港區南港路一段7號",
        "ShopPhone": "4512 2423",
    }, {
        "ShopID": 3,
        "ShopCode": "CF021",
        "ShopBrand": "Chicken Factory",
        "ShopName": "中和",
        "ShopAddress": "新北市中和區中和路7號",
        "ShopPhone": "1234 2423",
    }, {
        "ShopID": 4,
        "ShopCode": "CF022",
        "ShopBrand": "Chicken Factory",
        "ShopName": "新莊",
        "ShopAddress": "新北市新莊區新莊路7號",
        "ShopPhone": "5678 2423",
    }, {
        "ShopID": 5,
        "ShopCode": "CF023",
        "ShopBrand": "Chicken Factory",
        "ShopName": "板橋",
        "ShopAddress": "新北市板橋區板橋路7號",
        "ShopPhone": "9876 2423",
    }, {
        "ShopID": 6,
        "ShopCode": "CF024",
        "ShopBrand": "Chicken Factory",
        "ShopName": "三重",
        "ShopAddress": "新北市三重區三重路7號",
        "ShopPhone": "5432 2423",
    }, {
        "ShopID": 7,
        "ShopCode": "CF025",
        "ShopBrand": "Chicken Factory",
        "ShopName": "新店",
        "ShopAddress": "新北市新店區新店路7號",
        "ShopPhone": "8765 2423",
    }, {
        "ShopID": 8,
        "ShopCode": "CF026",
        "ShopBrand": "Chicken Factory",
        "ShopName": "永和",
        "ShopAddress": "新北市永和區永和路7號",
        "ShopPhone": "4321 2423",
    } , {
        "ShopID": 9,
        "ShopCode": "CF027",
        "ShopBrand": "Chicken Factory",
        "ShopName": "土城",
        "ShopAddress": "新北市土城區土城路7號",
        "ShopPhone": "1357 2423",
    }, {
        "ShopID": 10,
        "ShopCode": "CF028",
        "ShopBrand": "Chicken Factory",
        "ShopName": "汐止",
        "ShopAddress": "新北市汐止區汐止路7號",
        "ShopPhone": "2468 2423",
    }
]

const BaseInfoForm: React.FC<IC0217BaseInfoFormProps> = ({ isFormData, handleChangeStatus, handleChangeData, handleDataValueByName, handleDateChange }) => {

    const [isFocusSelectShop, setFocusSelectShop] = useState<boolean>(false);

    // Seach User
    function SearchUserFc() {
        const input = document.getElementById("SearchTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("UserListTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdCodeName = tr[i].getElementsByTagName("td")[0];
            const tdTel = tr[i].getElementsByTagName("td")[1];
            const tdAddress = tr[i].getElementsByTagName("td")[2];
            if (tdCodeName || tdTel || tdAddress) {
                const txtCodeNameValue = tdCodeName.textContent || tdCodeName.innerText;
                const txtTelValue = tdTel.textContent || tdTel.innerText;
                const txtAddressValue = tdAddress.textContent || tdAddress.innerText;
                // Check if either Staff ID or Username matches the filter
                if (txtCodeNameValue.toUpperCase().indexOf(filter) > -1 ||
                    txtTelValue.toUpperCase().indexOf(filter) > -1 ||
                    txtAddressValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.BasicInfoDiv}>
                    <div className={css.BasicFormTitle}>
                        <p>基本資料</p>
                    </div>

                    <div className={css.BasicFormContent}>

                        <div className={css.EachInputField}>
                            <div className={css.FormLabel}>
                                <p>開始日期</p>
                            </div>
                            <div className={css.FormInput}>
                                <DatePicker
                                    value={isFormData?.Date ? new Date(isFormData.Date) : null}
                                    onChange={(value) => handleDateChange("Date", value as Date | null)}
                                    format="dd-MM-yyyy"
                                    className={css.DatePicker}
                                    clearIcon={null}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder='dd'
                                    autoFocus={false}
                                />
                            </div>
                        </div>


                        <div className={css.EachInputField}>
                            <div className={css.FormLabel}>
                                <p>店家</p>
                            </div>
                            <div className={css.FormInput}>
                                <input type="text" id="SearchTxt" name="ShopID" value={isFormData.ShopID} onKeyUp={SearchUserFc} onChange={(e) => handleChangeData(e.target.name, e.target.value)} onFocus={() => setFocusSelectShop(true)} />
                            </div>
                            {isFocusSelectShop && (
                                <>
                                    <div className={css.SeachShopTableDiv}>
                                        <table className={css.SeachShopTable} id="UserListTable">
                                            <thead>
                                                <tr>
                                                    <th>店鋪</th>
                                                    <th>電話</th>
                                                    <th>地址</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ShopData.map((shop) => {
                                                    return (
                                                        <tr key={shop.ShopID} onClick={() => {
                                                            handleDataValueByName("ShopID", shop.ShopCode + "-" + shop.ShopName)
                                                            setFocusSelectShop(false)
                                                        }}>
                                                            <td style={{ width: `160px` }}>{shop.ShopCode}-{shop.ShopName}</td>
                                                            <td style={{ width: `120px` }}>{shop.ShopPhone}</td>
                                                            <td style={{ width: `210px` }}>{shop.ShopAddress}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className={css.ButtonControlDiv}>
                    <Link to="/C0217/RequestRepair">
                        <div className={css.ReturnToFunctionDiv}>
                            <i className={`fi fi-br-angle-double-small-left ${css.ReturnIcon}`} />
                            <p>返回</p>
                        </div>
                    </Link>

                    <div className={css.NextStepDiv} onClick={() => handleChangeStatus(1)}>
                        <i className={`fi fi-br-check ${css.NextStepIcon}`} />
                        <p>下一步</p>
                    </div>

                </div>


            </section>
        </>
    )

    return content
}

export default BaseInfoForm;