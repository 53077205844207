import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import css from '../../css/C0533/MediaNavBar.module.css';
import { IC0553NavBar } from '../../Interface/interface';

type SubMenuKeys = 'aboutUs' | 'classes' | 'competitions' | 'awards';

const MediaNavBar = ({ toggleNavBar }: IC0553NavBar) => {
    const [openSubMenus, setOpenSubMenus] = useState<Record<SubMenuKeys, boolean>>({
        aboutUs: false,
        classes: false,
        competitions: false,
        awards: false,
    });

    const handleToggle = (menu: SubMenuKeys) => {
        setOpenSubMenus(prevState => ({
            ...prevState,
            [menu]: !prevState[menu]
        }));
    };

    return (
        <section className={css.MainSection}>
            <div className={css.NavbarMenu} id="navMenu">
                <div className={css.NavbarMenuSize}>

                    <div className={css.dropdown}>
                        <Link to={"/C0533"} onClick={toggleNavBar}>Home</Link>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn} onClick={() => handleToggle('aboutUs')}>About Us
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={`${css.dropdown_content} ${openSubMenus.aboutUs ? css.show : ''}`}>
                            <Link to={"/C0533/about-hkrsu"} onClick={toggleNavBar}>本社介紹</Link>
                            <Link to={"/C0533/Our-Crew"} onClick={toggleNavBar}>教練團隊</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn} onClick={() => handleToggle('classes')}>Classes
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={`${css.dropdown_content} ${openSubMenus.classes ? css.show : ''}`}>
                            <Link to={"/C0533/course"} onClick={toggleNavBar}>學校課程</Link>
                            <Link to={"/C0533/Aces"} onClick={toggleNavBar}>Mini Aces／Aces</Link>
                            <Link to={"/C0533/Course"} onClick={toggleNavBar}>Summer Courses</Link>
                            <Link to={"/C0533/Course"} onClick={toggleNavBar}>Open Class (常規班)</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/performance_team"} onClick={toggleNavBar}>Performances</Link>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn} onClick={() => handleToggle('competitions')}>Competitions
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={`${css.dropdown_content} ${openSubMenus.competitions ? css.show : ''}`}>
                            <Link to={"/C0533/Union_Cup"} onClick={toggleNavBar}>聯校盃全港跳繩競速錦標賽</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn} onClick={() => handleToggle('awards')}>Awards
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={`${css.dropdown_content} ${openSubMenus.awards ? css.show : ''}`}>
                            <Link to={"/C0533/AWADRS-International"} onClick={toggleNavBar}>國際賽事獎項</Link>
                            <Link to={"/C0533/AWARDS"} onClick={toggleNavBar}>本地賽事獎項</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/about_rope"} onClick={toggleNavBar}>Sales</Link>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/contact-us"} onClick={toggleNavBar}>Contact Us</Link>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/Skill_Test"} onClick={toggleNavBar}>Skill Test</Link>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default MediaNavBar;
