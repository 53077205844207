import css from '../css/UpdateLogBook.module.css'
import { WebsiteMessage, SystemVersion, UpdatePatchNote } from '../Class/WebsiteMessage'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { use } from 'i18next';

const UpdateLog = () => {

    const [isDisplayModal, setIsDisplayModal] = useState<number>(2);
    const [isVersion, setVersion] = useState<string>("1.0.1");

    useEffect(() => {
        setVersion(SystemVersion);
    }, []);

    const renderPatchNotes = () =>
        UpdatePatchNote.filter((item) => item.Version === isVersion).map((item, index) => (
            <div key={`update-${index}`} className={css.UpdatePatchLogSection}>
                {/* Display Date and Message */}
                <div className={css.UpdatePatchLogDetails}>
                    <p><strong>版本編號:</strong> {item.Version}</p>
                    <p><strong>版本日期:</strong> {item.Date}</p>
                    <p><strong>更新訊息:</strong> {item.Message}</p>
                </div>
                {/* Render Content */}
                {item.Content.filter((eachtype) => eachtype.Points.length > 0).map((eachtype, typeIndex) => (
                    <div key={`type-${index}-${typeIndex}`} className={css.UpdateContentSection}>
                        <p><strong>{eachtype.Type}</strong></p>
                        <div className={css.UpdateContentDivider}>
                            {eachtype.Points.map((point, pointIndex) => (
                                <p key={`point-${index}-${typeIndex}-${pointIndex}`}>- {point}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        ));

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.updateLogTitle}>
                    <p>更新日誌</p>
                    <p className={css.version}>現時版本: {SystemVersion}</p>
                </div>

                <div className={css.SelectPatchLog}>
                    <label>查看版本資料</label>
                    <select name="Website_Versopn" value={isVersion} onChange={(e) => setVersion(e.target.value)}>
                        {UpdatePatchNote.map((item, index) => (
                            <option key={index} value={item.Version}>{item.Version}</option>
                        ))}
                    </select>
                </div>

                {isDisplayModal === 1 && (
                    <div className={css.UpdateLogTable}>
                        <table className={css.LogTable} id="LogListTable">
                            <thead>
                                <tr>
                                    <th>日期:</th>
                                    <th>ACID</th>
                                    <th>內容</th>
                                    <th>網頁位置</th>
                                </tr>
                            </thead>
                            <tbody>
                                {WebsiteMessage.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.Date}</td>
                                        <td>{item.ACID}</td>
                                        <td>{item.Message}</td>
                                        <td><Link to={item.Path}>{item.Path}</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {isDisplayModal === 2 && (
                    <div className={css.UpdatePatchLogDiv}>
                        {renderPatchNotes()}
                    </div>
                )}

            </section>
        </>
    )
    return content;
}

export default UpdateLog;