//import components
import HomePageChannel from "../../Components/C0533/HomPageChannel"
import ImageSlider from "../../Components/C0533/HomePageImageSlider"
import NewsInformation from "../../Components/C0533/NewsInformation"


const HomePage = () => {
    const content = (
        <>
            <ImageSlider />
            <NewsInformation />
            <HomePageChannel />
        </>
    )
    return content
}
export default HomePage