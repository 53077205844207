import OrderToDnCmp from '../../../Components/C0211/WareHouse/OrderToDn'
import DevelopDatePicker from '../../../Components/C0211/DevelopTest/DataPickerTest'

const OrderToDnPage = () => {
    const content = (
        <>
            <OrderToDnCmp />
            <DevelopDatePicker />
        </>
    )

    return content
}

export default OrderToDnPage