import { Link } from 'react-router-dom'
import css from '../../css/Auth/Navbar.module.css'

const AuthNavBar = () => {
    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.returnDiv}>
                    <Link to="/"><i className={`fi fi-rr-undo ${css.RetrunIcon}`} />
                        返回</Link>
                </div>
            </section>
        </>
    )

    return content
}

export default AuthNavBar;