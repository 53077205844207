import AWADS from '../../../Components/C0533/AWADS'

const AWARDSPage = () => {
    const content = (
        <>
        <AWADS />
        </>
    )
    return content
}
export default AWARDSPage