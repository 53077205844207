import { useState } from 'react'
import UserListCmp from '../../../Components/C0211/HumanResources/UserList'
import EditUserCmp from '../../../Components/C0211/HumanResources/EditUser'
import InsertNewUserCmp from '../../../Components/C0211/HumanResources/InsertUser'

const C0211UserListPage = () => {

    const [isuserid, setUserID] = useState<number | null>(null)

    const content = (
        <>
            {isuserid === null && (
                <UserListCmp setUserID={setUserID} />
            )}

            {isuserid === -1 && (
                <InsertNewUserCmp setUserID={setUserID} />
            )}

            {isuserid !== null && isuserid >= 0 && (
                <EditUserCmp
                    setUserID={setUserID}
                    isuserid={isuserid} />
            )}
        </>
    )

    return content
}

export default C0211UserListPage