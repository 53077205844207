import { useEffect, useState } from "react"
import css from "../../css/AlertMessage.module.css"
import { AlertMessageProps } from "../../Interface/interface"


const AlertMessage: React.FC<AlertMessageProps> = ({ ismessageType, isAlertMessage }) => {

    const [isShowMessage, setShowMessage] = useState<string>('');


    useEffect(() => {
        setShowMessage(isAlertMessage)
    }, [isAlertMessage])


    const content = (
        <section className={css.AlertMessageSection}>
            {ismessageType === 'Success' &&
                <div className={css.SuccessAlertMessageDiv}>
                    <i className="fi fi-bs-octagon-check"></i>
                    <h3>Success: </h3>
                    <p>{isShowMessage}</p>
                </div>
            }

            {ismessageType === 'Error' &&
                <div className={css.ErrorAlertMessageDiv}>
                    <i className="fi fi-bs-octagon-xmark"></i>
                    <h3>Error: </h3>
                    <p>{isShowMessage}</p>
                </div>
            }

            {ismessageType === 'Warning' &&
                <div className={css.WarningAlertMessageDiv}>
                    <i className="fi fi-bs-octagon-exclamation"></i>
                    <h3>Warning: </h3>
                    <p>{isShowMessage}</p>
                </div>
            }

            {ismessageType === 'Info' &&
                <div className={css.InfoAlertMessageDiv}>
                    <i className="fi fi-br-info"></i>
                    <h3>Info: </h3>
                    <p>{isShowMessage}</p>
                </div>
            }
        </section>
    )
    return content
}

export default AlertMessage