import { Parallax } from 'react-parallax';
import homeimg from '../global/home_bg_2.jpg'
import PLcss from '../css/PointList.module.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
//import 'bootstrap/dist/css/bootstrap.css'
import { useTranslation } from 'react-i18next';


const PointList = () => {
    const { t, i18n } = useTranslation();
    const content = (
        <div className="">
            <Parallax blur={3} bgImage={homeimg} bgImageAlt="BG" strength={1000}>
                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-check" /> {t('PointListTitle1')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage1')}</p>
                </div>

                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-graph-up-arrow" /> {t('PointListTitle2')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage2')}</p>
                </div>

                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-people-fill" /> {t('PointListTitle3')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage3')}</p>
                </div>

                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-layout-text-window-reverse" /> {t('PointListTitle4')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage4')}</p>
                </div>

                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-folder" /> {t('PointListTitle5')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage5')}</p>
                </div>

                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-pin-angle-fill" /> {t('PointListTitle6')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage6')}</p>
                </div>

                <div className={PLcss.ContectBox}>
                    <p className={PLcss.Title}><i className="bi bi-chat-left-text"></i> {t('PointListTitle7')}</p>
                    <p className={PLcss.Description}>{t('PointListMessage7')}</p>
                </div>
            </Parallax>
        </div>
    )
    return content
};

export default PointList;
