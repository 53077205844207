import UserList from "../Components/UserList"

const UserListPage = () => {
    const content = (
        <>
        <UserList />
        </>
    )
    return content
}

export default UserListPage
