import SkillTest from "../../Components/C0533/SkillTest"

const SkillTestPage = () => {
    const content = (
        <>
            <SkillTest />
        </>
    )
    return content
}

export default SkillTestPage