import { useEffect, useState } from 'react'
import css from '../../css/Common/ProgressBar.module.css'
import { IProgressBarProps } from '../../Interface/interface'

const ProgressBar: React.FC<IProgressBarProps> = ({ Percent }) => {

    const [isshowprcent, setShowPrecent] = useState<boolean>(false)

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setPrecent(isprecent + 1)
    //     }, 100)
    //     return () => clearInterval(interval)
    // }, [isprecent])

    const content = (
        <>
            <div className={css.progressBarBorder}>
                {isshowprcent ? <div className={css.progressText}>{Percent}%</div> : null}
                <div className={css.progress} style={{ width: `${Percent}%` }}></div>
            </div>
        </>
    )
    return content
}

export default ProgressBar