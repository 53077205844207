import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useContext, useEffect, useState } from "react";
import { c0211EditUserProps, IC0211UserAllowance, IC0211UserList } from "../../../Interface/interface"
import { CreateOutline } from 'react-ionicons';
import DatePicker, { DatePickerProps } from 'react-date-picker';
import MessageBox from "../../../Components/Common/AlertMessageBox";
import axios from "axios";
import { Cookies } from 'react-cookie';
import css from '../../../css/C0211/EditUserData.module.css'
import defaultpersonalImg from '../../../global/C0211/DefaultPhoto.jpg'
import DeafultLoadingBar from "../../LoadingBar/DefaultLoadingBar";
import AlertMessage from "../../LoadingBar/AlertMessage";
import Login from "../../Login";
import { config } from "process";
import CryptoJS from 'crypto-js';
import { PreLoadingContext } from "../../../Class/C0211PreLoadingData";
import { UserContext } from "../../../Class/UserPara";
import { AzureStorgeLink, C0211PreLoadingData, sasToken } from "../../../Interface/Const";
const cookies = new Cookies()

const token = cookies.get('token')
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const C0211EditUser: React.FC<c0211EditUserProps> = ({ setUserID, isuserid }) => {

    const userContext = useContext(UserContext);
    const preLoadDataContext = useContext(PreLoadingContext);

    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [isCancelUpdate, setCancelUpdate] = useState<boolean>(false);

    const [isShowMessageBox, setShowMessageBox] = useState<boolean>(false);
    const [isMessageBoxType, setMessageBoxType] = useState<string>('Boolean');
    const [isMessageBoxTitiel, setMessageBoxTitiel] = useState<string>('系統提示');
    const [isMessageBoxMessage, setMessageBoxMessage] = useState<string>('Are you sure you want to delete this product?');
    const [isMessageBoxResult, setMessageBoxResult] = useState<boolean | null>(null);

    const [isPhotoPanel, setPhotoPanel] = useState<boolean>(false);
    const [isPhotoAction, setPhotoAction] = useState<string>('');

    const [errorField, setErrorField] = useState({
        username: false,
        logincount: false,
        newpassword: false,
        confirmpassword: false,
    });

    const [fileData, setFileData] = useState<{ [key: string]: { file: File | null; previewUrl: string | null } }>({
        fileInput1: { file: null, previewUrl: null },
    });

    const [isUserPassword, setUserPassword] = useState<{ user_password: string; config_password: string }>({
        user_password: '',
        config_password: ''
    });

    const [data, setData] = useState<IC0211UserList>({
        user_id: null,
        login_id: '',
        staff_id: '',
        login_tel: '',
        login_email: '',
        user_password: '',
        user_name: '',
        authority_level: '',
        qr_login: '',
        login_count: 0,
        user_photo: '',
        department: '',
        job_title: '',
        passport_surname: '',
        passport_given_name: '',
        passport_full_name: '',
        date_of_birth: '',
        gender: '',
        marital_status: '',
        race: '',
        religion: '',
        nationality: '',
        address: '',
        postal_code: '',
        personal_email: '',
        work_email: '',
        employment_type: '',
        hire_date_1: '',
        hire_date_2: '',
        probation_date: '',
        probation_remarks: '',
        termination_date: '',
        termination_reason: '',
        job_comment: '',
        cost_center: '',
        notice_period: '',
        work_calendar: '',
        pay_frequency: '',
        bank_code: '',
        branch_code: '',
        bank_acc_num: '',
        bank_acc_name: '',
        id_type: '',
        id_num: '',
        visa_type: '',
        user_status: '',
        remarks: '',
        allowance: [{
            user_allowance_id: 0,
            user_id: 0,
            date_fm: '',
            a_01: false,
            a_02: false,
            a_03: false,
            a_04: false,
            a_05: false,
            a_06: false,
            a_07: false,
            a_08: false,
            a_09: false,
            a_10: false,
            a_11: false,
            a_12: false,
            a_13: false,
            a_14: false,
            a_15: false,
            a_16: false,
            a_17: false,
            a_18: false,
            a_19: false,
            a_20: false,
            ref_01: null,
            ref_02: null,
            ref_03: null,
            ref_04: null,
            ref_05: null,
            desc_01: '',
            desc_02: '',
            desc_03: '',
            desc_04: '',
            desc_05: '',
            salary: '',
            pay_freq: '',
            allowance_status: '',
            remarks: '',
            create_on: '',
            create_by: '',
            update_on: '',
            update_by: ''
        }],
        create_on: '',
        create_by: '',
        update_on: '',
        update_by: '',
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ismode, setMode] = useState<number>(1);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isallowanceid, setAllowanceID] = useState<number | null>(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isshowuploadinfo, setShowUploadInfo] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isloading, setLoading] = useState<boolean>(false);

    const bankList = [
        { value: 3, displayName: "STANDARD CHARTERED BANK (HONG KONG) LIMITED" },
        { value: 4, displayName: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD" },
        { value: 5, displayName: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK" },
        { value: 6, displayName: "CITIBANK N.A." },
        { value: 7, displayName: "JPMORGAN CHASE BANK, N.A." },
        { value: 8, displayName: "NATWEST MARKETS PLC" },
        { value: 9, displayName: "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED" },
        { value: 12, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 14, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 15, displayName: "THE BANK OF EAST ASIA, LIMITED" },
        { value: 16, displayName: "DBS BANK (HONG KONG) LIMITED" },
        { value: 18, displayName: "CHINA CITIC BANK INTERNATIONAL LIMITED" },
        { value: 19, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 20, displayName: "CMB WING LUNG BANK LIMITED" },
        { value: 22, displayName: "OVERSEA-CHINESE BANKING CORPORATION LTD" },
        { value: 24, displayName: "HANG SENG BANK LTD" },
        { value: 25, displayName: "SHANGHAI COMMERCIAL BANK LTD" },
        { value: 26, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 27, displayName: "BANK OF COMMUNICATIONS CO., LTD." },
        { value: 28, displayName: "PUBLIC BANK (HONG KONG) LIMITED" },
        { value: 29, displayName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD" },
        { value: 30, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 31, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 32, displayName: "DBS BANK (HONG KONG) LIMITED" },
        { value: 33, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 35, displayName: "OCBC WING HANG BANK LIMITED" },
        { value: 36, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 38, displayName: "TAI YAU BANK LTD" },
        { value: 39, displayName: "CHIYU BANKING CORPORATION LTD" },
        { value: 40, displayName: "DAH SING BANK LTD" },
        { value: 41, displayName: "CHONG HING BANK LIMITED" },
        { value: 43, displayName: "NANYANG COMMERCIAL BANK LTD" },
        { value: 44, displayName: "OCBC WING HANG BANK LIMITED" },
        { value: 45, displayName: "UCO BANK" },
        { value: 46, displayName: "KEB HANA BANK" },
        { value: 47, displayName: "MUFG BANK, LTD." },
        { value: 49, displayName: "BANGKOK BANK PUBLIC COMPANY LTD" },
        { value: 50, displayName: "INDIAN OVERSEAS BANK" },
        { value: 51, displayName: "CHINA CITIC BANK INTERNATIONAL LIMITED" },
        { value: 52, displayName: "DBS BANK (HONG KONG) LIMITED" },
        { value: 54, displayName: "DEUTSCHE BANK AG" },
        { value: 55, displayName: "BANK OF AMERICA, NATIONAL ASSOCIATION" },
        { value: 56, displayName: "BNP PARIBAS" },
        { value: 58, displayName: "BANK OF INDIA" },
        { value: 60, displayName: "NATIONAL BANK OF PAKISTAN" },
        { value: 61, displayName: "TAI SANG BANK LTD" },
        { value: 63, displayName: "MALAYAN BANKING BERHAD (MAYBANK)" },
        { value: 64, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 65, displayName: "SUMITOMO MITSUI BANKING CORPORATION" },
        { value: 66, displayName: "PT. BANK NEGARA INDONESIA (PERSERO) TBK." },
        { value: 67, displayName: "BDO UNIBANK, INC." },
        { value: 70, displayName: "BANK OF CHINA (HONG KONG) LIMITED" },
        { value: 71, displayName: "UNITED OVERSEAS BANK LTD" },
        { value: 72, displayName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD" },
        { value: 74, displayName: "BARCLAYS BANK PLC" },
        { value: 76, displayName: "THE BANK OF NOVA SCOTIA" },
        { value: 80, displayName: "ROYAL BANK OF CANADA" },
        { value: 81, displayName: "SOCIETE GENERALE" },
        { value: 82, displayName: "STATE BANK OF INDIA" },
        { value: 85, displayName: "THE TORONTO-DOMINION BANK" },
        { value: 86, displayName: "BANK OF MONTREAL" },
        { value: 92, displayName: "CANADIAN IMPERIAL BANK OF COMMERCE" },
        { value: 97, displayName: "COMMERZBANK AG" },
        { value: 103, displayName: "UBS AG, HONG KONG" },
        { value: 106, displayName: "HSBC BANK USA, NATIONAL ASSOCIATION" },
        { value: 109, displayName: "MIZUHO BANK, LTD." },
        { value: 113, displayName: "DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK" },
        { value: 118, displayName: "WOORI BANK" },
        { value: 119, displayName: "PHILIPPINE NATIONAL BANK" },
        { value: 128, displayName: "FUBON BANK (HONG KONG) LIMITED" },
        { value: 138, displayName: "MITSUBISHI UFJ TRUST AND BANKING CORPORATION" },
        { value: 139, displayName: "THE BANK OF NEW YORK MELLON" },
        { value: 145, displayName: "ING BANK N.V." },
        { value: 147, displayName: "BANCO BILBAO VIZCAYA ARGENTARIA, S.A." },
        { value: 150, displayName: "NATIONAL AUSTRALIA BANK LTD" },
        { value: 151, displayName: "WESTPAC BANKING CORPORATION" },
        { value: 152, displayName: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD" },
        { value: 153, displayName: "COMMONWEALTH BANK OF AUSTRALIA" },
        { value: 161, displayName: "INTESA SANPAOLO S.P.A." },
        { value: 164, displayName: "UNICREDIT BANK AG" },
        { value: 170, displayName: "THE CHIBA BANK LTD" },
        { value: 178, displayName: "KBC BANK N.V." },
        { value: 180, displayName: "WELLS FARGO BANK, N.A." },
        { value: 183, displayName: "COOPERATIEVE RABOBANK U.A." },
        { value: 185, displayName: "DBS BANK LTD, HONG KONG BRANCH" },
        { value: 186, displayName: "THE SHIZUOKA BANK, LTD" },
        { value: 188, displayName: "THE HACHIJUNI BANK, LTD" },
        { value: 198, displayName: "HUA NAN COMMERCIAL BANK LTD" },
        { value: 199, displayName: "THE SHIGA BANK, LTD." },
        { value: 201, displayName: "BANK OF TAIWAN" },
        { value: 202, displayName: "THE CHUGOKU BANK LTD" },
        { value: 203, displayName: "FIRST COMMERCIAL BANK" },
        { value: 206, displayName: "CHANG HWA COMMERCIAL BANK LTD" },
        { value: 210, displayName: "NATIXIS" },
        { value: 214, displayName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED" },
        { value: 220, displayName: "STATE STREET BANK AND TRUST COMPANY" },
        { value: 221, displayName: "CHINA CONSTRUCTION BANK CORPORATION" },
        { value: 222, displayName: "AGRICULTURAL BANK OF CHINA LIMITED" },
        { value: 227, displayName: "ERSTE GROUP BANK AG" },
        { value: 229, displayName: "CTBC BANK CO., LTD." },
        { value: 230, displayName: "TAIWAN BUSINESS BANK, LTD." },
        { value: 233, displayName: "CREDIT SUISSE AG" },
        { value: 236, displayName: "CATHAY UNITED BANK COMPANY, LIMITED" },
        { value: 237, displayName: "EFG BANK AG" },
        { value: 238, displayName: "CHINA MERCHANTS BANK CO., LTD." },
        { value: 239, displayName: "TAIPEI FUBON COMMERCIAL BANK CO., LTD." },
        { value: 241, displayName: "BANK SINOPAC" },
        { value: 242, displayName: "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD." },
        { value: 243, displayName: "E.SUN COMMERCIAL BANK, LTD" },
        { value: 244, displayName: "BANK SINOPAC" },
        { value: 245, displayName: "TAISHIN INTERNATIONAL BANK CO., LTD" },
        { value: 248, displayName: "HONG LEONG BANK BERHAD" },
        { value: 249, displayName: "STANDARD CHARTERED BANK HONG KONG BRANCH" },
        { value: 250, displayName: "CITIBANK (HONG KONG) LIMITED" },
        { value: 251, displayName: "ICICI BANK LIMITED" },
        { value: 254, displayName: "MELLI BANK PLC" },
        { value: 258, displayName: "EAST WEST BANK" },
        { value: 260, displayName: "FAR EASTERN INTERNATIONAL BANK" },
        { value: 262, displayName: "CANARA BANK" },
        { value: 263, displayName: "CATHAY BANK" },
        { value: 264, displayName: "LAND BANK OF TAIWAN CO., LTD." },
        { value: 265, displayName: "TAIWAN COOPERATIVE BANK, LTD." },
        { value: 266, displayName: "PUNJAB NATIONAL BANK" },
        { value: 267, displayName: "BANCO SANTANDER S.A." },
        { value: 268, displayName: "UNION BANK OF INDIA" },
        { value: 269, displayName: "THE SHANGHAI COMMERCIAL & SAVINGS BANK LTD" },
        { value: 271, displayName: "INDUSTRIAL BANK OF KOREA" },
        { value: 272, displayName: "BANK OF SINGAPORE LIMITED" },
        { value: 273, displayName: "SHINHAN BANK" },
        { value: 274, displayName: "O-BANK CO., LTD." },
        { value: 275, displayName: "BNP PARIBAS SECURITIES SERVICES" },
        { value: 276, displayName: "CHINA DEVELOPMENT BANK" },
        { value: 277, displayName: "FIRST ABU DHABI BANK PJSC" },
        { value: 278, displayName: "BANK J. SAFRA SARASIN LTD" },
        { value: 279, displayName: "BANK OF CHINA (HONG KONG) LIMITED, MANILA BRANCH" },
        { value: 280, displayName: "BANK OF CHINA LIMITED TOKYO BRANCH" },
        { value: 281, displayName: "PT BANK CENTRAL ASIA TBK, INDONESIA" },
        { value: 282, displayName: "BANK OF CHINA (MALAYSIA) BERHAD" },
        { value: 283, displayName: "JOINT-STOCK COMMERCIAL BANK <BANK OF CHINA (RUSSIA)>" },
        { value: 284, displayName: "BANK OF CHINA LIMITED JAKARTA BRANCH" },
        { value: 285, displayName: "BANK OF CHINA LIMITED SEOUL BRANCH" },
        { value: 286, displayName: "BANK OF CHINA LIMITED SINGAPORE BRANCH" },
        { value: 287, displayName: "BANK OF CHINA LIMITED FRANKFURT BRANCH" },
        { value: 288, displayName: "BANK OF CHINA LIMITED SYDNEY BRANCH" },
        { value: 289, displayName: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED" },
        { value: 290, displayName: "BANK OF CHINA (CENTRAL AND EASTERN EUROPE) LIMITED" },
        { value: 291, displayName: "BANK OF CHINA LIMITED MILAN BRANCH" },
        { value: 292, displayName: "BANK OF CHINA LONDON BRANCH" },
        { value: 293, displayName: "VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE" },
        { value: 295, displayName: "METROPOLITAN BANK & TRUST COMPANY" },
        { value: 296, displayName: "SAIGON THUONG TIN COMMERCIAL JOINT STOCK BANK" },
        { value: 298, displayName: "BANK OF CHINA LIMITED JOHANNESBURG BRANCH" },
        { value: 300, displayName: "BANK OF CHINA, LUXEMBOURG S.A." },
        { value: 301, displayName: "BANK OF CHINA LIMITED LUXEMBOURG BRANCH" },
        { value: 302, displayName: "BANK OF CHINA LIMITED GRAND CAYMAN BRANCH" },
        { value: 303, displayName: "BANK OF CHINA LIMITED PARIS BRANCH" },
        { value: 304, displayName: "BANK OF CHINA LIMITED NEW YORK BRANCH" },
        { value: 305, displayName: "JSC AB (BANK OF CHINA KAZAKHSTAN)" },
        { value: 306, displayName: "BANK OF CHINA (ZAMBIA) LIMITED" },
        { value: 307, displayName: "ABN AMRO BANK N.V." },
        { value: 308, displayName: "HDFC BANK LTD." },
        { value: 309, displayName: "UNION BANCAIRE PRIVEE, UBP SA" },
        { value: 310, displayName: "BANK OF CHINA (CANADA)" },
        { value: 311, displayName: "BANK OF CHINA LIMITED, PANAMA BRANCH" },
        { value: 314, displayName: "BANK OF CHINA (UK) LIMITED" },
        { value: 315, displayName: "BANK OF CHINA (AUSTRALIA) LIMITED" },
        { value: 316, displayName: "SKANDINAVISKA ENSKILDA BANKEN AB" },
        { value: 318, displayName: "KDB ASIA LIMITED" },
        { value: 319, displayName: "COOPERATIEVE RABOBANK U.A., SINGAPORE" },
        { value: 320, displayName: "BANK JULIUS BAER & CO. LTD." },
        { value: 321, displayName: "BANK OF CHINA INTERNATIONAL LIMITED" },
        { value: 322, displayName: "HABIB BANK ZURICH (HONG KONG) LIMITED" },
        { value: 323, displayName: "COOPERATIEVE RABOBANK U.A., UTRECHT" },
        { value: 324, displayName: "CREDIT INDUSTRIEL ET COMMERCIAL" },
        { value: 325, displayName: "GRANIT BANK ZRT" },
        { value: 326, displayName: "BANK OF CHINA (BRASIL) S.A." },
        { value: 327, displayName: "BANK OF CHINA LIMITED PHNOM PENH BRANCH" },
        { value: 329, displayName: "THE BANK OF NOVA SCOTIA, SINGAPORE" },
        { value: 331, displayName: "NATIONAL BANK OF CANADA" },
        { value: 337, displayName: "TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD." },
        { value: 338, displayName: "BANK OF CHINA LIMITED, HONG KONG BRANCH" },
        { value: 339, displayName: "CA INDOSUEZ (SWITZERLAND) SA" },
        { value: 340, displayName: "AGRICULTURAL BANK OF CHINA LIMITED, SINGAPORE BRANCH" },
        { value: 342, displayName: "LGT BANK AG" },
        { value: 343, displayName: "CIMB THAI BANK PUBLIC COMPANY LIMITED" },
        { value: 344, displayName: "MACQUARIE BANK LIMITED" },
        { value: 345, displayName: "SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD" },
        { value: 348, displayName: "BANK OF CHINA (HONG KONG) LIMITED HO CHI MINH CITY BRANCH" },
        { value: 349, displayName: "BANK OF COMMUNICATIONS SEOUL BRANCH" },
        { value: 351, displayName: "CAMBODIA MEKONG BANK PUBLIC LIMITED" },
        { value: 352, displayName: "MASHREQBANK PSC" },
        { value: 353, displayName: "CHINA MINSHENG BANKING CORP., LTD." },
        { value: 356, displayName: "BANCO DO BRASIL S. A., TOKYO BRANCH" },
        { value: 357, displayName: "PICTET & CIE (EUROPE) S.A." },
        { value: 358, displayName: "CHINA CONSTRUCTION BANK CORPORATION SEOUL BRANCH" },
        { value: 359, displayName: "CHINA GUANGFA BANK CO., LTD." },
        { value: 361, displayName: "CHINA BOHAI BANK CO., LTD." },
        { value: 364, displayName: "BANQUE PICTET & CIE SA" },
        { value: 365, displayName: "BANK OF DONGGUAN CO., LTD." },
        { value: 368, displayName: "CHINA EVERBRIGHT BANK CO., LTD" },
        { value: 369, displayName: "BANK OF CHINA (DUBAI) BRANCH" },
        { value: 371, displayName: "SUMITOMO MITSUI TRUST BANK, LIMITED" },
        { value: 372, displayName: "BANK OF SHANGHAI (HONG KONG) LIMITED" },
        { value: 374, displayName: "CIMB BANK BERHAD" },
        { value: 375, displayName: "J.P. MORGAN SECURITIES (ASIA PACIFIC) LIMITED" },
        { value: 377, displayName: "INDUSTRIAL BANK CO., LTD." },
        { value: 378, displayName: "YUANTA COMMERCIAL BANK CO., LTD." },
        { value: 379, displayName: "MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY" },
        { value: 380, displayName: "KASIKORNBANK PUBLIC COMPANY LIMITED" },
        { value: 381, displayName: "KOOKMIN BANK" },
        { value: 382, displayName: "BANK OF COMMUNICATIONS (HONG KONG) LIMITED" },
        { value: 383, displayName: "CHINA ZHESHANG BANK CO., LTD." },
        { value: 384, displayName: "MORGAN STANLEY BANK ASIA LIMITED" },
        { value: 385, displayName: "PING AN BANK CO., LTD." },
        { value: 386, displayName: "HUA XIA BANK CO., LIMITED" },
        { value: 387, displayName: "ZA BANK LIMITED" },
        { value: 388, displayName: "LIVI BANK LIMITED" },
        { value: 389, displayName: "MOX BANK LIMITED" },
        { value: 390, displayName: "WELAB BANK LIMITED" },
        { value: 391, displayName: "FUSION BANK LIMITED" },
        { value: 392, displayName: "PING AN ONECONNECT BANK (HONG KONG) LIMITED" },
        { value: 393, displayName: "ANT BANK (HONG KONG) LIMITED" },
        { value: 394, displayName: "QATAR NATIONAL BANK (Q.P.S.C.)" },
        { value: 395, displayName: "AIRSTAR BANK LIMITED" },
        { value: 400, displayName: "AGRICULTURAL BANK OF CHINA, BEIJING" },
        { value: 402, displayName: "ALLIED BANKING CORPORATION (HK) LTD." },
        { value: 404, displayName: "AUTORIDADE MONETARIA DE MACAU, MACAU" },
        { value: 406, displayName: "BANCO NACIONAL ULTRAMARINO S.A. MACAU BRANCH" },
        { value: 407, displayName: "JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT OF VIETNAM, HANOI" },
        { value: 414, displayName: "BANK OF CHINA LIMITED, HEAD OFFICE" },
        { value: 420, displayName: "BANK OF CHINA LIMITED MACAU BRANCH" },
        { value: 428, displayName: "BANK OF COMMUNICATIONS CO., LTD. HEAD OFFICE, SHANGHAI" },
        { value: 429, displayName: "BANK OF COMMUNICATIONS CO., LTD. SHENZHEN BRANCH" },
        { value: 431, displayName: "BANK SINOPAC, TAIPEI" },
        { value: 434, displayName: "CHINA EVERBRIGHT BANK, BEIJING" },
        { value: 435, displayName: "CHINA MERCHANTS BANK, HEAD OFFICE, SHENZHEN" },
        { value: 436, displayName: "CHINA MINSHENG BANKING CORPORATION LTD., BEIJING" },
        { value: 437, displayName: "CHINA CITIC BANK, BEIJING" },
        { value: 439, displayName: "DONGGUAN RURAL CREDIT COOPERATIVES UNION, DONGGUAN" },
        { value: 440, displayName: "EXPORT-IMPORT BANK OF THAILAND, BANGKOK" },
        { value: 445, displayName: "INDUSTRIAL BANK COMPANY LTD., FUZHOU" },
        { value: 447, displayName: "CHINA GUANGFA BANK CO. LTD, BEIJING" },
        { value: 450, displayName: "HSBC BANGLADESH" },
        { value: 452, displayName: "HSBC INDIA" },
        { value: 453, displayName: "PT BANK HSBC INDONESIA" },
        { value: 454, displayName: "HSBC JAPAN" },
        { value: 456, displayName: "HSBC MACAU" },
        { value: 457, displayName: "HSBC BANK MALAYSIA BERHAD" },
        { value: 458, displayName: "HSBC MANILA" },
        { value: 460, displayName: "HSBC SEOUL BRANCH" },
        { value: 461, displayName: "HSBC BANK (CHINA) COMPANY LIMITED" },
        { value: 462, displayName: "HSBC SINGAPORE" },
        { value: 463, displayName: "HSBC SRI LANKA" },
        { value: 464, displayName: "HSBC BANK (TAIWAN) LIMITED" },
        { value: 465, displayName: "HSBC THAILAND" },
        { value: 466, displayName: "HSBC BANK VIETNAM LTD" },
        { value: 468, displayName: "HUA XIA BANK, BEIJING" },
        { value: 469, displayName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA, BEIJING" },
        { value: 470, displayName: "INDUSTRIAL BANK OF TAIWAN, TAIPEI" },
        { value: 474, displayName: "TAIWAN SHIN KONG COMMERCIAL BANK COMPANY LIMITED, TAIPEI" },
        { value: 478, displayName: "BANK OF NINGBO CO. LTD., ZHEJIANG" },
        { value: 480, displayName: "PT. BANK MANDIRI (PERSERO) TBK, HONG KONG BRANCH" },
        { value: 483, displayName: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MACAU) LIMITED, MACAU" },
        { value: 484, displayName: "SHANGHAI PUDONG DEVELOPMENT BANK, SHANGHAI" },
        { value: 485, displayName: "PING AN BANK CO., LTD, SHENZHEN" },
        { value: 486, displayName: "SHENZHEN DEVELOPMENT BANK, SHENZHEN" },
        { value: 487, displayName: "THE SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED" },
        { value: 489, displayName: "STANDARD CHARTERED BANK, BEIJING BRANCH" },
        { value: 490, displayName: "STANDARD CHARTERED BANK, NANJING BRANCH" },
        { value: 491, displayName: "STANDARD CHARTERED BANK, SHANGHAI BRANCH" },
        { value: 492, displayName: "STANDARD CHARTERED BANK, SHENZHEN BRANCH" },
        { value: 493, displayName: "STANDARD CHARTERED BANK, TIANJIN BRANCH" },
        { value: 494, displayName: "STANDARD CHARTERED BANK, XIAMEN BRANCH" },
        { value: 495, displayName: "STANDARD CHARTERED BANK, ZHUHAI BRANCH" },
        { value: 501, displayName: "TMB BANK PUBLIC COMPANY LIMITED, HONG KONG" },
        { value: 504, displayName: "UNITED SUBURBAN AND RURAL CREDIT COOPERATIVE WUXI, WUXI" },
        { value: 505, displayName: "VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK, HANOI" },
        { value: 507, displayName: "CHINA RESOURCES BANK OF ZHUHAI CO LTD., ZHUHAI" },
        { value: 509, displayName: "SHENZHEN RURAL COMMERCIAL BANK" },
        { value: 515, displayName: "STANDARD CHARTERED BANK, JAKARTA BRANCH" },
        { value: 516, displayName: "STANDARD CHARTERED BANK, SINGAPORE BRANCH" },
        { value: 517, displayName: "STANDARD CHARTERED BANK, PHILIPPINES BRANCH" },
        { value: 518, displayName: "STANDARD CHARTERED BANK, SEOUL KOREA" },
        { value: 519, displayName: "STANDARD CHARTERED BANK, MALAYSIA BERHAD" },
        { value: 520, displayName: "STANDARD CHARTERED BANK, TAIWAN" },
        { value: 521, displayName: "STANDARD CHARTERED BANK, BANGKOK" },
        { value: 522, displayName: "STANDARD CHARTERED BANK, SRI LANKA" },
        { value: 524, displayName: "CONCORD BANK LTD., NINGBO" },
        { value: 529, displayName: "BANK OF CHANGSHA CO LTD, CHANGSHA, HUNAN" },
        { value: 531, displayName: "CITIBANK (CHINA) CO LTD, SHENZHEN BRANCH" },
        { value: 536, displayName: "STANDARD CHARTERED BANK PLC, LONDON" },
        { value: 538, displayName: "THE MACAU CHINESE BANK LTD, MACAU" },
        { value: 540, displayName: "BANK OF GUIYANG CO LTD, GUIYANG" },
        { value: 542, displayName: "BANK OF MONTREAL, GUANGZHOU BRANCH" },
        { value: 547, displayName: "XIAMEN BANK CO., LTD., XIAMEN" },
        { value: 549, displayName: "CHINA GUANGFA BANK CO., LTD. GUANGZHOU" },
        { value: 550, displayName: "HUA NAN COMMERCIAL BANK LTD., TAIPEI" },
        { value: 552, displayName: "AAREAL BANK AG, WIESBADEN, GERMANY" },
        { value: 553, displayName: "BANK FOR FOREIGN TRADE OF VIETNAM" },
        { value: 554, displayName: "THE HOUSING BANK FOR TRADE AND FINANCE, JORDAN" },
        { value: 557, displayName: "E.SUN COMMERCIAL BANK, LTD., TAIPEI" },
        { value: 802, displayName: "HONG KONG SECURITIES CLEARING COMPANY LIMITED" },
        { value: 808, displayName: "HONG KONG MONETARY AUTHORITY - SUB-ACCOUNT" },
        { value: 809, displayName: "HONG KONG MONETARY AUTHORITY CMU" },
        { value: 818, displayName: "STANDARD CHARTERED BANK (HONG KONG) LIMITED - EURO CLEARING SETTLEMENT INSTITUTION" },
        { value: 828, displayName: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD - USD CLEARING SETTLEMENT INSTITUTION" },
        { value: 833, displayName: "BANK OF CHINA (HK) LTD. RMB FIDUCIARY ACCOUNT" },
        { value: 838, displayName: "BANK OF CHINA (HONG KONG) LIMITED - RMB CLEARING BANK" },
        { value: 868, displayName: "CONTINUOUS LINKED SETTLEMENT BANK INTERNATIONAL" },
        { value: 871, displayName: "BANK OF CHINA (HONG KONG) LIMITED - GEBSC" },
        { value: 872, displayName: "BANK OF CHINA (HONG KONG) LIMITED - SZFESC" },
        { value: 888, displayName: "HONG KONG MONETARY AUTHORITY" }
    ];

    const awardList = [
        { value: "date_fm", displayName: "日期" },
        { value: "a_01", displayName: "達標獎" },
        { value: "a_02", displayName: "優質服務獎" },
        { value: "a_03", displayName: "全勤獎" },
        { value: "a_04", displayName: "佣金" },
        { value: "a_05", displayName: "神秘人" },
        { value: "a_06", displayName: "替假津貼" },
        { value: "a_07", displayName: "東涌津貼" },
        { value: "a_08", displayName: "店長津貼" },
        { value: "a_11", displayName: "工場津貼" },
        { value: "a_12", displayName: "津貼(組長)" },
        { value: "a_13", displayName: "炸貨津貼" },
        { value: "a_16", displayName: "無投訴" },
        { value: "a_17", displayName: "勤勞獎 (O.T.)" },
        { value: "desc_01", displayName: "店鋪 (1)" },
        { value: "desc_02", displayName: "店鋪 (2)" },
        { value: "desc_03", displayName: "店鋪 (3)" },
        { value: "desc_04", displayName: "店鋪 (4)" },
        { value: "desc_05", displayName: "店鋪 (5)" },
        { value: "salary", displayName: "薪金" },
        { value: "pay_freq", displayName: "出薪頻率" },
        { value: "allowance_status", displayName: "狀態" },
        { value: "remarks", displayName: "附註" },
    ];


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getUserData();
    }, [isuserid])

    useEffect(() => {
        if (isShowAlert === true) {
            setTimeout(() => {
                setShowAlert(false)
            }, 8000)
        }
    }, [isShowAlert])

    const addNewAllowance = () => {
        const newAllowance: IC0211UserAllowance = {
            user_allowance_id: 0,
            user_id: data.user_id || 0,
            date_fm: '',
            a_01: false,
            a_02: false,
            a_03: false,
            a_04: false,
            a_05: false,
            a_06: false,
            a_07: false,
            a_08: false,
            a_09: false,
            a_10: false,
            a_11: false,
            a_12: false,
            a_13: false,
            a_14: false,
            a_15: false,
            a_16: false,
            a_17: false,
            a_18: false,
            a_19: false,
            a_20: false,
            ref_01: null,
            ref_02: null,
            ref_03: null,
            ref_04: null,
            ref_05: null,
            desc_01: '',
            desc_02: '',
            desc_03: '',
            desc_04: '',
            desc_05: '',
            salary: '',
            pay_freq: '',
            allowance_status: '',
            remarks: '',
            create_on: '',
            create_by: '',
            update_on: '',
            update_by: '',
        };

        setData((prevData) => ({
            ...prevData,
            allowance: [...(prevData.allowance || []), newAllowance],
        }));
        setAllowanceID(data.allowance ? data.allowance.length : 0);
    }

    useEffect(() => {
        if (isPhotoAction === '') return;

        if (isMessageBoxResult === false && isPhotoAction === 'upload') {
            setFileData((prevData) => ({
                ...prevData,
                fileInput1: {
                    file: null,
                    previewUrl: null,
                },
            }));

            setMessageBoxResult(null)
            setPhotoAction('')
            return;
        }

        if (isMessageBoxResult === null || isMessageBoxResult === false) return;

        if (isPhotoAction === 'remove') {
            handleRemovePhoto();
        }

        if (isPhotoAction === 'upload') {
            handleUplaodPhotoToAzure();
        }
    }, [isMessageBoxResult])

    const handleRemovePhoto = async () => {
        setLoading(true)

        try {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/UpdateUserPhoto`, {
                user_id: data.user_id,
                user_photo: null,
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {

                setData(prevData => ({
                    ...prevData,
                    user_photo: ''
                }))

                setFileData((prevData) => ({
                    ...prevData,
                    fileInput1: {
                        file: null,
                        previewUrl: null,
                    },
                }));

                setShowAlert(true)
                setAlertType('Success')
                setAlertMessage('User photo has been removed.')
                preLoadDataContext.preLoadData.isProductList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);

            }

        } catch (error) {
            console.log(error)
        } finally {
            setMessageBoxResult(null)
            setPhotoAction('')
            setPhotoPanel(false)
            setLoading(false)
        }
    }

    const handleUplaodPhotoToAzure = async () => {
        setLoading(true)
        let uploadedImageUrl = data.user_photo;

        if (fileData.fileInput1.file !== null) {
            uploadedImageUrl = (await uploadImageToAzureUsingREST()) || data.user_photo;
            if (!uploadedImageUrl) {
                setShowAlert(true)
                setAlertType('Error')
                setAlertMessage('Image upload failed. Please try again.')
                setLoading(false)

                setMessageBoxResult(null)
                setPhotoAction('')
                return;
            }
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/UpdateUserPhoto`, {
                user_id: data.user_id,
                user_photo: uploadedImageUrl,
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                setShowAlert(true)
                setAlertType('Success')
                setAlertMessage('Product photo has been updated.')
                preLoadDataContext.preLoadData.isProductList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);
            }

        } catch (error) {

        } finally {
            setMessageBoxResult(null)
            setPhotoAction('')
            setLoading(false)
        }
    }

    const handleRemovePhotoMessage = () => {
        setPhotoPanel(false)
        setMessageBoxMessage('Are you sure you want to remove this photo?');
        setShowMessageBox(true)
        setMessageBoxType('Boolean')
        setPhotoAction('remove')
    }

    const RemoveUserAllowance = (index: number) => {
        setData((prevData) => {
            const updatedAllowances = prevData.allowance ? [...prevData.allowance] : [];
            if (updatedAllowances[index]) {
                updatedAllowances.splice(index, 1);
            }

            // Reset isallowanceid if the currently selected allowance was removed
            let newAllowanceID = isallowanceid;
            if (isallowanceid !== null && (isallowanceid === index || isallowanceid >= updatedAllowances.length)) {
                newAllowanceID = null;
            }

            setAllowanceID(newAllowanceID);

            return {
                ...prevData,
                allowance: updatedAllowances,
            };
        });
    };

    const saveUserData = async () => {
        let AllowanceArr: IC0211UserAllowance[] = [];

        setLoading(true)
        setCancelUpdate(false)

        const errors = {
            username: data.user_name === "",
            logincount: data.login_count?.toString() === "",
            newpassword: false,
            confirmpassword: false,
        };

        setErrorField(errors);

        if (data.user_id === null || data.user_id === undefined) {
            setAlertType("Error")
            setAlertMessage("User ID is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.login_id === null || data.login_id === undefined) {
            setAlertType("Error")
            setAlertMessage("Login ID is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.staff_id === null || data.staff_id === undefined) {
            setAlertType("Error")
            setAlertMessage("Staff ID is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.user_name === null || data.user_name === undefined || data.user_name === "") {
            setAlertType("Error")
            setAlertMessage("User Name is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.authority_level === null || data.authority_level === undefined) {
            setAlertType("Error")
            setAlertMessage("Authority Level is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.login_count === null || data.login_count === undefined || data.login_count.toString() === "") {
            setAlertType("Error")
            setAlertMessage("Login Count is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.user_status === null || data.user_status === undefined) {
            setAlertType("Error")
            setAlertMessage("User Status is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.allowance !== null && data.allowance !== undefined) {
            AllowanceArr = data.allowance.map((item) => {

                if (item.date_fm === null || item.date_fm === undefined || item.date_fm === '') {
                    setAlertType("Error")
                    setAlertMessage("Allowance Date is missing.")
                    setShowAlert(true)
                    setCancelUpdate(true)
                }

                if (item.salary === null || item.salary === undefined || item.salary === '') {
                    setAlertType("Error")
                    setAlertMessage("Salary is missing.")
                    setShowAlert(true)
                    setCancelUpdate(true)
                }

                if (item.pay_freq === null || item.pay_freq === undefined || item.pay_freq === '') {
                    setAlertType("Error")
                    setAlertMessage("Pay Frequency is missing.")
                    setShowAlert(true)
                    setCancelUpdate(true)
                }

                if (item.allowance_status === null || item.allowance_status === undefined || item.allowance_status === '') {
                    setAlertType("Error")
                    setAlertMessage("Allowance Status is missing.")
                    setShowAlert(true)
                    setCancelUpdate(true)
                }

                return {
                    user_allowance_id: item.user_allowance_id,
                    date_fm: item.date_fm,
                    a_01: item.a_01 ? 1 : 0,
                    a_02: item.a_02 ? 1 : 0,
                    a_03: item.a_03 ? 1 : 0,
                    a_04: item.a_04 ? 1 : 0,
                    a_05: item.a_05 ? 1 : 0,
                    a_06: item.a_06 ? 1 : 0,
                    a_07: item.a_07 ? 1 : 0,
                    a_08: item.a_08 ? 1 : 0,
                    a_09: item.a_09 ? 1 : 0,
                    a_10: item.a_10 ? 1 : 0,
                    a_11: item.a_11 ? 1 : 0,
                    a_12: item.a_12 ? 1 : 0,
                    a_13: item.a_13 ? 1 : 0,
                    a_14: item.a_14 ? 1 : 0,
                    a_15: item.a_15 ? 1 : 0,
                    a_16: item.a_16 ? 1 : 0,
                    a_17: item.a_17 ? 1 : 0,
                    a_18: item.a_18 ? 1 : 0,
                    a_19: item.a_19 ? 1 : 0,
                    a_20: item.a_20 ? 1 : 0,
                    ref_01: item.ref_01,
                    ref_02: item.ref_02,
                    ref_03: item.ref_03,
                    ref_04: item.ref_04,
                    ref_05: item.ref_05,
                    desc_01: item.desc_01,
                    desc_02: item.desc_02,
                    desc_03: item.desc_03,
                    desc_04: item.desc_04,
                    desc_05: item.desc_05,
                    salary: item.salary,
                    pay_freq: item.pay_freq,
                    allowance_status: item.allowance_status === "Yes" ? "Y" : "N",
                    remarks: item.remarks,
                }
            })

        }

        if (isCancelUpdate) {
            console.log("Cancel Update")
            setLoading(false)
            return;
        }

        try {
            console.log("AllowanceArr", AllowanceArr)

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/UpdateUserList`, {
                user_id: data.user_id,
                login_email: data.login_email,
                user_name: data.user_name,
                authority_level: data.authority_level,
                qr_login: data.qr_login,
                login_count: data.login_count,
                department: data.department,
                job_title: data.job_title,
                passport_surname: data.passport_surname,
                passport_given_name: data.passport_given_name,
                passport_full_name: data.passport_full_name,
                date_of_birth: data.date_of_birth,
                gender: data.gender,
                marital_status: data.marital_status,
                race: data.race,
                religion: data.religion,
                nationality: data.nationality,
                address: data.address,
                postal_code: data.postal_code,
                personal_email: data.personal_email,
                work_email: data.work_email,
                employment_type: data.employment_type,
                hire_date_1: data.hire_date_1,
                hire_date_2: data.hire_date_2,
                probation_date: data.probation_date,
                probation_remarks: data.probation_remarks,
                job_comment: data.job_comment,
                cost_center: data.cost_center,
                notice_period: data.notice_period,
                work_calendar: data.work_calendar,
                pay_frequency: data.pay_frequency,
                bank_code: data.bank_code,
                branch_code: data.branch_code,
                bank_acc_num: data.bank_acc_num,
                bank_acc_name: data.bank_acc_name,
                id_type: data.id_type,
                id_num: data.id_num,
                visa_type: data.visa_type,
                user_status: data.user_status,
                remarks: data.remarks,
                AllowanceData: AllowanceArr

            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            console.log(res)

            if (res.status === 200) {
                setShowAlert(true)
                setAlertType("Success")
                setAlertMessage("User Data Updated Successfully.")
                preLoadDataContext.preLoadData.isUserList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);
            }


        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const uploadImageToAzureUsingREST = async () => {
        if (fileData.fileInput1.file === null) {
            console.error("No file selected for upload.");
            return;
        }

        const selectedFile = fileData.fileInput1.file;

        // Ensure the file size is less than the maximum allowed
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowAlert(true);
            setAlertType("Error");
            setAlertMessage("圖片檔案過大(5MB或以下)");
            return;
        }

        // Create a unique blob name
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = String(today.getFullYear()).slice(-2);
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const fileType = selectedFile.type.split('/')[1]; // Extract file extension
        const blobName = `C0211${year}${month}${day}${data.login_email}${minutes}.${fileType}`;

        // Construct the full Azure URL for uploading
        const containerName = "c0211";
        const azureUrl = `${AzureStorgeLink}/${containerName}/${blobName}`;
        const azureUploadUrl = `${azureUrl}?${sasToken}`;

        try {
            // Upload to Azure Blob Storage using PUT
            const response = await fetch(azureUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: selectedFile,
            });

            if (response.ok) {
                console.log("Upload successful:", azureUploadUrl);
                return azureUploadUrl;
            } else {
                const errorText = await response.text();
                console.error("Upload failed:", response.statusText, errorText);
                setAlertType("Error");
                setAlertMessage("圖片上傳失敗，請再試一次");
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setAlertType("Error");
            setAlertMessage("上傳過程中發生錯誤");
            setShowAlert(true);
        }
    };

    const ResetPassword = async () => {

        setLoading(true)

        const errors = {
            username: false,
            logincount: false,
            newpassword: isUserPassword.user_password === null || isUserPassword.user_password === undefined || isUserPassword.user_password === "",
            confirmpassword: isUserPassword.config_password === null || isUserPassword.config_password === undefined || isUserPassword.config_password === "",
        };

        setErrorField(errors);

        if (isUserPassword.user_password === null || isUserPassword.user_password === undefined || isUserPassword.user_password === "") {
            setAlertType("Error")
            setAlertMessage("Password is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (isUserPassword.config_password === null || isUserPassword.config_password === undefined || isUserPassword.config_password === "") {
            setAlertType("Error")
            setAlertMessage("Confirm Password is missing.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (isUserPassword.user_password !== isUserPassword.config_password) {
            setAlertType("Error")
            setAlertMessage("Password and Confirm Password does not match.")
            setShowAlert(true)
            setLoading(false)
            return;
        }

        try {
            if (data.login_id === null || data.login_id === undefined) {
                setAlertType("Error")
                setAlertMessage("User Name is missing.")
                setShowAlert(true)
                return;
            }

            const SHAPassword = CryptoJS.SHA256(data.login_id.toUpperCase() + isUserPassword.user_password).toString()

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/UpdateUserPassword`, {
                user_id: data.user_id,
                user_password: SHAPassword,
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                setShowAlert(true)
                setAlertType("Success")
                setAlertMessage("Password Reset Successfully.")
            }
        } catch (error) {
            console.log(error)
            setShowAlert(true)
            setAlertType("Error")
            setAlertMessage("Password Reset Failed.")
        } finally {
            setLoading(false)
        }

    }

    // Generalized function to handle file change for both inputs
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, inputId: string) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFileData((prevData) => ({
                ...prevData,
                [inputId]: {
                    file: selectedFile,
                    previewUrl: URL.createObjectURL(selectedFile),
                },
            }));
        }

        setPhotoPanel(false)
        setMessageBoxMessage('Are you sure you want to upload this photo?');
        setShowMessageBox(true)
        setMessageBoxType('Boolean')
        setPhotoAction('upload')
    };

    const handleFileRemove = (inputId: string) => {
        setFileData((prevData) => ({
            ...prevData,
            [inputId]: {
                file: null,
                previewUrl: null,
            },
        }));
    };

    const getUserData = async () => {
        const token = cookies.get('token')
        setLoading(true)

        if (isuserid == null) return;

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserList`, {
                user_id: isuserid
            }, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(function (res) {
                    console.log(res.data.User);
                    setData(res.data.User)
                })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    // Handle changes in the input fields
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (targetName: string, value: Date | null) => {
        if (value !== null) {
            const enteredYear = value.getFullYear();
            if (enteredYear < 1800 || enteredYear > 2100) {
                return;
            }

            const day = String(value.getDate()).padStart(2, '0'); // Pad the day to always be two digits
            const month = String(value.getMonth() + 1).padStart(2, '0'); // Pad the month (January is 0)
            const year = String(value.getFullYear());

            const formattedDate = `${year}-${month}-${day}`;

            setData(prevData => ({
                ...prevData,
                [targetName]: formattedDate // Converting the date to a string format like 'YYYY-MM-DD'
            }));
        }
    };

    const handleAllowaceDateChange = (targetName: string, value: Date | null) => {
        if (value !== null) {
            const enteredYear = value.getFullYear();
            if (enteredYear < 1800 || enteredYear > 2100) {
                return;
            }

            const day = String(value.getDate()).padStart(2, '0'); // Pad the day to always be two digits
            const month = String(value.getMonth() + 1).padStart(2, '0'); // Pad the month (January is 0)
            const year = String(value.getFullYear());

            const formattedDate = `${year}-${month}-${day}`;

            setShowAlert(true)
            setAlertType("Info")
            setAlertMessage("Changes date value the system will create a new record.")

            setData((prevData) => {
                const updatedAllowances = prevData.allowance ? [...prevData.allowance] : [];
                if (isallowanceid !== null) {
                    updatedAllowances[isallowanceid] = {
                        ...updatedAllowances[isallowanceid],
                        [targetName]: formattedDate,
                    };
                }

                return {
                    ...prevData,
                    allowance: updatedAllowances,
                };
            });
        }
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserPassword(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleAllowanceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, fieldName: string) => {
        const { value } = e.target;
        const updatedValue = value === 'Y' ? true : value === 'N' ? false : value; // Handle boolean or other types

        setData((prevData) => {
            const updatedAllowances = prevData.allowance ? [...prevData.allowance] : [];
            if (isallowanceid !== null) {
                updatedAllowances[isallowanceid] = {
                    ...updatedAllowances[isallowanceid],
                    [fieldName]: updatedValue,
                };
            }

            return {
                ...prevData,
                allowance: updatedAllowances,
            };
        });
    }

    const formatDate = (dateString: string): string => {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };

    const setToDefaultImage = () => {
        setData((prevData) => ({
            ...prevData,
            user_photo: ''
        }))
    }

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar />}
            {isShowMessageBox && <MessageBox MessageBoxOnOff={setShowMessageBox} messageType={isMessageBoxType} messageTitle={isMessageBoxTitiel} messageContent={isMessageBoxMessage} returnBooleanResult={setMessageBoxResult} />}


            {/* Start At here */}
            <div className={css.buttonContainer}>
                <button className={css.ReturnListBtn} onClick={() => setUserID(null)}>
                    <i className={`fi fi-br-angle-double-left ${css.returntoListIcon}`} />
                    Back To User List
                </button>
            </div>

            <section className={css.MainSection}>

                <div className={css.SubTitleDiv}>
                    <h1>Base Information</h1>
                    <div className={css.DisplayInformation}>
                        <i className="fi fi-sr-info"
                            onMouseEnter={() => setShowUploadInfo(true)}
                            onMouseLeave={() => setShowUploadInfo(false)}
                        />
                        {isshowuploadinfo && (
                            <div className={css.HideuploadInfoDiv}>
                                <div className={css.uploadInfoItem}>
                                    <label>Create Date</label>
                                    <input
                                        type="text"
                                        value={data?.create_on ?? ''}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Create By</label>
                                    <input
                                        type="text"
                                        value={data?.create_by ?? ''}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Update Data</label>
                                    <input
                                        type="text"
                                        value={data?.update_on ?? ''}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Update By</label>
                                    <input
                                        type="text"
                                        value={data?.update_by ?? ''}
                                    />
                                </div>

                            </div>
                        )}
                    </div>
                </div>

                <div className={css.baseInfoDiv}>
                    <div className={css.userImageDiv}>
                        {fileData.fileInput1.previewUrl && fileData.fileInput1.previewUrl.length >= 0 ? (
                            <>
                                <img src={fileData.fileInput1.previewUrl} alt="Product_main_Image" />
                                <button className={css.PhotoBtn} onClick={() => handleFileRemove('fileInput1')}>
                                    <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                    Remove
                                </button>
                            </>
                        ) : (
                            <>
                                {data?.user_photo ? (
                                    <img src={data.user_photo} alt="Product_main_Image" />
                                ) : (
                                    <img src={defaultpersonalImg} alt="Product_main_Image" />
                                )}
                                <div className={css.EditPhotoPanel} onClick={() => { setPhotoPanel(!isPhotoPanel) }}>
                                    <i className={`fi fi-rc-pencil ${css.EditPhotoIcon}`} />
                                    Edit
                                </div>
                                {isPhotoPanel && (
                                    <div className={css.PhotoOptionPanel}>
                                        <div className={css.UploadPhotoPanel}>
                                            <ul>
                                                <li>
                                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                                    Upload a photo
                                                </li>
                                                <li onClick={() => handleRemovePhotoMessage()}>
                                                    Remove photo
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {/* <button className={css.PhotoBtn} onClick={() => console.log("This function not ready.")}>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                    <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                    Edit
                                </button>
                                <button className={css.RemovePhotoBtn} onClick={() => setToDefaultImage()}>
                                    <i className={`fi fi-rr-refresh ${css.RemovePhotoIcon}`} />
                                </button> */}
                            </>
                        )}
                    </div>


                    <div className={css.baseInfo2}>
                        <div className={css.baseInfoItem}>
                            <label>Login ID</label>
                            <input
                                type="text"
                                name="login_id"
                                value={data.login_id}
                                onChange={handleInputChange}
                                disabled={true}
                            />
                        </div>
                        <div className={errorField.username ? css.errorbaseInfoItem : css.baseInfoItem}>
                            <label>Username</label>
                            <input
                                type="text"
                                name="user_name"
                                value={data.user_name}
                                onChange={handleInputChange} // Update user_name
                                maxLength={63}
                            />
                        </div>
                        <div className={css.baseInfoItem}>
                            <label>Permission Level</label>
                            <select name="authority_level" value={data?.authority_level} onChange={handleInputChange}>
                                <option value="factory-1">factory-1</option>
                                <option value="factory-2">factory-2</option>
                                <option value="factory-3">factory-3</option>
                                <option value="factory-4">factory-4</option>

                                <option value="office-1">office-1</option>
                                <option value="office-2">office-2</option>
                                <option value="office-3">office-3</option>
                                <option value="office-4">office-4</option>

                                <option value="sales-1">sales-1</option>
                                <option value="sales-2">sales-2</option>
                                <option value="sales-3">sales-3</option>
                                <option value="sales-4">sales-4</option>

                                <option value="manager-1">manager-1</option>
                                <option value="manager-2">manager-2</option>
                                <option value="manager-3">manager-3</option>
                                <option value="manager-4">manager-4</option>

                                <option value="admin">admin</option>
                            </select>
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Department</label>
                            <select name="department" value={data?.department} onChange={handleInputChange}>
                                <option value="FACTORY">工廠</option>
                                <option value="OFFICE">文職</option>
                                <option value="SALES">銷售</option>
                            </select>
                        </div>
                    </div>

                    <div className={css.baseInfo1}>
                        <div className={css.baseInfoItem}>
                            <label>Status</label>
                            <select name="user_status" value={data?.user_status} onChange={handleInputChange}>
                                <option value="Y">在職</option>
                                <option value="N">離職</option>
                            </select>
                        </div>
                        <div className={css.baseInfoItem}>
                            <label>Job Title</label>
                            <input type="text" name="job_title" value={data?.job_title} onChange={handleInputChange} maxLength={63} />
                        </div>
                        <div className={css.baseInfoItem}>
                            <label>Remark</label>
                            <textarea value={data?.remarks} cols={20} rows={5} name="remarks" onChange={handleInputChange} maxLength={255} />
                        </div>
                    </div>

                </div>

                <div className={css.SelectTypeInfoDiv}>
                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(1)}>
                            <i className={`fi fi-br-user ${css.SelectTypeIcon}`} />
                            Personal Information
                        </button>
                    </div>

                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(2)}>
                            <i className={`fi fi-rs-corporate-alt ${css.SelectTypeIcon}`} />
                            Onboarding information
                        </button>
                    </div>

                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(3)}>
                            <i className={`fi fi-rr-calculator-money ${css.SelectTypeIcon}`} />
                            Staff Allowance
                        </button>
                    </div>

                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(4)}>
                            <i className={`fi fi-rs-key ${css.SelectTypeIcon}`} />
                            Reset Password
                        </button>
                    </div>
                </div>

            </section>

            {ismode === 1 &&
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>Personal Information</h1>
                    </div>

                    <div className={css.personalInfoDiv}>
                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>Passport Surname</label>
                                <input
                                    type="text"
                                    name="passport_surname"
                                    value={data?.passport_surname}
                                    onChange={handleInputChange}
                                    maxLength={31}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Passport Given Name</label>
                                <input
                                    type="text"
                                    name="passport_given_name"
                                    value={data?.passport_given_name}
                                    onChange={handleInputChange}
                                    maxLength={63}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Passport Full Name</label>
                                <input
                                    type="text"
                                    name="passport_full_name"
                                    value={data?.passport_full_name}
                                    onChange={handleInputChange}
                                    maxLength={127}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Gender</label>
                                <select name="gender" value={data?.gender} onChange={handleInputChange}>
                                    <option value="M">男 | M</option>
                                    <option value="F">女 | F</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Date Of Birth</label>
                                {/* <input
                                    type="date"
                                    name="date_of_birth"
                                    value={data?.date_of_birth}
                                    onChange={handleInputChange}
                                /> */}
                                <DatePicker
                                    value={data?.date_of_birth ? new Date(data.date_of_birth) : null}
                                    onChange={(value) => handleDateChange("date_of_birth", value as Date | null)}
                                    format="dd-MM-yyyy"
                                    className={css.DatePicker}
                                    clearIcon={null}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder='dd'
                                    autoFocus={false}
                                />
                            </div>
                        </div>

                        <div className={css.personalInfoItem}>

                            <div className={css.baseInfoItem}>
                                <label>ID Type</label>
                                <select name="id_type" value={data?.id_type} onChange={handleInputChange}>
                                    <option value="Citizen">Citizen</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>ID Number</label>
                                <input
                                    type="text"
                                    name="id_num"
                                    value={data?.id_num}
                                    onChange={handleInputChange}
                                    maxLength={15}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Marital Status</label>
                                <select name="marital_status" value={data?.marital_status} onChange={handleInputChange}>
                                    <option value="S">單身</option>
                                    <option value="M">已婚</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Race</label>
                                <select name="race" value={data?.race} onChange={handleInputChange}>
                                    <option value="Chinese">中國人</option>
                                    <option value="Vietnamese">越南人</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Religion</label>
                                <select name="religion" value={data?.religion} onChange={handleInputChange}>
                                    <option value="Not Applicable">不適用</option>
                                </select>
                            </div>

                        </div>

                        <div className={css.personalInfoItem}>

                            <div className={css.baseInfoItem}>
                                <label>Nationality</label>
                                <input
                                    type="text"
                                    name="nationality"
                                    value={data?.nationality}
                                    onChange={handleInputChange}
                                    maxLength={31}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Visa Type</label>
                                <select name="visa_type" value={data?.visa_type} onChange={handleInputChange}>
                                    <option value="Permanent Residency">永久居留權</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Personal Email</label>
                                <input
                                    type="text"
                                    name="personal_email"
                                    value={data?.personal_email}
                                    onChange={handleInputChange}
                                    maxLength={127}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Address</label>
                                <textarea value={data?.address} cols={20} rows={4} name="address" onChange={handleInputChange} maxLength={255} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Post Code</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    value={data?.postal_code}
                                    onChange={handleInputChange}
                                    maxLength={15}
                                />
                            </div>

                        </div>

                    </div>

                </section>
            }

            {ismode === 2 &&
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>Onboarding Information</h1>
                    </div>

                    <div className={css.personalInfoDiv}>

                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>Hire Date</label>
                                {/* <input
                                    type="date"
                                    name="hire_date_1"
                                    value={data?.hire_date_1}
                                    onChange={handleInputChange}
                                /> */}
                                <DatePicker
                                    value={data?.hire_date_1 ? new Date(data.hire_date_1) : null}
                                    onChange={(value) => handleDateChange("hire_date_1", value as Date | null)}
                                    format="dd-MM-yyyy"
                                    className={css.DatePicker}
                                    clearIcon={null}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder='dd'
                                    autoFocus={false}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Work Email</label>
                                <input
                                    type="text"
                                    name="work_email"
                                    value={data?.work_email}
                                    onChange={handleInputChange}
                                    maxLength={127}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Termination Date</label>
                                {/* <input
                                    type="date"
                                    name="termination_date"
                                    value={data?.termination_date}
                                    onChange={handleInputChange}
                                /> */}
                                <DatePicker
                                    value={data?.termination_date ? new Date(data.termination_date) : null}
                                    onChange={(value) => handleDateChange("termination_date", value as Date | null)}
                                    format="dd-MM-yyyy"
                                    className={css.DatePicker}
                                    clearIcon={null}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder='dd'
                                    autoFocus={false}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Termination Reason</label>
                                <textarea value={data?.termination_reason} cols={20} rows={3} name="termination_reason" onChange={handleInputChange} maxLength={255} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Job Comment</label>
                                <textarea value={data?.job_comment} cols={20} rows={3} name="job_comment" onChange={handleInputChange} maxLength={511} />
                            </div>
                        </div>

                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>Employment Type</label>
                                <select name="employment_type" value={data?.employment_type} onChange={handleInputChange}>
                                    <option value="LFT">Local Full Time</option>
                                    <option value="LPT">Local Part Time</option>
                                    <option value="LFL">Local Freelance</option>
                                    <option value="OFL">Overseas Freelance</option>
                                    <option value="OFT">Overseas Full Time</option>
                                    <option value="OPL">Overseas Part Time</option>
                                    <option value="">Unknown</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Cost Center</label>
                                <select name="cost_center" value={data?.cost_center} onChange={handleInputChange}>
                                    <option value="SHY">SHY</option>
                                    <option value="D">OTH</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Notice Period</label>
                                <select name="notice_period" value={data?.notice_period} onChange={handleInputChange}>
                                    <option value="normal">一般</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Work Calendar</label>
                                <select name="notice_period" value={data?.work_calendar} onChange={handleInputChange}>
                                    <option value="5">5</option>
                                    <option value="5.5">5.5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Pay Frequency</label>
                                <select name="user_status" value={data?.pay_frequency} onChange={handleInputChange}>
                                    <option value="D">時薪</option>
                                    <option value="D">日薪</option>
                                    <option value="M">月薪</option>
                                </select>
                            </div>

                            <div className={errorField.logincount ? css.errorbaseInfoItem : css.baseInfoItem}>
                                <label>Login Count</label>
                                <input
                                    type="text"
                                    name="login_count"
                                    value={data?.login_count}
                                    onChange={handleInputChange}
                                />
                            </div>


                        </div>

                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>Bank Code</label>
                                <select name="bank_code" value={data?.bank_code} onChange={handleInputChange}>
                                    {bankList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.value} | {item.displayName}</option>
                                    ))}
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Branch Code</label>
                                <input
                                    type="text"
                                    name="branch_code"
                                    value={data?.branch_code}
                                    onChange={handleInputChange}
                                    maxLength={3}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Bank Account Number</label>
                                <input
                                    type="text"
                                    name="bank_acc_num"
                                    value={data?.bank_acc_num}
                                    onChange={handleInputChange}
                                    maxLength={15}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Bank Account Name</label>
                                <input
                                    type="text"
                                    name="bank_acc_name"
                                    value={data?.bank_acc_name}
                                    onChange={handleInputChange}
                                    maxLength={127}
                                />
                            </div>

                        </div>

                    </div>
                </section>
            }

            {ismode === 3 &&
                <section className={css.SubSection}>

                    <button className={css.AddAllowanceBtn} onClick={() => addNewAllowance()}>
                        <i className={`fi fi-br-plus ${css.AddAllowanceIcon}`} />
                        Add Staff Allowance
                    </button>


                    <div className={css.UserAllowanceList}>
                        <table className={css.UserTable} id="UserAllowanceTable">
                            <tr>
                                {awardList.map((item, index) => (
                                    <th key={index}>{item.displayName}</th>
                                ))}
                                <th>Edit</th>
                            </tr>
                            <tbody>
                                {data?.allowance != null && data?.allowance.length > 0 &&
                                    data?.allowance.map((item, index) => (
                                        <tr key={index}>
                                            {awardList.map((award, awardIndex) => (
                                                // <td key={awardIndex}>
                                                //     {typeof item[award.value as keyof typeof item] === 'boolean'
                                                //         ? item[award.value as keyof typeof item] ? 'Yes' : 'No'
                                                //         : item[award.value as keyof typeof item]}
                                                // </td>
                                                <td key={awardIndex}>
                                                    {award.value === 'date_fm' && item[award.value as keyof typeof item]
                                                        ? formatDate(item[award.value as keyof typeof item] as string)
                                                        : typeof item[award.value as keyof typeof item] === 'boolean'
                                                            ? item[award.value as keyof typeof item]
                                                                ? 'Yes'
                                                                : 'No'
                                                            : item[award.value as keyof typeof item]}
                                                </td>
                                            ))}
                                            <td className={css.EditUserBtn}>
                                                <CreateOutline
                                                    color={'#032654'}
                                                    height="30px"
                                                    width="30px"
                                                    onClick={() => setAllowanceID(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </section>
            }

            {ismode === 3 && data.allowance !== null && data.allowance !== undefined && isallowanceid !== null && data.allowance[isallowanceid] !== undefined && (
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>Edit Staff Allowance</h1>
                    </div>

                    <div className={css.UserAllowanceInfoDiv}>
                        <div className={css.UserAllowanceInfoItem}>

                            {/* {awardList.map((award, index) => {
                                const value = data?.allowance?.[isallowanceid]?.[award.value as keyof IC0211UserAllowance];

                                // Only render if the value is a string
                                if (typeof value === 'string' || value === null) {
                                    return (
                                        <div className={css.baseInfoItem} key={index}>
                                            <label>{award.displayName}</label>

                                            <input
                                                type="text"
                                                name={award.value}
                                                value={value ?? ''}
                                                onChange={(e) => handleAllowanceChange(e, award.value)}
                                            />
                                        </div>
                                    );
                                }

                                return null; // Do not render non-boolean fields
                            })} */}

                            <div className={css.baseInfoItem}>
                                <label>日期</label>
                                {/* <input
                                    type="text"
                                    name="date_fm"
                                    value={data.allowance?.[isallowanceid].date_fm ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "date_fm")}
                                /> */}
                                <DatePicker
                                    value={
                                        data.allowance?.[isallowanceid]?.date_fm
                                            ? new Date(data.allowance?.[isallowanceid]?.date_fm as string)
                                            : null
                                    }
                                    onChange={(value) => handleAllowaceDateChange("date_fm", value as Date | null)}
                                    format="dd-MM-yyyy"
                                    className={css.DatePicker}
                                    clearIcon={null}
                                    yearPlaceholder="yyyy"
                                    monthPlaceholder="mm"
                                    dayPlaceholder='dd'
                                    autoFocus={false}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>店鋪(1)</label>
                                <input
                                    type="text"
                                    name="desc_01"
                                    value={data.allowance?.[isallowanceid].desc_01 ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "desc_01")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>店鋪(2)</label>
                                <input
                                    type="text"
                                    name="desc_02"
                                    value={data.allowance?.[isallowanceid].desc_02 ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "desc_02")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>店鋪(3)</label>
                                <input
                                    type="text"
                                    name="desc_03"
                                    value={data.allowance?.[isallowanceid].desc_03 ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "desc_03")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>店鋪(4)</label>
                                <input
                                    type="text"
                                    name="desc_04"
                                    value={data.allowance?.[isallowanceid].desc_04 ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "desc_04")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>店鋪(5)</label>
                                <input
                                    type="text"
                                    name="desc_05"
                                    value={data.allowance?.[isallowanceid].desc_05 ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "desc_05")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>薪金</label>
                                <input
                                    type="text"
                                    name="salary"
                                    value={data.allowance?.[isallowanceid].salary ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "salary")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>出薪頻率</label>
                                <select
                                    name="pay_freq"
                                    value={data.allowance?.[isallowanceid].pay_freq ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "pay_freq")}
                                >
                                    <option value="H">時薪 | H</option>
                                    <option value="D">日薪 | D</option>
                                    <option value="D">月薪 | M</option>
                                </select>

                            </div>

                            <div className={css.baseInfoItem}>
                                <label>狀態</label>
                                <select
                                    name="award.value"
                                    value={data.allowance?.[isallowanceid].allowance_status ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "allowance_status")}
                                >
                                    <option value="Yes">開啟</option>
                                    <option value="No">關閉</option>
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>附註</label>
                                <input
                                    type="text"
                                    name="remarks"
                                    value={data.allowance?.[isallowanceid].remarks ?? ''}
                                    onChange={(e) => handleAllowanceChange(e, "remarks")}
                                />
                            </div>


                            <div className={css.uploadInfoDiv}>
                                <div className={css.uploadInfoItem}>
                                    <label>Create Data</label>
                                    <input
                                        type="text"
                                        value={data?.allowance?.[isallowanceid].create_on ?? ''}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Create By</label>
                                    <input
                                        type="text"
                                        value={data?.allowance?.[isallowanceid].create_by ?? ''}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Update Data</label>
                                    <input
                                        type="text"
                                        value={data?.allowance?.[isallowanceid].update_on ?? ''}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Update By</label>
                                    <input
                                        type="text"
                                        value={data?.allowance?.[isallowanceid].update_by ?? ''}
                                    />
                                </div>

                            </div>

                        </div>

                        <div className={css.UserAllowanceInfoItem}>

                            {/* Map over awardList and handle only boolean fields */}
                            {awardList.map((award, index) => {
                                const value = data?.allowance?.[isallowanceid]?.[award.value as keyof IC0211UserAllowance];

                                // Only render if the value is a boolean
                                if (typeof value === 'boolean') {
                                    return (
                                        <div className={css.baseInfoItem} key={index}>
                                            <label>{award.displayName}</label>

                                            <select
                                                name={award.value}
                                                value={value ? 'Y' : 'N'}
                                                onChange={(e) => handleAllowanceChange(e, award.value)}
                                            >
                                                <option value="Y">開啟</option>
                                                <option value="N">關閉</option>
                                            </select>
                                        </div>
                                    );
                                }

                                return null; // Do not render non-boolean fields
                            })}

                        </div>
                        <div className={css.UserAllowanceBtnContainer}>
                            <button className={css.BottomBtn} onClick={() => RemoveUserAllowance(isallowanceid)}>
                                <i className="fi fi-br-cross" />
                                Remove
                            </button>
                        </div>
                    </div>
                </section>
            )}

            {ismode === 4 && (
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>Update User Password</h1>
                    </div>

                    <div className={css.RestPasswordDiv}>
                        <div className={css.RestPasswordInfoItem}>
                            <div className={errorField.newpassword ? css.errorbaseInfoItem : css.baseInfoItem}>
                                <label>New Password</label>
                                <input
                                    type="password"
                                    name="user_password"
                                    value={isUserPassword?.user_password}
                                    onChange={handleChangePassword}
                                />
                            </div>

                            <div className={errorField.confirmpassword ? css.errorbaseInfoItem : css.baseInfoItem}>
                                <label>Config Password</label>
                                <input
                                    type="password"
                                    name="config_password"
                                    value={isUserPassword?.config_password}
                                    onChange={handleChangePassword}
                                />
                            </div>
                        </div>

                        <div className={css.UserAllowanceBtnContainer}>
                            <button className={css.BottomBtn} onClick={() => ResetPassword()}>
                                <i className="fi fi-rr-password-lock" />
                                Confirm Update Password
                            </button>
                        </div>
                    </div>
                </section>

            )}

            {/* Bottom  Control Buttons */}
            <section className={css.BottomSection}>
                <div className={css.SaveBtnContainer}>
                    {/* <button className={css.BottomBtn} onClick={() => setMode(4)}>
                        <i className="fi fi-rs-key" />
                        Reset Password
                    </button> */}

                    <button className={css.BottomBtn} onClick={saveUserData}>
                        <i className="fi fi-rs-disk" />
                        Save
                    </button>
                </div>
            </section>

        </>

    )
    return content

}

export default C0211EditUser