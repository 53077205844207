import { Helmet, HelmetProvider } from "react-helmet-async" 
import { Cookies } from "react-cookie"
import { useContext, useEffect } from "react"
import css from '../css/TestingLayout.module.css'
//Pages
import { Outlet } from "react-router-dom"
import FootagePage from "../Components/Footage"
import DashVNavBar from "../Components/DashVNavBar"
import { SystemContext } from "../Class/SystemPara"
import { FetchSystemData } from "../Interface/Const"
import { UserContext } from "../Class/UserPara"


const cookies = new Cookies()

const TestingLayout = () => {
    const UserDate = useContext(UserContext)
    const systemContext = useContext(SystemContext);

    useEffect(() => {
        FetchSystemData(systemContext)
        .then(
            CheckLoginStatus
        )
        console.log(systemContext.systemData.isLogin)
    }, []);

    const CheckLoginStatus = () => {
        if (systemContext.systemData.isLogin !== true) {
            // window.location.replace('https://www.wisetechinterglobe.com.hk/');
            console.log(systemContext.systemData.isLogin)
          }
          console.log("Login Status :" + systemContext.systemData.isLogin)
    }


    const content = (
        <body>
            <HelmetProvider>
                <div className={css.MainDiv}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Wisetechinterglobe Dash</title>
                    </Helmet>

                    <section className={css.NavBar}>
                        <DashVNavBar />
                    </section>

                    <section className={css.Outlet}>
                        <Outlet />
                    </section>
                    
                                      
                </div>
                <FootagePage />  
            </HelmetProvider>
        </body>
    
    )
    return content
}
export default TestingLayout