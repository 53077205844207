import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useContext, useEffect, useState } from "react";
import { c0211EditUserProps, c0211UserListProps, IC0211UserAllowance, IC0211UserList } from "../../../Interface/interface"
import { CreateOutline } from 'react-ionicons';
import axios from "axios";
import { Cookies } from 'react-cookie';
import css from '../../../css/C0211/EditUserData.module.css'
import defaultpersonalImg from '../../../global/C0211/DefaultPhoto.jpg'
import DeafultLoadingBar from "../../LoadingBar/DefaultLoadingBar";
import AlertMessage from "../../LoadingBar/AlertMessage";
import Login from "../../Login";
import { config } from "process";
import CryptoJS from 'crypto-js';
import { PreLoadingContext } from "../../../Class/C0211PreLoadingData";
import { UserContext } from "../../../Class/UserPara";
import { C0211PreLoadingData } from "../../../Interface/Const";
import { use } from "i18next";
const cookies = new Cookies()

const token = cookies.get('token')

const C0211InserUser: React.FC<c0211UserListProps> = ({ setUserID }) => {

    const userContext = useContext(UserContext);
    const preLoadDataContext = useContext(PreLoadingContext);


    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [isloading, setLoading] = useState<boolean>(false);

    const [iscustomUserID, setCustomUserID] = useState<boolean>(true);
    const [isLoginEmail, setLoginEmail] = useState<string>('');
    const [isUsername, setUsername] = useState<string>('');
    const [isUserPassword, setUserPassword] = useState<string>('');
    const [isAuthority, setAuthority] = useState<string>('factory-1');
    const [isQRCode, setQRCode] = useState<string>('');
    const [isRemark, setRemark] = useState<string>('');

    const [errorField, setErrorField] = useState({
        username: false,
        password: false,
    });

    useEffect(() => {
        if (isShowAlert === true) {
            setTimeout(() => {
                setShowAlert(false)
            }, 8000)
        }
    }, [isShowAlert])

    useEffect(() => {
        console.log(errorField)
    }, [errorField])

    const handleInputChange = (field: string, value: string) => {
        if (field === "username") setUsername(value);
        if (field === "password") setUserPassword(value);
    
        setErrorField((prevState) => ({
            ...prevState,
            [field]: false,
        }));
    };

    const AddNewUser = async () => {
        setLoading(true)

        const errors = {
            username: isUsername === "",
            password: isUserPassword === "",
        };
    
        setErrorField(errors);

        if (isUsername === '') {
            setAlertType('Error')
            setAlertMessage('Please fill in the Username')
            setShowAlert(true)
            setLoading(false)
            return
        }

        if (isUserPassword === '') {
            setAlertType('Error')
            setAlertMessage('Please fill in the Password')
            setShowAlert(true)
            setLoading(false)
            return
        }

        if (isAuthority === '') {
            setAlertType('Error')
            setAlertMessage('Please fill in the Authority Level')
            setShowAlert(true)
            setLoading(false)
            return
        }

        try {

            let payload: any = {
                user_name: isUsername,
                user_password: isUserPassword,
                authority_level: isAuthority,
                qr_login: isQRCode,
                user_status: "Y",
                remarks: isRemark // Fixed typo from isRemark to remarks
            };

            if (isLoginEmail.length > 0) {
                payload.login_email = isLoginEmail;
            } else {
                payload.login_email = null;
            }

            console.log(payload)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/InsertUserList`, 
                payload
            , {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status >= 200 && res.status < 300) {
                setAlertType('Success')
                setAlertMessage('User has been added successfully')
                setShowAlert(true)
                setUserID(null)
                preLoadDataContext.preLoadData.isUserList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }


    const content = (
            <>
                {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
                {isloading && <DeafultLoadingBar />}

                <div className={css.buttonContainer}>
                    <button className={css.ReturnListBtn} onClick={() => setUserID(null)}>
                        <i className={`fi fi-br-angle-double-left ${css.returntoListIcon}`} />
                        Back To User List
                    </button>
                </div>

                <section className={css.MainSection}>

                    <section className={css.SubSection}>
                        <div className={css.SubInfoTitleDiv}>
                            <h1>User Login Information</h1>
                        </div>

                        <div className={css.personalInfoDiv}>
                            <div className={css.personalInfoItem}>
                                <div className={css.baseInfoItem}>
                                    <label>Staff Code</label>
                                    <input
                                        type="text"
                                        value={isLoginEmail || ''}
                                        onChange={(e) => setLoginEmail(e.target.value)}
                                        placeholder="Please fill in if you want to use custom staff code."
                                    />
                                </div>

                                <div className={errorField.username ? css.errorbaseInfoItem : css.baseInfoItem}>
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        value={isUsername}
                                        onChange={(e) => handleInputChange("username", e.target.value)}
                                    />
                                </div>

                                <div className={errorField.password ? css.errorbaseInfoItem : css.baseInfoItem}>
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        value={isUserPassword}
                                        onChange={(e) => handleInputChange("password", e.target.value)}
                                    />
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Permission Level</label>
                                    <select value={isAuthority} onChange={(e) => setAuthority(e.target.value)}>
                                        <option value="factory-1">factory-1</option>
                                        <option value="factory-2">factory-2</option>
                                        <option value="factory-3">factory-3</option>
                                        <option value="factory-4">factory-4</option>

                                        <option value="office-1">office-1</option>
                                        <option value="office-2">office-2</option>
                                        <option value="office-3">office-3</option>
                                        <option value="office-4">office-4</option>

                                        <option value="sales-1">sales-1</option>
                                        <option value="sales-2">sales-2</option>
                                        <option value="sales-3">sales-3</option>
                                        <option value="sales-4">sales-4</option>

                                        <option value="manager-1">manager-1</option>
                                        <option value="manager-2">manager-2</option>
                                        <option value="manager-3">manager-3</option>
                                        <option value="manager-4">manager-4</option>

                                        <option value="admin">admin</option>
                                    </select>
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>QR Code</label>
                                    <input
                                        type="text"
                                        value={isQRCode}
                                        onChange={(e) => setQRCode(e.target.value)}
                                    />
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Remark</label>
                                    <input
                                        type="text"
                                        value={isRemark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                    </section>

                    <section className={css.BottomSection}>
                        <div className={css.SaveBtnContainer}>
                            {/* <button className={css.BottomBtn} onClick={() => setMode(4)}>
                        <i className="fi fi-rs-key" />
                        Reset Password
                    </button> */}

                            <button className={css.BottomBtn} onClick={() => AddNewUser()}>
                                <i className="fi fi-br-user-add" />
                                Add User
                            </button>
                        </div>
                    </section>

                </section>
            </>
        )
        return content
    }

    export default C0211InserUser