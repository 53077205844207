import { CreateOutline, RefreshOutline } from 'react-ionicons'
import css from '../css/ShopList.module.css'


const ShpoList = () => {
    const content = (
        <>
            <section className={css.TopSection}>
                <div className={css.ControlBar}>
                    <p className={css.Title}>Shop List</p>
                    <div className={css.RefreshBtn}>
                    <RefreshOutline
                        color={'#00000'}
                        height="35px"
                        width="35px"
                    />
                    </div>
                </div>

                <div className={css.ListUser}>
                    <table className={css.UserTable} id="UserListTable">
                        <tr>
                            <th>ID</th>
                            <th>Shop Code</th>
                            <th>Shop Type</th>
                            <th>Shop Name</th>
                            <th>Brief Name</th>
                            <th>GPS Latitude</th>
                            <th>GPS Longitude</th>
                            <th>Shop Group</th>
                            <th>Shop Region</th>
                            <th>Status</th>
                            <th>Update Reocrd</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>SHY-001</td>
                            <td>S</td>
                            <td>香港站</td>
                            <td>香港站</td>
                            <td>22.284559</td>
                            <td>114.158252</td>
                            <td>A</td>
                            <td>九龍西</td>
                            <td>Y</td>
                            
                            <td className={css.EditUserBtn}><CreateOutline
                                color={'#032654'}
                                height="30px"
                                width="30px"
                            /></td>
                        </tr>

                    </table>
                </div>
            </section>
        </>
    )
    return content
}
export default ShpoList