import css from '../../css/C0533/SkillTest.module.css'

import SkillLevelItem from '../../global/C533/SkillLevelItem.jpg'
import LevelBoot from '../../global/C533/level.book.jpg'

const SkillTest = () => {
    const content = (
        <section className={css.MainSection}>
            <div className={css.Title}>
                <p>花式跳繩技術評級考核</p>
            </div>

            <div className={css.MainDiv}>
                <div className={css.Certificate}>
                    <img src={LevelBoot} alt="Skill Level Certificate" />
                </div>
                <div className={css.Item}>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdWk3HjCEF1q9lqea1l4r3nxITcMBY6aE9_nmpNTW9wVdNEQA/viewform">
                        <div className={css.form}>
                            <p>花式跳繩技術評級證書申請表</p>
                        </div>
                    </a>
                    <div className={css.ItemImage}>
                        <img src={SkillLevelItem} alt="Skill Item" />
                    </div>
                </div>
            </div>

            <div className={css.DescDiv}>
                <p>本港花式跳繩運動發展愈來愈成熟普及，為方便有清晰及明確的技術指標。本社設立技術評級考核，達到唔同程度會有相應章別等別證明，讓學員能更有目標提升個人技術。完成考核後，可向本社申請資格證明。</p>
            </div>

        </section>
    )
    return content
}

export default SkillTest