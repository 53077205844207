import css from '../../css/C0533/NavBar.module.css'

// import Logo
import Logo from '../../global/C533/HomePageLgog.png'
import FaceBook from '../../global/C533/FaceBook.png'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Hamburger from 'hamburger-react'
import MediaNavBar from './MediaNavBar'

const NavBar = () => {

    const [isOpen, setOpen] = useState(false)

    const toggleNavBar = () => {
        console.log("On Click")
        setOpen(!isOpen)
    }

    const content = (
        <section className={css.MainSection} id="home">
            <div className={css.LogoDiv}>
                <Link to="/C0533">
                    <img className={css.LogoImg} src={Logo} alt="Logo" />
                </Link>

                <p className={css.ContectUsTxt}>Follow Us: </p>
                <a href='https://www.facebook.com/HKRSU'>
                    <img className={css.FaceBookImg} src={FaceBook} alt="Logo" />
                </a>
            </div>

            <div className={css.MediaMenu}>
                <Hamburger toggled={isOpen} toggle={setOpen} size={30} />
            </div>
            <div className={css.NavbarMenu} id="navMenu">
                <div className={css.NavbarMenuSize}>

                    <div className={css.dropdown}>
                        <Link to={"/C0533"}>Home</Link>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn}>About Us
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={css.dropdown_content}>
                            <Link to={"/C0533/about-hkrsu"}>本社介紹</Link>
                            <Link to={"/C0533/Our-Crew"}>教練團隊</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn}>Classes
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={css.dropdown_content}>
                            <Link to={"/C0533/course"}>學校課程</Link>
                            <Link to={"/C0533/Aces"}>Mini Aces／Aces</Link>
                            <Link to={"/C0533/Course"}>Summer Coures</Link>
                            <Link to={"/C0533/Course"}>Open Class (常規班)</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/performance_team"}>Performances</Link>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn}>Competitions
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={css.dropdown_content}>
                            <Link to={"/C0533/Union_Cup"}>聯校盃全港跳繩競速錦標賽</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <button className={css.dropbtn}>Awards
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className={css.dropdown_content}>
                            <Link to={"/C0533/AWADRS-International"}>國際賽事獎項</Link>
                            <Link to={"/C0533/AWARDS"}>本地賽事獎項</Link>
                        </div>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/about_rope"}>Sales</Link>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/contact-us"}>Contact Us</Link>
                    </div>

                    <div className={css.dropdown}>
                        <Link to={"/C0533/Skill_Test"}>Skill Test</Link>
                    </div>

                </div>
            </div>

            {isOpen ? (<MediaNavBar toggleNavBar={toggleNavBar} />) : null}


        </section >
    )
    return content
}

export default NavBar
