import Aces from "../../../Components/C0533/Aces"

const AcesPage = () => {
    const content = (
        <>
        <Aces />
        </>
    )
    return content
}
export default AcesPage