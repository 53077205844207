import { IC0217DownloadReportProps } from "../../../Interface/interface"
import css from "../../../css/C0217/DownloadReport.module.css"


const C0217DownloadReportFilter: React.FC<IC0217DownloadReportProps> = ({
    setShowAlert,
    setAlertType,
    setAlertMessage,
    setLoading,
    setStage,
    isFilterData,
    handleInputChange,
    handleCheckboxChange,
    handleDateChange
}) => {

    const setDateFilterByList = [
        { value: 1, displayName: "Billing Date" },
        { value: 2, displayName: "File Name" },
    ]

    const PlatformType = [
        { value: "foodpanda", displayName: "FoodPanda" },
        { value: "deliveroo", displayName: "Deliveroo" },
        { value: "keeta", displayName: "Keeta" },
    ]

    const GoToNextStage = () => {
        if (isFilterData?.StartDate === "" || isFilterData?.StartDate === undefined || isFilterData?.StartDate === null || isFilterData?.StartDate.length === 0 || isFilterData?.StartDate === "undefined-undefined-") {
            setShowAlert && setShowAlert(true)
            setAlertType && setAlertType("Error")
            setAlertMessage && setAlertMessage("Start Date is required")
            return
        }

        if (isFilterData?.EndDate === "" || isFilterData?.EndDate === undefined || isFilterData?.EndDate === null || isFilterData?.EndDate.length === 0 || isFilterData?.EndDate === "undefined-undefined-") {
            setShowAlert && setShowAlert(true)
            setAlertType && setAlertType("Error")
            setAlertMessage && setAlertMessage("End Date is required")
            return
        }

        const startDate = new Date(isFilterData?.StartDate.split("-").reverse().join("-"));
        const endDate = new Date(isFilterData?.EndDate.split("-").reverse().join("-"));

        if (startDate > endDate) {
            console.log(startDate, endDate)
            setShowAlert && setShowAlert(true)
            setAlertType && setAlertType("Error")
            setAlertMessage && setAlertMessage("Start Date must be less than End Date")
            return
        }

        if (isFilterData?.PlatformType?.length === 0 || isFilterData?.PlatformType === undefined) {
            setShowAlert && setShowAlert(true)
            setAlertType && setAlertType("Error")
            setAlertMessage && setAlertMessage("Platform Type is required")
            return
        }
        
        setStage && setStage(1)
    }

    const content = (
        <>
            <section className={css.MainSection}>

                <div className={css.SelectionFilterDiv}>
                    <div className={css.FilterTitleDiv}>
                        <p>Report Filter</p>
                    </div>

                    <div className={css.MainFilterDiv}>

                        <div className={css.ReportFilterDiv}>

                            <div className={css.baseInfoItem}>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    name="StartDate"
                                    value={isFilterData?.StartDate ? isFilterData?.StartDate.split("-").reverse().join("-") : ""}
                                    onChange={(e) => handleDateChange && handleDateChange(e, "StartDate")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    name="EndDate"
                                    value={isFilterData?.EndDate ? isFilterData?.EndDate.split("-").reverse().join("-") : ""}
                                    onChange={(e) => handleDateChange && handleDateChange(e, "EndDate")}
                                />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>Date Filter By</label>
                                <select name="DateFilterBy" value={isFilterData?.DateFilterBy ?? ''} onChange={handleInputChange}>
                                    {setDateFilterByList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.displayName}</option>
                                    ))}
                                </select>
                            </div>

                            <div className={css.baseInfoItem}>
                                <fieldset className={css.TypeFieldset}>
                                    <legend>Platform Type</legend>
                                    {PlatformType.map((PFT, index) => (
                                        <div key={PFT.value} className={css.ProductTypeFieldset}>
                                            <input
                                                type="checkbox"
                                                id={PFT.value}
                                                name="PlatformType"
                                                value={PFT.value}
                                                checked={isFilterData?.PlatformType?.includes(PFT.value) || false}
                                                onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'PlatformType')}
                                            />
                                            <label htmlFor={PFT.value} className={css.Typelable}>
                                                {PFT.displayName}
                                            </label>
                                        </div>
                                    ))}
                                </fieldset>
                            </div>

                        </div>

                        {/* <div className={css.FilterLineDiv} />

                        <div className={css.ReportTypeDiv}>

                            <div className={css.baseInfoItem}>
                                <label>Report Type</label>
                                <select name="ReportType" value={isFilterData?.ReportType ?? ''} onChange={handleInputChange}>
                                    {ReportType.map((item, index) => (
                                        <option key={index} value={item.value}>{item.displayName}</option>
                                    ))}
                                </select>
                            </div>

                            {isFilterData?.ReportType !== "Summary Report" && (
                                <div className={css.baseInfoItem}>
                                    <fieldset className={css.TypeFieldset}>
                                        <legend>Please confirm raw data report contain as below</legend>
                                        {RawDataReportType.map((RDRT, index) => (
                                            <div key={RDRT.value} className={css.ProductTypeFieldset}>
                                                <input
                                                    type="checkbox"
                                                    id={RDRT.value}
                                                    name="RawDataReportType"
                                                    value={RDRT.value}
                                                    checked={isFilterData?.RawDataReportType?.includes(RDRT.value) || false}
                                                    onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'RawDataReportType')}
                                                />
                                                <label htmlFor={RDRT.value} className={css.Typelable}>
                                                    {RDRT.displayName}
                                                </label>
                                            </div>
                                        ))}
                                    </fieldset>
                                </div>
                            )}


                        </div> */}
                    </div>
                </div>

                <div className={css.SelectionBtnDiv}>
                    <button className={css.SelectionBtn} onClick={GoToNextStage}>
                        <i className="fi fi-rr-check" />
                        Next Step
                    </button>
                </div>
            </section>
        </>
    )
    return content
}

export default C0217DownloadReportFilter