import { useSearchParams } from 'react-router-dom';

//page
import Function from "../Components/Function";

function DashHomePage() {

  const [searchParams] = useSearchParams();
  const fName = searchParams.get('FName');

  return (
    <div>
      <Function FName={fName}/>
    </div>
  );
}

export default DashHomePage;
