import { useEffect, useState } from 'react'
import css from '../../css/C0533/Course.module.css'

import { IC0553WebsiteImage } from '../../Interface/interface'
import axios from 'axios'

const Course = () => {

    const [data, setData] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        goGetDataJson();
    }, [])

    const goGetDataJson = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "Course",
                item_section_code: "Course_"
            });

            if (res.status === 200) {
                setData(res.data)
                console.log(data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const getMatchImg = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        const urlpath = data[indexID].item_pic_url;
        const urldesc = data[indexID].item_desc;
        return <img src={urlpath} alt={urldesc} />;
    };


    const getMatchLink1 = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        const urlpath = data[indexID].item_link_url;
        return <a href={urlpath}>  點擊：(進行網上報名) </a>;
    };

    const getMatchLink2 = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        const urlpath = data[indexID].item_link_url;
        return <a href={urlpath}> 點擊：(時間表下載) </a>;
    };

    const content = (
        <section className={css.MainSection}>
            <div className={css.CourseDiv}>
                <div className={css.CourseTitleDiv}>
                    <p>葵興 Studio跳繩班課程</p>
                </div>
                <div className={css.LinkDiv}>
                    <p>課程 資料：</p>
                    {getMatchLink1(data, "Course_03")}
                    {getMatchLink2(data, "Course_04")}
                </div>
                <div className={css.CourseImage}>
                    {getMatchImg(data, "Course_01")}
                </div>
                {/* <div className={css.DescDiv}>
                    <p>好好為小朋友安排好跳繩課程啦！就算唔識跳繩都可以參加嫁！有各區唔同時段選擇，總有一個岩哂你。今年開始更會正式推出『技術評核手冊』免費派發(價值50元)。報讀任何跳繩課程都會有一件T-Shirt 送比大家。</p>
                </div> */}
            </div>

            <div className={css.CourseDiv2}>
                <div className={css.CourseTitleDiv}>
                    <p>跳繩常規分區班課程</p>
                </div>
                <div className={css.LinkDiv}>
                    <p>課程 資料：</p>
                    {getMatchLink1(data, "Course_05")}
                    {getMatchLink2(data, "Course_06")}
                </div>
                {/* <div className={css.CourseImage}>
                    {getMatchImg(data, "Course_02")}
                </div> */}
                <div className={css.ThreeInOne}>

                    <div className={css.EachCourseImg}>
                        {getMatchImg(data, "Course_02")}
                    </div>

                    <div className={css.EachCourseImg}>
                        {getMatchImg(data, "Course_07")}
                    </div>

                    <div className={css.EachCourseImg}>
                        {getMatchImg(data, "Course_08")}
                    </div>

                </div>

            </div>

            <div className={css.DescDiv}>
                <p>好好為小朋友安排好跳繩課程啦！就算唔識跳繩都可以參加嫁！有各區唔同時段選擇，總有一個岩哂你。今年開始更會正式推出『技術評核手冊』免費派發(價值50元)。報讀任何跳繩課程都會有一件T-Shirt 送比大家。</p>
            </div>

            <div className={css.DescDiv2}>
                <p>花式跳繩課程：歡迎有興趣人士報讀各區的花式跳繩興趣班及 精英培訓班，暑期班跳繩班，私人跳繩班查詢。</p>
            </div>

            <div className={css.ContactDiv}>
                <p>如有任何問題，請致電 6580 1700 與本社職員聯絡</p>
            </div>
        </section>
    )
    return content
}

export default Course