import { Link } from 'react-router-dom'
import css from '../../css/C0533/Footer.module.css'

//import Photo
import Partners1 from '../../global/C533/Partners1.jpg'
import Partners2 from '../../global/C533/Partners2.png'
import Partners3 from '../../global/C533/Partners3.jpg'
import Partners4 from '../../global/C533/Partners4.png'
import Partners5 from '../../global/C533/Partners5.png'
import Partners6 from '../../global/C533/Partners6.png'
import Partners7 from '../../global/C533/Partners7.png'

const Footer = () => {
    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                <p>PARTNERS 合作機構</p>
            </div>
            <div className={css.PartnersDiv}>
                <div className={css.PartnersImg}><img src={Partners1} alt="Partners1" /></div>
                <div className={css.PartnersImg}><img src={Partners2} alt="Partners2" /></div>
                <div className={css.PartnersImg}><img src={Partners3} alt="Partners3" /></div>
                <div className={css.PartnersImg}><img src={Partners4} alt="Partners4" /></div>
                <div className={css.PartnersImg}><img src={Partners5} alt="Partners5" /></div>
                <div className={css.PartnersImg}><img src={Partners6} alt="Partners6" /></div>
                <div className={css.PartnersImg}><img src={Partners7} alt="Partners7" /></div>
            </div>
            <div className={css.Copyright}>
                <a href='/C0533/#'><p>Copyright © 2024 Hong Kong Rope Skipping Union</p></a>
            </div>
        </section>
    )
    return content
}

export default Footer