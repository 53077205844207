import { useContext, useEffect, useState } from "react";
import { c0211ProductProps, IC0211Product, IC0211ProductDetail, IC0211ProductType } from "../../../Interface/interface"
import AlertMessage from "../../LoadingBar/AlertMessage";
import DeafultLoadingBar from "../../LoadingBar/DefaultLoadingBar";
import MessageBox from "../../../Components/Common/AlertMessageBox"
import axios from "axios";
import css from '../../../css/C0211/EditProduct.module.css'
import { Cookies } from "react-cookie";
import DefaultProductImg from '../../../global/C0211/LogoBW.png'
import { UserContext } from "../../../Class/UserPara";
import { PreLoadingContext } from "../../../Class/C0211PreLoadingData";
import { AzureStorgeLink, C0211PreLoadingData, sasToken } from "../../../Interface/Const";
import { set } from "rsuite/esm/internals/utils/date";
import { use } from "i18next";


const cookies = new Cookies()

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
const C0211EditProductDetail: React.FC<c0211ProductProps> = ({ setProductID, isProductID, setEditMode }) => {

    const userContext = useContext(UserContext);
    const preLoadDataContext = useContext(PreLoadingContext);

    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    const [isShowMessageBox, setShowMessageBox] = useState<boolean>(false);
    const [isMessageBoxType, setMessageBoxType] = useState<string>('Boolean');
    const [isMessageBoxTitiel, setMessageBoxTitiel] = useState<string>('系統提示');
    const [isMessageBoxMessage, setMessageBoxMessage] = useState<string>('Are you sure you want to delete this product?');
    const [isMessageBoxResult, setMessageBoxResult] = useState<boolean | null>(null);

    const [isPhotoPanel, setPhotoPanel] = useState<boolean>(false);
    const [isPhotoAction, setPhotoAction] = useState<string>('');

    const [typedata, setTypeData] = useState<IC0211ProductType[]>([]);
    const [fileData, setFileData] = useState<{ [key: string]: { file: File | null; previewUrl: string | null } }>({
        fileInput1: { file: null, previewUrl: null },
        fileInput2: { file: null, previewUrl: null },
    });

    const [errorField, setErrorField] = useState({
        Name: false,
        Desc: false,
    });


    const [data, setData] = useState<IC0211ProductDetail>({
        product_main_id: 0,
        product_code: '',
        product_type_name: '',
        product_name: '',
        product_desc: '',
        product_img: '',
        product_status: '',
        create_on: '',
        create_by: '',
        update_on: '',
        update_by: ''
    });

    useEffect(() => {
        setLoading(true)
        getProductDetail()
    }, [])

    useEffect(() => {
        if (!isShowAlert) return;
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);
    }, [isShowAlert])

    useEffect(() => {
        if (isPhotoAction === '') return;

        if (isMessageBoxResult === false && isPhotoAction === 'upload') {
            setFileData((prevData) => ({
                ...prevData,
                fileInput1: {
                    file: null,
                    previewUrl: null,
                },
            }));

            setMessageBoxResult(null)
            setPhotoAction('')
            return;
        }

        if (isMessageBoxResult === null || isMessageBoxResult === false) return;

        if (isPhotoAction === 'remove') {
            handleRemovePhoto();
        }

        if (isPhotoAction === 'upload') {
            handleUplaodPhotoToAzure();
        }
    }, [isMessageBoxResult])


    const getProductDetail = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductDetail`, {
                product_main_id: isProductID
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            const AllProductTypeRes = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            });

            if (AllProductTypeRes.status === 200) {
                setTypeData(AllProductTypeRes.data['Product Type']);
            }

            if (res.status === 200) {
                setData(res.data.ProductDetail)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, inputId: string) => {
        let uploadedImageUrl = data.product_img;

        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFileData((prevData) => ({
                ...prevData,
                [inputId]: {
                    file: selectedFile,
                    previewUrl: URL.createObjectURL(selectedFile),
                },
            }));
        }

        setPhotoPanel(false)
        setMessageBoxMessage('Are you sure you want to upload this photo?');
        setShowMessageBox(true)
        setMessageBoxType('Boolean')
        setPhotoAction('upload')


    };

    const handleUplaodPhotoToAzure = async () => {
        setLoading(true)
        let uploadedImageUrl = data.product_img;

        if (fileData.fileInput1.file !== null) {
            uploadedImageUrl = (await uploadImageToAzureUsingREST()) || data.product_img;
            if (!uploadedImageUrl) {
                setShowAlert(true)
                setAlertType('Error')
                setAlertMessage('Image upload failed. Please try again.')
                setLoading(false)

                setMessageBoxResult(null)
                setPhotoAction('')
                return;
            }
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/UpdateProductMainPhoto`, {
                product_main_id: data.product_main_id,
                product_main_photo: uploadedImageUrl,
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                setShowAlert(true)
                setAlertType('Success')
                setAlertMessage('Product photo has been updated.')
                preLoadDataContext.preLoadData.isProductList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);
            }

        } catch (error) {

        } finally {
            setMessageBoxResult(null)
            setPhotoAction('')
            setLoading(false)
        }
    }

    const handleFileRemove = (inputId: string) => {
        setFileData((prevData) => ({
            ...prevData,
            [inputId]: {
                file: null,
                previewUrl: null,
            },
        }));
    };

    const handleRemovePhotoMessage = () => {
        setPhotoPanel(false)
        setMessageBoxMessage('Are you sure you want to remove this photo?');
        setShowMessageBox(true)
        setMessageBoxType('Boolean')
        setPhotoAction('remove')
    }

    const handleRemovePhoto = async () => {
        setLoading(true)

        try {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/UpdateProductMainPhoto`, {
                product_main_id: data.product_main_id,
                product_main_photo: null,
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {

                setData(prevData => ({
                    ...prevData,
                    product_img: ''
                }))

                setFileData((prevData) => ({
                    ...prevData,
                    fileInput1: {
                        file: null,
                        previewUrl: null,
                    },
                }));

                setShowAlert(true)
                setAlertType('Success')
                setAlertMessage('Product photo has been removed.')
                preLoadDataContext.preLoadData.isProductList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);

            }

        } catch (error) {
            console.log(error)
        } finally {
            console.log(data)
            setMessageBoxResult(null)
            setPhotoAction('')
            setPhotoPanel(false)
            setLoading(false)
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const SaveProductDetail = async () => {
        setLoading(true)

        const error = {
            Name: data.product_name === '',
            Desc: data.product_desc === '',
        }

        setErrorField(error)

        if (data.product_name === '') {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('Please enter product name.')
            setLoading(false)
            return;
        }

        if (data.product_desc === '') {
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('Please enter product description.')
            setLoading(false)
            return;
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertUpdateProductMain`, {
                product_main_id: data.product_main_id,
                product_name: data.product_name,
                product_desc: data.product_desc,
                // product_img: uploadedImageUrl,
                product_status: data.product_status
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                setShowAlert(true)
                setAlertType('Success')
                setAlertMessage('Product detail has been updated.')
                preLoadDataContext.preLoadData.isProductList = []
                await C0211PreLoadingData(userContext, preLoadDataContext);
            }
        } catch (error) {
            console.log(error)
            setShowAlert(true)
            setAlertType('Error')
            setAlertMessage('Product detail update failed.')
        } finally {
            setLoading(false)
        }

    }

    const uploadImageToAzureUsingREST = async () => {
        if (fileData.fileInput1.file === null) {
            console.error("No file selected for upload.");
            return;
        }

        const selectedFile = fileData.fileInput1.file;

        // Ensure the file size is less than the maximum allowed
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowAlert(true);
            setAlertType("Error");
            setAlertMessage("圖片檔案過大(5MB或以下)");
            return;
        }

        // Create a unique blob name
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = String(today.getFullYear()).slice(-2);
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const fileType = selectedFile.type.split('/')[1]; // Extract file extension
        const blobName = `C0211${year}${month}${day}${data.product_code}${minutes}.${fileType}`;

        // Construct the full Azure URL for uploading
        const containerName = "c0211";
        const azureUrl = `${AzureStorgeLink}/${containerName}/${blobName}`;
        const azureUploadUrl = `${azureUrl}?${sasToken}`;

        try {
            // Upload to Azure Blob Storage using PUT
            const response = await fetch(azureUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: selectedFile,
            });

            if (response.ok) {
                console.log("Upload successful:", azureUploadUrl);
                return azureUploadUrl;
                // setAlertType("Success");
                // setAlertMessage("圖片上傳成功");
                // setShowAlert(true);
            } else {
                const errorText = await response.text();
                console.error("Upload failed:", response.statusText, errorText);
                setAlertType("Error");
                setAlertMessage("圖片上傳失敗，請再試一次");
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setAlertType("Error");
            setAlertMessage("上傳過程中發生錯誤");
            setShowAlert(true);
        }
    };

    const setToDefaultImage = () => {
        setData(prevData => ({
            ...prevData,
            product_img: ''
        }));
    }

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar />}
            {isShowMessageBox && <MessageBox MessageBoxOnOff={setShowMessageBox} messageType={isMessageBoxType} messageTitle={isMessageBoxTitiel} messageContent={isMessageBoxMessage} returnBooleanResult={setMessageBoxResult} />}

            <div className={css.buttonContainer}>
                <button className={css.ReturnListBtn} onClick={() => setEditMode && setEditMode(null)}>
                    <i className={`fi fi-br-angle-double-left ${css.returntoListIcon}`} />
                    Back To Product List
                </button>
            </div>

            <section className={css.MainSection}>
                <div className={css.baseProductInfoDiv}>

                    <div className={css.ProductImageDiv}>
                        {fileData.fileInput1.previewUrl && fileData.fileInput1.previewUrl.length >= 0 ? (
                            <>
                                <img src={fileData.fileInput1.previewUrl} alt="Product_main_Image" />
                                <div className={css.EditPhotoPanel} onClick={() => { setPhotoPanel(!isPhotoPanel) }}>
                                    <i className={`fi fi-rc-pencil ${css.EditPhotoIcon}`} />
                                    Edit
                                </div>
                                {isPhotoPanel && (
                                    <div className={css.PhotoOptionPanel}>
                                        <div className={css.UploadPhotoPanel}>
                                            <ul>
                                                <li>
                                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                                    Upload a photo
                                                </li>
                                                <li onClick={() => handleRemovePhotoMessage()}>
                                                    Remove photo
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {data?.product_img ? (
                                    <img src={data.product_img} alt="Product_main_Image" />
                                ) : (
                                    <img src={DefaultProductImg} alt="Product_main_Image" />
                                )}
                                <div className={css.EditPhotoPanel} onClick={() => { setPhotoPanel(!isPhotoPanel) }}>
                                    <i className={`fi fi-rc-pencil ${css.EditPhotoIcon}`} />
                                    Edit
                                </div>
                                {isPhotoPanel && (
                                    <div className={css.PhotoOptionPanel}>
                                        <div className={css.UploadPhotoPanel}>
                                            <ul>
                                                <li>
                                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                                    Upload a photo
                                                </li>
                                                <li onClick={() => handleRemovePhotoMessage()}>
                                                    Remove photo
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {/* <button className={css.PhotoProductBtn} onClick={() => console.log("This function not ready.")}>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                    <i className={`fi fi-rc-pencil ${css.EditPhotoIcon}`} />
                                    Edit
                                </button>
                                <button className={css.RemovePhotoProductBtn} onClick={() => setToDefaultImage()}>
                                    <i className={`fi fi-rr-refresh ${css.RemovePhotoIcon}`} />
                                </button> */}
                            </>
                        )}
                    </div>

                    <div className={css.baseProductInfo1}>
                        <div className={css.baseInfoItem}>
                            <label>Product Code </label>
                            <input type="text" value={data.product_code ?? ''} disabled={true} />
                        </div>

                        <div className={errorField.Name ? css.errorbaseInfoItem : css.baseInfoItem}>
                            <label>Product Name</label>
                            <input type="text" name="product_name" value={data?.product_name} onChange={handleInputChange} />
                        </div>


                        <div className={css.baseInfoItem}>
                            <label>Product Type</label>
                            <select name="product_type_name" value={data?.product_type_name} onChange={handleInputChange} disabled={true}>
                                {typedata.map((item, index) => (
                                    <option key={index} value={item.product_type_name}>{item.product_type_code} | {item.product_type_name}</option>
                                ))}
                            </select>
                        </div>

                        <div className={errorField.Desc ? css.errorbaseInfoItem : css.baseInfoItem}>
                            <label>Product Description</label>
                            <input type="text" name="product_desc" value={data?.product_desc} onChange={handleInputChange} />
                        </div>


                        <div className={css.baseInfoItem}>
                            <label>Status</label>
                            <select name="product_status" value={data?.product_status} onChange={handleInputChange}>
                                <option value="Y">開啟</option>
                                <option value="N">關閉</option>
                            </select>
                        </div>
                    </div>
                </div>

            </section>

            <section className={css.BottomSection}>
                <div className={css.SaveBtnContainer}>
                    {/* <button className={css.BottomBtn} onClick={() => setMode(4)}>
                        <i className="fi fi-rs-key" />
                        Reset Password
                    </button> */}

                    <button className={css.BottomBtn} onClick={() => SaveProductDetail()}>
                        <i className="fi fi-br-user-add" />
                        Save
                    </button>
                </div>
            </section>
        </>
    )
    return content
}

export default C0211EditProductDetail