import RequestForm from "../../Components/C0281/RequestForm"

const BemisSampleRequestFormPage = () => {
    const content = (
        <>
        <RequestForm />
        </>
    )
    return content
}

export default BemisSampleRequestFormPage