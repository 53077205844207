import DeafultLoadingBar from "../../Components/LoadingBar/DefaultLoadingBar";

const C0211TestPage = () => {
    const conent = (
        <>
            <DeafultLoadingBar />
        </>
    ) 
    return conent;
}

export default C0211TestPage