import { useState } from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker';

import css from '../../../css/C0211/DevelopTest.module.css';


const DataPickerTest = () => {

    const [date, setDate] = useState<Date | null>(null);

    const content = (
        <>
        <p>{date ? date.toString() : 'No date selected'}</p>
        <DatePicker onChange={(value: DatePickerProps['value']) => setDate(value as Date | null)} value={date} format="dd-MM-yyyy" className={css.DatePicker} clearIcon={null} yearPlaceholder="yyyy" monthPlaceholder="mm" dayPlaceholder='dd'/>
        </>
    )

    return content
}

export default DataPickerTest