import { Helmet, HelmetProvider } from "react-helmet-async"
import NavBar from "../../Components/C0533/NavBar"
import Footer from "../../Components/C0533/Footer"
import { Outlet } from "react-router-dom"


import logo from '../../global/C533/AboutLogo.png'


const MainLayout = () => {

    const content = (
        <>
            <body>
                <HelmetProvider>
                    <div>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>香港跳繩精英訓練社Hong Kong Rope Skipping Union</title>
                            <link rel="icon" href={logo} />
                        </Helmet>
                        <NavBar />
                        <Outlet />
                        <Footer />
                    </div>
                </HelmetProvider>
            </body>
        </>
    )
    return content
}

export default MainLayout