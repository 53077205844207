import { Link } from 'react-router-dom'
import css from '../../../css/C0217/NavBar.module.css'
import { Cookies } from 'react-cookie';
import { useContext } from 'react';
import { SystemContext } from '../../../Class/SystemPara';
import { LogoutFunction } from '../../../Interface/Const'
const cookies = new Cookies();

const C0217Navbar = () => {

    const systemContext = useContext(SystemContext);

    const content = (
        <>
            <section className={css.MainSection}>

                <div className={css.EachTypeItem}>
                    <Link to="/C0217/"><i className={`fi fi-rs-home ${css.iconStyles}`} /><p>Dashboard</p></Link>
                </div>
                <div className={css.EachTypeItem}>
                    <Link to="/C0217/FunctionList"><i className={`fi fi-sr-list ${css.iconStyles}`} /><p>Function</p></Link>
                </div>

                <div className={css.EachTypeItem}>
                    <Link to="/C0217/Setting"><i className={`fi fi-rs-settings ${css.iconStyles}`} /><p>Setting</p></Link>
                </div>

                <div className={css.EachTypeItem}>
                    <Link to="/" onClick={() => LogoutFunction(systemContext)}><i className={`fi fi-ss-sign-out-alt ${css.iconStyles}`} /><p>Logout</p></Link>
                </div>

            </section>

        </>
    )

    return content
}

export default C0217Navbar