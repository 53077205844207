import { Link } from 'react-router-dom'
import NBcss from '../css/NavBar.module.css'
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { SystemContext } from '../Class/SystemPara';

const MediaNavBar = () => {
    const cookies = new Cookies();

    const systemContext = useContext(SystemContext);

    const { t, i18n } = useTranslation();
    const [login, IsLogin] = useState(false)

    useEffect(() => {
        IsLogin(cookies.get('Login_status'))
    });

    const dashboardRouter = () => {
        const getACID = cookies.get('ACID');
        let dashboardLink = null;

        console.log(getACID);

        switch (getACID) {
            case "c0000":
                dashboardLink = (
                    <li><Link className={NBcss.active} to="/dash">{t('DashBoard')}</Link></li>
                );
                break;
            case "c0211":
                dashboardLink = (
                    <li><Link className={NBcss.active} to="/C0211">{t('DashBoard')}</Link></li>
                );
                break;
            case "c0217":
                dashboardLink = (
                    <li><Link className={NBcss.active} to="/C0217">{t('DashBoard')}</Link></li>
                );
                break;
            default:
                dashboardLink = null;
        }

        return dashboardLink;
    };

    const Logout = () => {
        cookies.remove('token');
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/Login`);
    }

    const content = (
        <section className={NBcss.MediaNavSection}>
            <div>
                <ul id={NBcss.MnavMenu}>
                    <li><Link className={NBcss.Mactive} to="/">{t('HomePage')}</Link></li>
                    <li><Link className={NBcss.Mactive} to="/contact-us">{t('ContactUS')}</Link></li>
                    <li><Link className={NBcss.Mactive} to="/PCSystem">{t('PCSoftWare')}</Link></li>
                    <li><Link className={NBcss.Mactive} to="/PhoneSystem">{t('PhoneSoftWare')}</Link></li>
                    {/* {login === true && <li><Link className={NBcss.Mactive} to="/dash">{t('DashBoard')}</Link></li>} */}
                    {systemContext.systemData.isLogin === true && dashboardRouter()}
                    {login === true ? <li><Link className={NBcss.Mactive} to="/Login" onClick={Logout}>{t('Logout')}</Link></li> : <li><Link className={NBcss.Mactive} to="/Login">{t('Login')}</Link></li>}
                </ul>
            </div>
        </section>
    )
    return content
}
export default MediaNavBar