import { useState, useEffect, useRef, useContext } from 'react';
import css from '../../../css/C0211/ControlBar.module.css';
import DefaultUserImage from '../../../global/C0211/DefaultPhoto.jpg';
import { WebsiteMessage } from '../../../Class/WebsiteMessage';
import { isAfter, isEqual, parse } from 'date-fns';
import { SystemContext } from '../../../Class/SystemPara';
import { LogoutFunction } from '../../../Interface/Const';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

const ControlBar = () => {

    const systemContext = useContext(SystemContext);

    const [isShowNotifications, setIsShowNotifications] = useState(false);
    const [isShowUserMenu, setIsShowUserMenu] = useState(false);
    const controlBarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (controlBarRef.current && !controlBarRef.current.contains(event.target as Node)) {
                setIsShowNotifications(false);
                setIsShowUserMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        cookies.remove('token');
        systemContext.setLoginStatus(false)
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
    }

    const toggleNotifications = () => {
        setIsShowNotifications((prev) => !prev);
        setIsShowUserMenu(false); // Close user menu if open
    };

    const toggleUserMenu = () => {
        setIsShowUserMenu((prev) => !prev);
        setIsShowNotifications(false); // Close notifications if open
    };

    const filteredMessages = WebsiteMessage.filter(item => {
        const currentDate = new Date();
        const enableToDate = parse(item.EnableTo, 'dd-MM-yyyy', new Date());
        return item.Published === true && (isAfter(enableToDate, currentDate) || isEqual(enableToDate, currentDate));
    }).filter(item => item.ACID === 'C0211');


    return (
        <section className={css.MainSection} ref={controlBarRef}>
            <div className={css.ControlBarDiv}>
                {/* Notifications */}
                <div className={css.Notifications} onClick={() => toggleNotifications()}>
                    <i className={`fi fi-ss-bell ${css.BellIcon}`} />
                    {isShowNotifications && (
                        <div className={css.NotificationDropdown}>
                            <ul>
                                {filteredMessages.length > 0 ? (
                                    filteredMessages.map((item, index) => (
                                        <li key={index}>
                                            <div className={css.eachNotification}>
                                                <div className={css.MessageIconDiv}>
                                                    <i className={`fi fi-sr-bolt ${css.MessageIcon}`} />
                                                </div>
                                                <div className={css.MessageData}>
                                                    <p className={css.mainMessage}>{item.Message}</p>
                                                    <p className={css.MessageDate}>{`${item.Date}`}</p>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <p className={css.NoAlretMessage}>沒有通知</p>
                                )}
                            </ul>
                        </div>
                    )}
                </div>

                {/* User Icon */}
                <div className={css.UserIcon}>
                    <img src={DefaultUserImage} alt="User Icon" />
                </div>

                {/* User Menu */}
                <div className={css.MenuDiv} onClick={() => toggleUserMenu()}>
                    <i className={`fi fi-rr-caret-down ${css.DownArrow}`} />
                    {isShowUserMenu && (
                        <div className={css.UserMenu}>
                            <ul>
                                <li>
                                    <div className={css.eachMenuItem}><i className="fi fi-ss-user"></i>個人資料</div>
                                </li>
                                <li onClick={() => LogoutFunction(systemContext)}>
                                    <div className={css.eachMenuItem}><i className="fi fi-bs-sign-out-alt"></i>登出帳號</div>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ControlBar;
