import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import RRcss from '../css/RaceRank.module.css'
import axios from 'axios';
import { IRaceRank } from '../Interface/interface';

const RaceRank = () => {
    const { t, i18n } = useTranslation();
    const [date, SetDate] = useState<string | undefined>('');
    const [data, SetData] = useState<IRaceRank[]>([])
    // const [no , SetNo ] = useState<IRaceRankdetails []>([])
    const [no, SetNo] = useState<number>(-1);

    const handleDateInput = (e: ChangeEvent<HTMLInputElement>) => SetDate(e.target.value);

    useEffect(() => {
        GetFunctionList();
    }, [date])

    const getSelectNo = (value: number) => {
        SetNo(value - 1)
    };

    async function GetFunctionList() {
        SetNo(-1)
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/c0020/race/GetRaceData`, {
                race_date: date
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }

            })
                .then(function (res) {
                    console.log(res.data)
                    SetData(res.data)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const content = (
        <div className={RRcss.RaceRank}>

            <section className={RRcss.SelectPart}>

                <p>選擇日期:</p><input type="date" onChange={handleDateInput}></input>
                <div className={RRcss.SelectRaceNo}>
                    {data.map((race) => (
                        <div>
                            <div className={RRcss.SelectRaceNoBtn}>
                                <button onClick={() => { getSelectNo(race.race_no) }} value={race.race_no}>{race.race_no}</button>
                            </div>
                        </div>
                    ))}
                </div>

            </section>

            <div className={RRcss.RaceRankInfomation}>

                {no >= 0 ? (
                    <div className={RRcss.BaseInfoDiv}>
                        <table className={RRcss.Basetable}>
                            <p>資枓</p>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_id</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_id}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_date</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_date}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_no</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_no}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_location</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_location}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_day_night</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_day_night}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_type</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_type}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_name</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_name}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_time</th>
                                <th>{no >= 0 && data[no].race_time}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_is_all_day_road</th>
                                <th className={RRcss.BaseinfoBE}>{no >= 0 && data[no].race_is_all_day_road}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_length</th>
                                <th>{no >= 0 && data[no].race_length}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_ground_type</th>
                                <th>{no >= 0 && data[no].race_ground_type}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_bounty</th>
                                <th>{no >= 0 && data[no].race_bounty}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_rating</th>
                                <th>{no >= 0 && data[no].race_rating}</th>
                            </tr>
                            <tr className={RRcss.Baseinfo}>
                                <th className={RRcss.Baseinfo}>race_class</th>
                                <th>{no >= 0 && data[no].race_class}</th>
                            </tr>
                        </table>
                    </div>
                ) : null}
                {no >= 0 ? (<p className={RRcss.TitleMessage}>詳細資料</p>) : null}
                <table className={RRcss.RaceTable}>
                    {no >= 0 ? (
                        <tr className={RRcss.DetailTitle}>
                            <th className={RRcss.race_detail_id}>{t('race_detail_id')}</th>
                            <th className={RRcss.race_id}>{t('race_id')}</th>
                            <th className={RRcss.horse_no}>{t('horse_no')}</th>
                            <th className={RRcss.horse_id}>{t('horse_id')}</th>
                            <th className={RRcss.last_six_ranking}>{t('last_six_ranking')}</th>
                            <th className={RRcss.negative_pounds}>{t('negative_pounds')}</th>
                            <th className={RRcss.rider_id}>{t('rider_id')}</th>
                            <th className={RRcss.possible_overweight}>{t('possible_overweight')}</th>
                            <th className={RRcss.trainer_id}>{t('trainer_id')}</th>
                            <th className={RRcss.race_position}>{t('race_position')}</th>
                            <th className={RRcss.rating}>{t('rating')}</th>
                            <th className={RRcss.rating_changes}>{t('rating_changes')}</th>
                            <th className={RRcss.priority_queue}>{t('priority_queue')}</th>
                            <th className={RRcss.weight_ranking}>{t('weight_ranking')}</th>
                            <th className={RRcss.weight_ranking_changes}>{t('weight_ranking_changes')}</th>
                            <th className={RRcss.best_time}>{t('best_time')}</th>
                            <th className={RRcss.horse_age}>{t('horse_age')}</th>
                            <th className={RRcss.split_age_give_pounds}>{t('split_age_give_pounds')}</th>
                            <th className={RRcss.seasonal_bounty}>{t('seasonal_bounty')}</th>
                            <th className={RRcss.accessories}>{t('accessories')}</th>
                            <th className={RRcss.horse_owner}>{t('horse_owner')}</th>
                            <th className={RRcss.sp_loss_pounds}>{t('sp_loss_pounds')}</th>
                            <th className={RRcss.sp_split_age_give_pounds}>{t('sp_split_age_give_pounds')}</th>
                            <th className={RRcss.sp_accessories}>{t('sp_accessories')}</th>
                            <th className={RRcss.sp_strap_tongue}>{t('sp_strap_tongue')}</th>
                            <th className={RRcss.sp_last_game_mouth_metal}>{t('sp_last_game_mouth_metal')}</th>
                            <th className={RRcss.sp_this_game_mouth_metal}>{t('sp_this_game_mouth_metal')}</th>
                            <th className={RRcss.sp_ratings}>{t('sp_ratings')}</th>
                            <th className={RRcss.sp_length}>{t('sp_length')}</th>
                            <th className={RRcss.sp_last_game_day_diff}>{t('sp_last_game_day_diff')}</th>
                            <th className={RRcss.sp_trainer_changes}>{t('sp_trainer_changes')}</th>
                            <th className={RRcss.sp_arrival_date}>{t('sp_arrival_date')} </th>
                        </tr>
                    ) : null}
                    {no >= 0 && data[no].race_details.map((detail) => (
                        <tr className={RRcss.DetailTitle}>
                            <td className={RRcss.race_detail_id}>{no >= 0 && detail.race_detail_id}</td>
                            <td className={RRcss.race_id}>{no >= 0 && detail.race_id}</td>
                            <td className={RRcss.horse_no}>{no >= 0 && detail.horse_no}</td>
                            <td className={RRcss.horse_id}>{no >= 0 && detail.horse_id}</td>
                            <td className={RRcss.last_six_ranking}>{no >= 0 && detail.last_six_ranking}</td>
                            <th className={RRcss.negative_pounds}>{no >= 0 && detail.negative_pounds}</th>
                            <th className={RRcss.rider_id}>{no >= 0 && detail.rider_id}</th>
                            <th className={RRcss.possible_overweight}>{no >= 0 && detail.possible_overweight}</th>
                            <th className={RRcss.trainer_id}>{no >= 0 && detail.trainer_id}</th>
                            <th className={RRcss.race_position}>{no >= 0 && detail.race_position}</th>
                            <th className={RRcss.rating}>{no >= 0 && detail.rating}</th>
                            <th className={RRcss.rating_changes}>{no >= 0 && detail.rating_changes}</th>
                            <th className={RRcss.priority_queue}>{no >= 0 && detail.priority_queue}</th>
                            <th className={RRcss.weight_ranking}>{no >= 0 && detail.weight_ranking}</th>
                            <th className={RRcss.weight_ranking_changes}>{no >= 0 && detail.weight_ranking_changes}</th>
                            <th className={RRcss.best_time}>{no >= 0 && detail.best_time}</th>
                            <th className={RRcss.horse_age}>{no >= 0 && detail.horse_age}</th>
                            <th className={RRcss.split_age_give_pounds}>{no >= 0 && detail.split_age_give_pounds}</th>
                            <th className={RRcss.seasonal_bounty}>{no >= 0 && detail.seasonal_bounty}</th>
                            <th className={RRcss.accessories}>{no >= 0 && detail.accessories}</th>
                            <th className={RRcss.horse_owner}>{no >= 0 && detail.horse_owner}</th>
                            <th className={RRcss.sp_loss_pounds}>{no >= 0 && detail.sp_loss_pounds}</th>
                            <th className={RRcss.sp_split_age_give_pounds}>{no >= 0 && detail.sp_split_age_give_pounds}</th>
                            <th className={RRcss.sp_accessories}>{no >= 0 && detail.sp_accessories}</th>
                            <th className={RRcss.sp_strap_tongue}>{no >= 0 && detail.sp_strap_tongue}</th>
                            <th className={RRcss.sp_last_game_mouth_metal}>{no >= 0 && detail.sp_last_game_mouth_metal}</th>
                            <th className={RRcss.sp_this_game_mouth_metal}>{no >= 0 && detail.sp_this_game_mouth_metal}</th>
                            <th className={RRcss.sp_ratings}>{no >= 0 && detail.sp_ratings}</th>
                            <th className={RRcss.sp_length}>{no >= 0 && detail.sp_length}</th>
                            <th className={RRcss.sp_last_game_day_diff}>{no >= 0 && detail.sp_last_game_day_diff}</th>
                            <th className={RRcss.sp_trainer_changes}>{no >= 0 && detail.sp_trainer_changes}</th>
                            <th className={RRcss.sp_arrival_date}>{no >= 0 && detail.sp_arrival_date}</th>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
    return content
}

export default RaceRank