import React from 'react';
import css from '../../../css/C0217/RepairEquipmentMenu.module.css'
import RepairEquipmentMenu from '../../../Components/C0217/Shop/RepairEquipmentFunctionList'

const RepairEquipmentMenuPage = () => {
    const content = (
        <>
            <RepairEquipmentMenu />
        </>
    )

    return content
}

export default RepairEquipmentMenuPage;