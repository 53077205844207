import css from '../../css/C0281/Main.module.css'

import ACLogo from '../../global/C0281/ACLogo.png'

const RequestForm = () => {
    const content = (
        <section className={css.MainSection}>
            {/* Excel Row 1 */}
            <div className={css.TopView}>
                <div className={css.LogoDiv}>
                    <img src={ACLogo} alt="Logo" />
                    <p>Bemis Sample Request Form</p>
                </div>
                <div className={css.RecordNumDiv}>
                    <p>*78439*</p>
                </div>
            </div>

            {/* Excel Row 2-4 */}
            <div className={css.BaseForm24}>
                <div className={css.BaseInfo}>
                    <div className={css.DateInfo}>
                        <p>Date:</p>
                        <p>21-Mar-2022</p>
                    </div>
                </div>
                <div className={css.SubBaseInfo}>
                    <div className={css.RequisitionDiv}>
                        <p>Requisition #</p>
                        <p>78439</p>
                    </div>
                    <div className={css.AirWayBill}>
                        <p>AirWayBill#</p>
                        <p>DHL: 2993218325</p>
                    </div>
                </div>
            </div>

            {/* Excel Row 5 */}
            <div className={css.BaseForm5}>
                <div className={css.RequestorDiv}>
                    <p>Requestor:</p>
                    <p>KARYN DoHERTY</p>
                </div>
                <div className={css.SalesmanDiv}>
                    <p>SALESMAN / CUSTOMER CODE:</p>
                </div>
                <div className={css.SalesmanInputDiv}>
                    <p>NEWLEAD</p>
                </div>
            </div>

            {/* Excel Row 6 */}
            <div className={css.BaseForm6}>
                <div className={css.Brandcode}>
                    <p>BRAND CODE:</p>
                </div>
            </div>
        </section>
    )
    return content
}

export default RequestForm