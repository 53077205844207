import css from '../css/ProfileSetting.module.css'
const ProfileSetting = () => {
    const content = (
        <>

            <section className={css.BaseInfoSection}>

                <div className={css.subTitleDiv}>
                    <i className="bi bi-person-badge-fill" /><p className={css.subTitle}>General Details</p>
                </div>


                <div className={css.BaseInfoDiv}>
                    <div className={css.EachFormDiv}>
                        <p>Login ID</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Username</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Department</p>
                        <select className={css.LoginIDText}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="-">Other</option>
                        </select>
                    </div>

                    
                    <div className={css.EachFormDiv}>
                        <p>Job Title</p>
                        <input type="text" className={css.LoginIDText}></input>
                    </div>

                    <div className={css.EachFormDiv}>
                        <p>Premission Level</p>
                        <select className={css.LoginIDText}>
                            <option value="">A</option>
                            <option value="">B</option>
                            <option value="-">Other</option>
                        </select>
                    </div>


                    
                    <div className={css.EachFormDiv}>
                        <p>Status</p>
                        <div className={css.RadioDiv}>
                        <input type="radio" className={css.StatusRadio} name="drone"></input>
                        <label>Yes</label>
                        <input type="radio" className={css.StatusRadio} name="drone"></input>
                        <label>No</label>
                        </div>
                    </div>

                    <div className={css.EachFormDiv100}>
                        <p>Remark</p>
                        <textarea className={css.RemarkText} rows={5} cols={85}></textarea>
                    </div>
                    
                </div>
            </section>

        </>
    )
    return content
}
export default ProfileSetting