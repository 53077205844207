import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DVNBcss from '../css/DashVNavBar.module.css'
import logo from '../global/logo.jpg'
import { IMatchFunction} from '../Interface/interface'
import { Cookies } from 'react-cookie'
import { SystemContext } from '../Class/SystemPara'
import { UserContext } from '../Class/UserPara'
import { FetchSystemData } from '../Interface/Const'

const cookies = new Cookies();

const DashVNavBar = () => {

    const systemContext = useContext(SystemContext);
    const userContext = useContext(UserContext)


    const [openDropdowns, setOpenDropdowns] = useState<string[]>([]);
    const [matchdata, setMatchDate] = useState<IMatchFunction[]>([])

    const toggleDropdown = (dropdownId: string) => {
        if (openDropdowns.includes(dropdownId)) {
            setOpenDropdowns(openDropdowns.filter((id) => id !== dropdownId));
        } else {
            setOpenDropdowns([...openDropdowns, dropdownId]);
        }
    };

    async function getmatchData() {
        setMatchDate([])
        userContext.userData.permissions.forEach((permission: { permission_id: number }) => {
            systemContext.systemData.FunctionArr.forEach(d => {
                if (permission.permission_id === d.permission_id) {
                    setMatchDate(matchdata => [...matchdata, d]);
                }
            });
        });
    }

    useEffect(() => {
        FetchSystemData(systemContext)
    }, [])

    useEffect(() => {
        getmatchData();
    }, [userContext.userData, systemContext.systemData.FunctionArr])


    const content = (
        <section className={DVNBcss.MainSection}>

            <div className={DVNBcss.LogoDiv}>
                <img src={logo} alt="logo"></img>
                <Link to="/" className={DVNBcss.LogoutBtn}>Logout</Link>
            </div>

            <div className={DVNBcss.DropDownMenu}>
                <button
                    className={`${DVNBcss.DDEachItem} ${openDropdowns.includes('dropdown1') ? 'active' : ''}`}
                    onClick={() => toggleDropdown('dropdown1')}
                >
                    <i className="bi bi-box-fill" /><p>Function List</p>
                </button>
                {openDropdowns.includes('dropdown1') && (
                    <div className={DVNBcss.SubNavBarBtn}>

                        {matchdata.map((cafunction, index) => (

                            <Link to={cafunction.function_path} className={DVNBcss.SubNavBarBtn} key={index}>
                                <div className={DVNBcss.SubEachItem}>
                                    <i className="fi fi-sr-arrow-turn-down-right" /><p>{cafunction.function_section}</p>
                                </div>
                            </Link>

                        ))}

                    </div>
                )}
            </div>

            <div className={DVNBcss.DropDownMenu}>
                <Link to="/dash/Setting" className={DVNBcss.NavBarBtn}>
                    <div className={DVNBcss.EachItem}>
                        <i className="bi bi-gear-fill" /><p>Setting</p>
                    </div>
                </Link>
            </div>
        </section>
    )
    return content
}
export default DashVNavBar