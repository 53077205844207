import UpdateLogCmp from '../Components/UpdateLog';

const UpdateLogBookPage = () => {
    const content = (
        <>
            <UpdateLogCmp />
        </>
    )
    return content;
}

export default UpdateLogBookPage;