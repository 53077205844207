import { CreateOutline, RefreshOutline } from 'react-ionicons'
import css from '../css/GPSReocrd.module.css'

const GPSRecord = () => {

    const content = (
        <>
            <section className={css.TopSection}>
                <div className={css.ControlBar}>
                    <p className={css.Title}>Roster Record</p>
                    <div className={css.RefreshBtn}>
                    <RefreshOutline
                        color={'#00000'}
                        height="35px"
                        width="35px"
                    />
                    </div>
                </div>

                <div className={css.ListUser}>
                    <table className={css.UserTable} id="UserListTable">
                        <tr>
                            <th>Roster ID</th>
                            <th>Username</th>
                            <th>Shop Code</th>
                            <th>Shop Name</th>
                            <th>Date Time</th>
                            <th>Status</th>
                            <th>Platform</th>
                            <th>Remarks</th>
                            <th>Update Reocrd</th>
                        </tr>
                        <tr>
                            <td>S0001</td>
                            <td>Staff ABC</td>
                            <td>SHY-028</td>
                            <td>油塘 Head Office</td>
                            <td>29/2/2024 9:35:04 AM</td>
                            <td>Y</td>
                            <td>React</td>
                            <td>NULL</td>
                            <td className={css.EditUserBtn}><CreateOutline
                                color={'#032654'}
                                height="30px"
                                width="30px"
                            /></td>
                        </tr>

                    </table>
                </div>
            </section>
        </>
    )
    return content
}
export default GPSRecord