import ShopList from '../Components/ShopList'

const ShopListPage = () => {
    const content = (
        <>
            <ShopList />
        </>
    )
    return content
}
export default ShopListPage