import { useEffect, useState } from "react"
import { Cookies } from "react-cookie";
import { IC0217DownloadReportProps } from "../../../Interface/interface"
import css from '../../../css/C0217/KeywordFilter.module.css'

import saveFileGIF from "../../../global/C0217/save.gif"
import trashBinGIF from '../../../global/C0217/trash-can.gif'


const cookies = new Cookies();

const C0217KeywordFilter: React.FC<IC0217DownloadReportProps> = ({
    setShowAlert,
    setAlertType,
    setAlertMessage,
    setLoading,
    setStage,
    isFilterData,
    selectedShops,
    keywords,
    addKeywordInput,
    RemoveAllKeywords,
    handleKeywordChange,
    handlePackageLoad,
}) => {

    const [packageName, setPackageName] = useState(""); // New state for package name
    const [keywordPackages, setKeywordPackages] = useState<{ name: string; keywords: string[] }[]>([]);

    useEffect(() => {
        const savedPackages = cookies.get("keywordPackages");
        if (savedPackages) {
            setKeywordPackages(savedPackages);
        }
    }, [])

    const savePackageToCookies = () => {
        if (!packageName) {
            setShowAlert && setShowAlert(true);
            setAlertType && setAlertType("Warning");
            setAlertMessage && setAlertMessage("Please enter a package name before saving.");
            return;
        }

        // Check if a package with the same name exists
        const packageIndex = keywordPackages.findIndex(pkg => pkg.name === packageName);
        let updatedPackages;

        if (packageIndex !== -1) {
            // Update existing package
            updatedPackages = [...keywordPackages];
            updatedPackages[packageIndex] = { name: packageName, keywords: keywords ? [...keywords] : [] };
            setAlertMessage && setAlertMessage(`Package "${packageName}" has been updated successfully.`);
        } else {
            // Add a new package
            updatedPackages = [...keywordPackages, { name: packageName, keywords: keywords ? [...keywords] : [] }];
            setAlertMessage && setAlertMessage(`Package "${packageName}" has been saved successfully.`);
        }

        // Save the updated packages array to cookies
        cookies.set("keywordPackages", updatedPackages, { path: "/", expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });

        // Update state and reset package name
        setKeywordPackages(updatedPackages);
        setPackageName(""); // Clear package name input
        setShowAlert && setShowAlert(true);
        setAlertType && setAlertType("Success");
    };

    const removePackageAtCookiesList = () => {

        if (!packageName) {
            setShowAlert && setShowAlert(true);
            setAlertType && setAlertType("Warning");
            setAlertMessage && setAlertMessage("Please enter a package name before delete.");
            return;
        }

        // Filter out the package by name
        const updatedPackages = keywordPackages.filter(pkg => pkg.name !== packageName);

        // Update cookies
        cookies.set("keywordPackages", updatedPackages, { path: "/", expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });

        // Update state
        setKeywordPackages(updatedPackages);
        setShowAlert && setShowAlert(true);
        setAlertType && setAlertType("Success");
        setAlertMessage && setAlertMessage(`Package "${packageName}" has been deleted successfully.`);
    };

    const handlePackageLoadProccess = (packname: string, packageKeywords: string[]) => {
        setPackageName(packname)
        handlePackageLoad && handlePackageLoad(packageKeywords)
    }


    const content = (
        <>
            <div className={css.MainSectionWrapper}>
                <section className={css.MainSection}>
                    <div className={css.KeywordsDiv}>
                        <div className={css.TitileDiv}>
                            <p>Keywords Filter</p>
                        </div>

                        <div className={css.KeywordsInputDiv}>

                            {keywordPackages.length > 0 && (
                                <fieldset className={css.KeywordPackageFieldset}>
                                    <legend>Saved Keyword Packages</legend>
                                    {keywordPackages.map((pkg, index) => (
                                        <button className={css.eachPackageBtn} key={index} onClick={() => handlePackageLoadProccess(pkg.name, pkg.keywords)}>
                                            <p>{pkg.name}</p>
                                        </button>
                                    ))}
                                </fieldset>
                            )}

                            <div className={css.PackageNameDiv2}>
                                <input
                                    type="text"
                                    placeholder="Enter Package Name"
                                    value={packageName}
                                    onChange={(e) => setPackageName(e.target.value)}
                                    className={css.PackageNameInput}
                                />
                                <button
                                    type="button"
                                    className={css.SavePackageButton2}
                                    onClick={savePackageToCookies}
                                >
                                    <img src={saveFileGIF} alt="Save" />
                                    {/* <i className="fi fi-rs-disk" /> */}
                                    {/* <p>Save Keywords Package</p> */}
                                </button>
                                <button
                                    type="button"
                                    className={css.SavePackageButton2}
                                    onClick={removePackageAtCookiesList}
                                >
                                    <img src={trashBinGIF} alt="Save" />
                                    {/* <i className="fi fi-rs-trash" /> */}
                                    {/* <p>Delete Keyword Package</p> */}
                                </button>
                            </div>

                            <fieldset className={css.KeyWordsFieldset}>
                                <legend>Please Add Keywords as below</legend>

                                {/* Render input for each keyword */}
                                {keywords && keywords.map((keyword, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        className={css.KeywordInput}
                                        placeholder={`Keyword ${index + 1}`}
                                        value={keyword}
                                        onChange={(e) => handleKeywordChange && handleKeywordChange(index, e.target.value)}
                                    />
                                ))}

                                {/* Button to add new keyword input */}
                                <div className={css.KeywordControlBtnDiv}>
                                    <button
                                        type="button"
                                        className={css.AddKeywordButton}
                                        onClick={addKeywordInput}
                                    >
                                        <i className="fi fi-rr-multiple" />
                                        <p>Add Keyword</p>
                                    </button>
                                    <button
                                        type="button"
                                        className={css.AddKeywordButton}
                                        onClick={RemoveAllKeywords}
                                    >
                                        <i className="fi fi-rr-multiple" />
                                        <p>Remove All Keyword</p>
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div className={css.ControlBtnDiv}>

                        <div className={css.ControlBtn}>
                            <button className={css.SelectionBackBtn} onClick={() => setStage && setStage(1)}>
                                <i className="fi fi-br-left" />
                                Back
                            </button>
                        </div>

                        {/* <div className={css.ControlBtn}>
                            <button className={css.SelectionBtn} onClick={() => setStage && setStage(90)}>
                                <i className="fi fi-rr-print" />
                                Generate Report
                            </button>
                        </div> */}

                    </div>

                </section>
            </div>
        </>
    )

    return content
}

export default C0217KeywordFilter