import { Parallax } from 'react-parallax';
import { useTranslation } from 'react-i18next';
import DescriptionBG from '../global/Description.png'
import DScss from '../css/Description.module.css'
//import 'bootstrap/dist/css/bootstrap.css'


const Description = () => {

    const { t, i18n } = useTranslation();

    return (
        <section className={DScss.MainSection}>
            <div className={DScss.ContactMessage}>
                <Parallax blur={5} bgImage={DescriptionBG} bgImageAlt="DescriptionBG" strength={600} style={{
                    width: '100%',
                    height: '100%',
                    filter: `grayscale(50%)`,
                }} >

                    <div className={DScss.DescriptionMainGroup}>

                        <div className={DScss.DescriptionGroup}>
                            <p className={DScss.DescriptioMainTitle}>{t('DpageTitle')}</p>
                        </div>

                        <div className={DScss.DescriptionGroup}>
                            <p className={DScss.SubDescriptionTitle}>{t('DpagePoint1Titile')}</p>
                            <p className={DScss.DescriptionMessage}>{t('DpagePoint1Message')}</p>
                        </div>

                        <div className={DScss.DescriptionGroup}>
                            <p className={DScss.SubDescriptionTitle}>{t('DpagePoint2Titile')}</p>
                            <p className={DScss.DescriptionMessage}>{t('DpagePoint2Message')}</p>
                        </div>

                        <div className={DScss.DescriptionGroup}>
                            <p className={DScss.SubDescriptionTitle}>{t('DpagePoint3Titile')}</p>
                            <p className={DScss.DescriptionMessage}>{t('DpagePoint3Message')}</p>
                        </div>

                    </div>

                </Parallax>
            </div>
        </section>
    );
};

export default Description;
