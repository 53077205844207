import css from '../../../../css/C0217/RequestRepairPage.module.css'

import Shortstove from '../../../../global/C0217/Short_stove.png'
import Gasstove from '../../../../global/C0217/GasStove.png'
import IcePool from '../../../../global/C0217/icepool.jpg'
import { IC0217BaseInfoFormProps } from '../../../../Interface/interface'
import { Link } from 'react-router-dom'

const Tempdata = [
    {
        "EquipmentID": "2021001521",
        "EquipmentName": "矮仔爐 (電)",
        "EquipmentImage": Shortstove,
        "EquipmentType": "爐具(電)"
    },
    {
        "EquipmentID": "2021001522",
        "EquipmentName": "爐具(煤)",
        "EquipmentImage": Gasstove,
        "EquipmentType": "爐具(煤)"
    },
    {
        "EquipmentID": "2021001523",
        "EquipmentName": "冰水池",
        "EquipmentImage": IcePool,
        "EquipmentType": "雪櫃/凍櫃/冰水池"
    }
]


const SelectOption = [{
    "value": "爐具(電)",
    "label": "爐具(電)"
}, {
    "value": "爐具(煤)",
    "label": "爐具(煤)"
}, {
    "value": "雪櫃/凍櫃/冰水池",
    "label": "雪櫃/凍櫃/冰水池"
}, {
    "value": "安心出行手機",
    "label": "安心出行手機"
}, {
    "value": "電力裝置/跳電掣",
    "label": "電力裝置/跳電掣"
}, {
    "value": "水喉/渠務",
    "label": "水喉/渠務"
}, {
    "value": "收銀機無法連線",
    "label": "收銀機無法連線"
}, {
    "value": "電視機",
    "label": "電視機"
}, {
    "value": "無法上網",
    "label": "無法上網"
}, {
    "value": "冰機",
    "label": "冰機"
}, {
    "value": "冷氣機",
    "label": "冷氣機"
}, {
    "value": "抽風/通風/空氣靜化(EP)",
    "label": "抽風/通風/空氣靜化(EP)"
}, {
    "value": "傢俱",
    "label": "傢俱"
}, {
    "value": "室內裝修",
    "label": "室內裝修"
}, {
    "value": "半自動炒爐",
    "label": "半自動炒爐"
}, {
    "value": "消防系統",
    "label": "消防系統"
}, {
    "value": "閉路電視",
    "label": "閉路電視"
}, {
    "value": "OPENRICE機",
    "label": "OPENRICE機"
}, {
    "value": "隔油池 / 隔油箱",
    "label": "隔油池 / 隔油箱"
}, {
    "value": "洗碗碟機",
    "label": "洗碗碟機",
}, {
    "value": "對講機/擴音機/影迫設備",
    "label": "對講機/擴音機/影迫設備"
}, {
    "value": "落單手機",
    "label": "落單手機",
}, {
    "value": "落貨平板",
    "label": "落貨平板",
}, {
    "value": "面相機",
    "label": "面相機",
}, {
    "value": "排隊機",
    "label": "排隊機",
}, {
    "value": "廠房打印機",
    "label": "廠房打印機",
}, {
    "value": "FAX機",
    "label": "FAX機",
}, {
    "value": "拍卡機",
    "label": "拍卡機",
}, {
    "value": "音樂機",
    "label": "音樂機",
}, {
    "value": "固網電話",
    "label": "固網電話",
}]

const SelectEquipment: React.FC<IC0217BaseInfoFormProps> = ({ isFormData, handleChangeStatus, handleChangeData, handleDataValueByName, handleDateChange }) => {

    const onClickSelectEquipment = (id: number) => {
        handleChangeData("EquipmentID", Tempdata[id].EquipmentID)
        handleChangeData("EquipmentName", Tempdata[id].EquipmentName)
        handleChangeStatus(2)
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.SelectRepairTypeDiv}>
                    <div className={css.BasicFormTitle}>
                        <p>維修類別</p>
                    </div>

                    <div className={css.BasicFormContent}>

                        <fieldset className={css.EquipmentOptionDiv}>
                            {SelectOption.map((option) => {
                                return (
                                    <div key={option.value} className={css.eachEquipmentOption}>
                                        <input type="radio" id={option.value} name="drone" value={option.value} />
                                        <label>{option.label}</label>
                                    </div>
                                )
                            })
                            }
                        </fieldset>

                    </div>
                </div>

                <div className={css.EquipmentListDiv}>
                    <div className={css.BasicFormTitle}>
                        <p>設備清單</p>
                    </div>


                    <div className={css.EquipmentTableDiv}>
                        <table className={css.EquipmentTable}>
                            <thead>
                                <tr>
                                    <th>設備編號</th>
                                    <th>設備名稱</th>
                                    <th>設備圖片</th>
                                    <th>指令</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Tempdata.map((data, index) => {
                                    return (
                                        <tr key={data.EquipmentID}>
                                            <td style={{ width: `130px` }}>{data.EquipmentID}</td>
                                            <td style={{ width: `380px` }}>{data.EquipmentName}</td>
                                            <td><img src={data.EquipmentImage} alt={data.EquipmentName} /></td>
                                            <td style={{ width: `120px` }}>
                                                <i className={`fi fi-rr-wrench-alt ${css.FixOptionIcon}`} onClick={() => onClickSelectEquipment(index)} />
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={css.ButtonControlDiv}>

                    <div className={css.ReturnToStep1Div} onClick={() => handleChangeStatus(0)}>
                        <i className={`fi fi-br-angle-double-small-left ${css.ReturnIcon}`} />
                        <p>上一步</p>
                    </div>

                </div>


                <div style={{ minHeight: `30px` }}></div>


            </section>
        </>
    )
    return content
}

export default SelectEquipment  