//import Page
import ProfileSetting from "../Components/ProfileSetting"

const ProfileSettingPage = () => {
    const content = (
        <>
        <ProfileSetting />
        </>
    )
    return content
}

export default ProfileSettingPage