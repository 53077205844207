import AboutHKrus from "../../../Components/C0533/AboutHKrus"

const AboutHKRUS = () => {
    const content = (
    <>
    <AboutHKrus />
    </>
    )
    return content
}

export default AboutHKRUS