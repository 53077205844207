import OurCrew from '../../../Components/C0533/OurCrew'

const OurCrewPage = () => {
    const content = (
        <>
        <OurCrew />
        </>
    )
    return content
}
export default OurCrewPage