import { useState } from 'react'
import css from '../../../css/C0217/FunctionPage.module.css'
import { IC0217Function } from '../../../Interface/interface'
import { Link } from 'react-router-dom'

const C0217FunctionList: React.FC = () => {

    const [isSelectionType, setSelectionType] = useState<number>(0)
    const [functionList, setFunctionList] = useState<IC0217Function[]>([
        {
            function_typeID: 2901,
            function_id: 2001,
            function_name: "View report",
            function_icon: "fi fi-rs-newspaper",
            function_icon_BGcolor: "#168369",
            desc: "可以生成不同類型的報告例如(Raw Date Report)",
            permission_id: 2001,
            function_path: "/C0217/DownloadReport",
            function_status: "開發中",
        },{
            function_typeID: 2902,
            function_id: 2002,
            function_name: "店鋪維修請求",
            function_icon: "fi fi-sr-wrench-alt",
            function_icon_BGcolor: "#545454",
            desc: "此表單用於店鋪內部提交維修需求，方便管理人員統一處理與追蹤維修進度。",
            permission_id: 2002,
            function_path: "/C0217/RequestRepair",
            function_status: "開發中",
        },
    ])

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.TopDiv}>
                    <p className={css.PageTitle}>Function List</p>
                </div>
                <div className={css.dividingline} />

                {/* <div className={css.SelectionDiv}>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(0)}>
                        <i className="fi fi-rs-user-add" />
                        ALL
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(1)}>
                        <i className="fi fi-rs-user-add" />
                        Human Resources
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(2)}>
                        <i className="fi fi-rs-user-add" />
                        Ware House
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(3)}>
                        <i className="fi fi-rs-user-add" />
                        Salse
                    </button>
                    <button className={css.TypeBtn} onClick={() => setSelectionType(4)}>
                        <i className="fi fi-rs-user-add" />
                        Develop Page
                    </button>
                </div> */}


                {isSelectionType === 0 && (
                    <div className={css.FunctionList}>
                        {functionList.map((item, index) => (
                            <div key={index} className={css.FunctionItem}>
                                <div className={css.FunctionIcon} style={{ backgroundColor: item.function_icon_BGcolor }}>
                                    <i className={item.function_icon} />
                                </div>
                                <div className={css.FunctionName}>
                                    {item.function_name}
                                </div>
                                <div className={css.FunctionDesc}>
                                    {item.desc}
                                </div>
                                <div className={css.Functiondividingline} />
                                <div className={css.FunctionControlDiv}>
                                    <div className={css.LinkDiv}>
                                        <Link to={item.function_path}><button className={css.FunctionBtn}>Go</button></Link>
                                    </div>
                                    <div className={css.FunctionStatusDiv}>
                                        <p className={css.functionStatus}>功能狀態:</p>
                                        {item.function_status}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                )}

                {isSelectionType === 1 && (
                    <div className={css.FunctionList}>
                        {functionList.filter(item => item.function_typeID === 2901).map((item, index) => (
                            <div key={index} className={css.FunctionItem}>
                                <div className={css.FunctionIcon} style={{ backgroundColor: item.function_icon_BGcolor }}>
                                    <i className={item.function_icon} />
                                </div>
                                <div className={css.FunctionName}>
                                    {item.function_name}
                                </div>
                                <div className={css.FunctionDesc}>
                                    {item.desc}
                                </div>
                                <div className={css.Functiondividingline} />
                                <div className={css.FunctionControlDiv}>
                                    <div className={css.LinkDiv}>
                                        <Link to={item.function_path}><button className={css.FunctionBtn}>Go</button></Link>
                                    </div>
                                    <div className={css.FunctionStatusDiv}>
                                        <p className={css.functionStatus}>功能狀態:</p>
                                        {item.function_status}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {isSelectionType === 2 && (
                    <div className={css.FunctionList}>
                        {functionList.filter(item => item.function_typeID === 2902).map((item, index) => (
                            <div key={index} className={css.FunctionItem}>
                                <div className={css.FunctionIcon} style={{ backgroundColor: item.function_icon_BGcolor }}>
                                    <i className={item.function_icon} />
                                </div>
                                <div className={css.FunctionName}>
                                    {item.function_name}
                                </div>
                                <div className={css.FunctionDesc}>
                                    {item.desc}
                                </div>
                                <div className={css.Functiondividingline} />
                                <div className={css.FunctionControlDiv}>
                                    <div className={css.LinkDiv}>
                                        <Link to={item.function_path}><button className={css.FunctionBtn}>Go</button></Link>
                                    </div>
                                    <div className={css.FunctionStatusDiv}>
                                        <p className={css.functionStatus}>功能狀態:</p>
                                        {item.function_status}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {isSelectionType === 3 && (
                    <div className={css.FunctionList}>
                        {functionList.filter(item => item.function_typeID === 2903).map((item, index) => (
                            <div key={index} className={css.FunctionItem}>
                                <div className={css.FunctionIcon} style={{ backgroundColor: item.function_icon_BGcolor }}>
                                    <i className={item.function_icon} />
                                </div>
                                <div className={css.FunctionName}>
                                    {item.function_name}
                                </div>
                                <div className={css.FunctionDesc}>
                                    {item.desc}
                                </div>
                                <div className={css.Functiondividingline} />
                                <div className={css.FunctionControlDiv}>
                                    <div className={css.LinkDiv}>
                                        <Link to={item.function_path}><button className={css.FunctionBtn}>Go</button></Link>
                                    </div>
                                    <div className={css.FunctionStatusDiv}>
                                        <p className={css.functionStatus}>功能狀態:</p>
                                        {item.function_status}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                


            </section>
        </>
    )
    return content
}

export default C0217FunctionList