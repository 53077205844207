import { Helmet, HelmetProvider } from "react-helmet-async"
import { Outlet } from "react-router-dom"
import css from '../../css/Auth/AuthLayout.module.css'

import NavBar from "../../Components/AuthPage/NavBar"

import ACLogo from '../../global/C0211/SHYLogo.png'
import ChangeColor from '../../Components/ChangeColor'

const AuthLayout = () => {

    const content = (
        <>
            <body>
                <HelmetProvider>
                    <div>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>控制台</title>
                        </Helmet>
                        <div className={css.DashLayout}>

                            <div className={css.navbar}>
                                <NavBar />
                            </div>

                            <div className={css.Outlet}>
                                <Outlet />
                            </div>
                        </div>

                    </div>
                </HelmetProvider>
            </body>
        </>
    )
    return content
}

export default AuthLayout;