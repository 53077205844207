import FTcss from '../css/Function.module.css'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IFunction } from '../Interface/interface';
import { SystemContext } from '../Class/SystemPara';
import { FetchSystemData } from '../Interface/Const';

const Function = (props: { FName: any; }) => {

    const ACFunctionName = props.FName;
    const systemContext = useContext(SystemContext);
    const [filterdata, SetFilterData] = useState<IFunction[]>([])


    useEffect(() => {
        FetchSystemData(systemContext)
        SetFilterData([])
        getmatchData();
    }, [])

    useEffect(() => {
        console.log(filterdata)
    }, [filterdata])

    async function getmatchData() {

        let newFilterData = [...filterdata];

        systemContext.systemData.FunctionArr.forEach((item) => {

            if (item.function_section.split('-')[0] === ACFunctionName) {
                newFilterData.push(item);
            }

        });

        if (newFilterData.length <= 0) {
            window.location.replace('https://www.wisetechinterglobe.com.hk/dash');
        }

        // Update the state only once after processing all items
        SetFilterData(newFilterData);
    }

    const content = (
        <section className={FTcss.MainSection}>
            <div className={FTcss.FunctionMain}>
                <div className={FTcss.Title}>
                    <p>功能列表{ACFunctionName}</p>
                </div>
                <hr />
                {/* <button onClick={refresh}>Refresh</button> */}
                <div className={FTcss.DisplayFT}>
                    {filterdata.map((item) => (
                        <div className={FTcss.EachFTDiv} key={item.permission_id}>

                            <div className={FTcss.EachFunctionImgDiv}>
                                <img alt={item.desc} src={item.function_img}></img>
                            </div>

                            <div className={FTcss.EachFunctionDesc}>

                                <div className={FTcss.FunctionTitle}>
                                    <p>{item.function_section}</p>
                                </div>

                                <div className={FTcss.FunctionMessage}>
                                    <p>{item.desc}</p>
                                </div>

                                <div className={FTcss.FunctionBtn}>
                                    <div className={FTcss.ClickMeDiv}>
                                        <Link to={item.function_path}>
                                            <div className={FTcss.ClickBtnDiv}>
                                                <p>Click Me</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
    return content
}

export default Function