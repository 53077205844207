import { useState } from 'react';
import { Cookies } from 'react-cookie';

import ProductList from '../../../Components/C0211/WareHouse/ProductList';
import EditProductTypeCmp from '../../../Components/C0211/WareHouse/EditProductTypeDetail'
import EditProductCmp from '../../../Components/C0211/WareHouse/EditProductDetail'
import EditProductSKUCmp from '../../../Components/C0211/WareHouse/EditProductSKUDetail'
import { IC0211ProductType } from '../../../Interface/interface';

const cookies = new Cookies();

const C0211ProductPage = () => {
    const [isEditMode, setEditMode] = useState<number | null>(null);
    const [isProductTypeID, setProductTypeID] = useState<number | null>(null);
    const [isProductID, setProductID] = useState<number | null>(null);
    const [isProductSKUID, setProductSKUID] = useState<number | null>(null);
    const [isProductSKUCode, setProductSKUCode] = useState<string | null>("");



    const content = (
        <>
            {isEditMode === null && (
                <ProductList setProductTypeID={setProductTypeID} setProductID={setProductID} setProductSKUID={setProductSKUID} setProductSKUCode={setProductSKUCode} setEditMode={setEditMode} />
            )}

            {isEditMode === 1 && (
                <EditProductTypeCmp setProductTypeID={setProductTypeID} isProductTypeID={isProductTypeID} setEditMode={setEditMode}/>
            )}

            {isEditMode === 2 && (
                <EditProductCmp isProductID={isProductID} setProductID={setProductID} setEditMode={setEditMode} />
            )}

            {isEditMode === 3 && (
                <EditProductSKUCmp isProductID={isProductID} setProductID={setProductID} isProductSKUID={isProductSKUID} setProductSKUID={setProductSKUID} setProductSKUCode={setProductSKUCode} isProductSKUCode={isProductSKUCode} setEditMode={setEditMode} />
            )}





        </>
    );

    return content;
};

export default C0211ProductPage;
