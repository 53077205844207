import { useContext, useEffect } from 'react';
import css from '../../css/Auth/ACIDSelect.module.css'
import { UserContext } from '../../Class/UserPara';

//Company Logo
import SHYLogo from '../../global/SHYLogo.jpg'
import TOA from '../../global/TasteofasiaLogo.jpg'
import Wisetech from '../../global/wisetechinterglobeLogo.jpg'
import { Link } from 'react-router-dom';


const ACData = [
    {
        ACID: 'C0000',
        ACName: "Wisetech Interglobe Limited 滙文智庫有限公司",
        DashboardPath: '/tsdash',
        Logo: Wisetech,
    },
    {
        ACID: 'C0211',
        ACName: "紹香園",
        DashboardPath: '/C0211',
        Logo: SHYLogo,
    },
    {
        ACID: 'C0217',
        ACName: "亞洲國際餐飲集團",
        DashboardPath: '/C0217',
        Logo: TOA,
    },

]


const ACIDSelect = () => {

    const { ACIDList } = useContext(UserContext)

    useEffect(() => {
        console.log('ACIDSelect mounted', ACIDList)
    }, [])

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.SelectCompanyDiv}>
                    {ACData.filter(acid => ACIDList.some(company => company === acid.ACID)).map((acid) => {
                        return (
                            <div className={css.EachCompanyDiv} key={acid.ACID}>
                                <div className={css.CompanyACIDDiv}>
                                    <p>{acid.ACID}</p>
                                </div>
                                <div className={css.CompanyLogoDiv}>
                                    <img src={acid.Logo} alt="Company Logo" />
                                </div>
                                <div className={css.CompanyNameDiv}>
                                    <p>{acid.ACName}</p>
                                </div>
                                <Link to={acid.DashboardPath}>
                                    <div className={css.CompanyButtonDiv}>
                                        Go
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </section>
        </>
    )
    return content;
}

export default ACIDSelect;