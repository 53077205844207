import DeliveryNoteToExcelCmp from '../,,/../../../Components/C0211/WareHouse/DeliveryNoteToExcel'

const DeliveryNotePage = () => {
    const content = (
        <>
        <DeliveryNoteToExcelCmp />
        </>
    )
    return content
}

export default DeliveryNotePage;