import React, { useState } from 'react'
import css from '../../../css/C0211/ProudctAdd.module.css'
import { c0211ProductAddModeProps } from '../../../Interface/interface';

const AddProductType: React.FC<c0211ProductAddModeProps> = ({ setAddMode }) => {

    const [isTypeCode, setTypeCode] = useState<string>('');
    const [isTypeName, setTypeName] = useState<string>('');

    const submitProductType = async () => {
        console.log(isTypeCode);
        console.log(isTypeName);
    }

    const content = (
        <>
            <section className={css.darkpannel}>
                <div className={css.AddDiv}>
                    <p className={css.Title}>Add Product Type</p>
                    <div className={css.baseInfoDiv}>
                        <div className={css.baseInfoItem}>
                            <label>Product Type Code</label>
                            <input
                                type="text"
                                value={isTypeCode}
                                onChange={(e) => setTypeCode(e.target.value)}
                            />
                        </div>
                        <div className={css.baseInfoItem}>
                            <label>Product Type Name</label>
                            <input
                                type="text"
                                value={isTypeName}
                                onChange={(e) => setTypeName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={css.ButtonsDiv}>
                        <button className={css.BottomBtn} onClick={() => setAddMode(null)}>
                            <i className="fi fi-rs-rectangle-xmark" />
                            Cancel
                        </button>
                        <button className={css.BottomBtn} onClick={() => submitProductType()}>
                            <i className="fi fi-rs-disk" />
                            Save
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
    return content
}

export default AddProductType