import { useEffect, useState } from 'react'
import css from '../../css/C0533/Competition.module.css'

import image01 from '../../global/C533/BackgroundBG.jpg'
import imgPDF from '../../global/C533/PGFImg.png'
import axios from 'axios'
import { IC0553WebsiteImage } from '../../Interface/interface'

const Competition = () => {

    const [data, setData] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        goGetDataJson();
    }, [])

    const goGetDataJson = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "Competition",
                item_section_code: "CPTI_",
            });
            if (res.status === 200) {
                setData(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getMatchLink = (data: IC0553WebsiteImage[], SeachTitile: string, ShowTitle: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        const itemTitle = data[indexID].item_title;
        const urlpath = data[indexID].item_link_url;
        return <a href={urlpath}>{itemTitle}</a>;
    };


    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                <p>聯校盃</p>
                <p>全港跳繩競速錦標賽 2024</p>
            </div>
            <div className={css.BannerImg}>
                <img src={image01} alt="BG photo" />
            </div>
            <div className={css.parentContainer}>
                <div className={css.RegulationsTitle}>
                    <p>個人賽 - 比賽章程</p>
                </div>
            </div>
            <div className={css.LinkAndFileDiv}>
                <div className={css.PDFimgDiv}>
                    <a href="http://www.hkrsu.com/download/lian-xiao-bei-quan-gang-tiao-sheng-jing-su-jin-biao-sai2024-ge-ren-.pdf"><img src={imgPDF} alt="PDF image" /></a>
                </div>
                <div className={css.Link1Div}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_20", "比賽證書申請")}
                </div>
            </div>
            <div className={css.Link3Div}>
                <div className={css.eachLinkDiv}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_02", "比賽時間表")}
                </div>
                <div className={css.eachLinkDiv}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_03", "報名表 下載")}
                </div>
                <div className={css.eachLinkDiv}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_21", "比賽聲帶 下載")}
                </div>
            </div>

            <div className={css.parentContainer}>
                <div className={css.RegulationsTitle}>
                    <p>示範影片 :</p>
                </div>
            </div>

            <div className={css.videoDiv}>
                <iframe
                    id="pl4"
                    className={css.video}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    title="UnionCupMaster_Demo"
                    width="640"
                    height="360"
                    src="https://www.youtube-nocookie.com/embed/05wDC4HFYbk?playsinline=1&rel=1&autoplay=1&mute=1&enablejsapi=1&origin=http://www.hkrsu.com&widgetid=1"
                />
            </div>

            <div className={css.result}>

                <div className={css.EachResult}>
                    <div className={css.ReasultTitle}>
                        <p>2023 成績公佈</p>
                    </div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_05", "比賽相片精華")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_06", "8歲或以下組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_07", "9-11歲組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_08", "12歲或以上組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_09", "個人總成績")}</div>
                </div>

                <div className={css.EachResult}>
                    <div className={css.ReasultTitle}>
                        <p>2024 成績公佈</p>
                    </div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_10", "比賽相片精華")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_11", "9歲或以下組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_12", "10-12歲組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_13", "13歲或以上組別成績")}</div>
                </div>

            </div>

            <hr className={css.Line} />


            <div className={css.parentContainer}>
                <div className={css.RegulationsTitle}>
                    <p>團體賽 - 比賽章程</p>
                </div>
            </div>

            <div className={css.Regulations2Group}>

                <div className={css.EachRegulations2}>
                    <div className={css.Regulations2}>
                        <div className={css.Regulations2SubTitle}>
                            <p>示範影片 :</p>
                        </div>

                        <div className={css.videoDiv}>
                            <iframe
                                id="pl5"
                                className={css.video}
                                frameBorder="0"
                                allowFullScreen
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                title="聯校盃全港跳繩競速錦標賽 團體賽－示範片段"
                                width="640"
                                height="360"
                                src="https://www.youtube-nocookie.com/embed/IIZS2SgrlEA?playsinline=1&rel=1&autoplay=1&mute=1&enablejsapi=1&origin=http://www.hkrsu.com&widgetid=2"
                            />
                        </div>

                        <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_14", "比賽相片精華 2023")}</div>
                    </div>
                </div>

                <div className={css.EachRegulations2}>
                    <div className={css.PDFimgDiv}>
                        <img src={imgPDF} alt="PDF image" />
                    </div>

                    <div className={css.RegulationsTitle2}>
                        <p>2024 報名資料</p>
                    </div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_15", "章程 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_16", "報名表 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_17", "4x30s 接力聲帶 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_18", "2x30s 接力聲帶 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_19", "1min 項目聲帶 下載")}</div>
                </div>

            </div>

        </section>
    )
    return content
}

export default Competition