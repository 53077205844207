import React, { Dispatch, SetStateAction, createContext, useState } from 'react';
import { IC0211WebsitePermission, IUser } from '../Interface/interface';

export interface UserContextType {
  userData: IUser;
  setUserData: Dispatch<SetStateAction<IUser>>;
  ACIDList: string[];
  setACIDList: Dispatch<SetStateAction<string[]>>;
  FuncPermission: IC0211WebsitePermission[];
  setFunctionPermission: (FuncPermission: IC0211WebsitePermission[]) => void;
}

const defaultUserData: IUser = {
  user_id: 0,
  user_name: '',
  login_email: '',
  ac_id: '',
  email_id: '',
  login_count: 0,
  user_status: '',
  authority_level: '',
  permissions: []
};

export const UserContext = createContext<UserContextType>({
  userData: defaultUserData,
  setUserData: () => {}, // Implement a no-op
  ACIDList: [],
  setACIDList: () => {},
  FuncPermission: [],
  setFunctionPermission: () => {},
});