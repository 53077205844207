import css from '../../css/C0533/ContactUs.module.css'
import React from 'react';
import Photo from '../../global/C533/Phone.jpg'
import Email from '../../global/C533/Email.jpg'
import FaceBook from '../../global/C533/FaceBook.jpg'
import Address from '../../global/C533/Address.png'
import { useState } from 'react'

import Logo from '../../../src/global/C533/AboutLogo.png'

const ContactUs: React.FC = () => {

    const [equipment, setEquipment] = useState(false);
    const [course, setCourse] = useState(false);
    const [posp, setPOSP] = useState(false);
    const [other, setOther] = useState(false)

    const content = (
        <section className={css.MainSection}>

            <div className={css.ContactUsBtnDiv2}>
                <a href="#from" >
                    <div className={css.ContactUsBtnDiv}>
                        聯絡我們
                    </div>
                </a>
            </div>

            <div className={css.ContactInfo}>
                <div className={css.EachContact}>
                    <img src={Email} alt="Email" />
                    <div className={css.ContactMessage}>
                        <p>hkrsu.asia@gmail.com</p>
                    </div>
                </div>
                <div className={css.EachContact}>
                    <img src={Photo} alt="Phone" />
                    <div className={css.ContactMessage}>
                        <p>(+852) 65801700</p>
                    </div>
                </div>
                <div className={css.EachContact}>
                    <img src={FaceBook} alt="FaceBook" />
                    <div className={css.ContactMessage}>
                        <p>www.facebook.com/hkrsu</p>
                    </div>
                </div>
                <div className={css.EachContact}>
                    <img src={Address} alt="Address" />
                    <div className={css.ContactMessage}>
                        <p>地址：葵涌大連排道83號 K83 15樓03室 (葵興港鐵站 E出口 步行5分鐘)</p>
                        <p>郵寄地址：將軍澳郵政局郵政信箱65217號</p>
                    </div>
                </div>
            </div>

            <div className={css.formDiv}>
                <form id="from">
                    <p>相關事項</p>
                    <br />

                    <div className={css.TwoInOne}>

                        <div className={css.TwoInOne_Form}>
                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={equipment}
                                    onChange={() => setEquipment(!equipment)}
                                />
                                <label>器材</label>
                            </div>

                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={course}
                                    onChange={() => setCourse(!course)}
                                />
                                <label>課程</label>
                            </div>

                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={posp}
                                    onChange={() => setPOSP(!posp)}
                                />
                                <label>表演或學校推廣</label>
                            </div>

                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={other}
                                    onChange={() => setOther(!other)}
                                />
                                <label>其他</label>
                            </div>
                        </div>

                        <div className={css.TwoInOne_Logo}>
                            <img src={Logo} alt="Logo" />
                        </div>

                    </div>
                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>聯絡人</p>
                            <p className={css.required}>*</p>
                        </div>
                        <input type="text" name="name" placeholder="聯絡人姓名" className={css.inputTextBox} />
                    </label>

                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>聯絡人電話</p>
                            <p className={css.required}>*</p>
                        </div>
                        <input type="text" name="name" placeholder="聯絡人電話" className={css.inputTextBox} />
                    </label>

                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>電郵</p>
                            <p className={css.required}>*</p>
                        </div>
                        <input type="text" name="name" placeholder="電郵" className={css.inputTextBox} />
                    </label>

                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>內容</p>
                            <p className={css.required}>*</p>
                        </div>
                        <textarea name="name" rows={10} cols={30} placeholder="填寫內容" className={css.AllContent}></textarea>
                    </label>

                    <br />

                    <input type="submit" value="提交" />
                </form>
            </div>

        </section >
    )
    return content
}
export default ContactUs